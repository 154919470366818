import { useEffect, useState } from 'react';

function useOutsideAlerter(ref) {
  const [isClickedOutside, setIsClickedOutside] = useState(false);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (event.target.closest('.modal') == null) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsClickedOutside(true);
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
  return isClickedOutside;
}

export default useOutsideAlerter;
