import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';

export default function NotificationsAlertComponent({
  apiUserEmailList,
  setApiUserEmailList,
  weekDaysObject,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  setWeekDaysObject
}) {
  const [onDatepickerOpen, setOnDatepickerOpen] = useState(false);

  const toggleUserEmail = (userEmailId) => {
    let tempUserListing = [...apiUserEmailList];
    let userIndex = tempUserListing.findIndex((item) => item.id == userEmailId);

    tempUserListing[userIndex] = {
      ...tempUserListing[userIndex],
      status: !tempUserListing[userIndex].status
    };
    setApiUserEmailList(tempUserListing);
  };
  const selectWeekDays = (index) => {
    let tempSelectWeekday = [...weekDaysObject];
    tempSelectWeekday[index] = {
      ...tempSelectWeekday[index],
      selected: !tempSelectWeekday[index].selected
    };
    let lastDay = tempSelectWeekday.every((weekday) => {
      return weekday.selected == false;
    });
    if (!lastDay) setWeekDaysObject(tempSelectWeekday);
  };

  useEffect(() => {
    var datepickerPopperElement = document.getElementsByClassName('react-datepicker-popper')[0];
    if (datepickerPopperElement) {
      var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation, index) {
          if (mutation.attributeName === 'style' && index == 0) {
            var transformX = Math.floor(
              datepickerPopperElement.style.transform
                .split('(')[1]
                .split(')')[0]
                .split(',')[0]
                .split('px')[0]
            );
            var transformY = Math.floor(
              datepickerPopperElement.style.transform
                .split('(')[1]
                .split(')')[0]
                .split(',')[1]
                .split('px')[0]
            );
            var transform = `translate(${transformX}px, ${transformY}px)`;
            datepickerPopperElement.style.transform = transform;
            datepickerPopperElement.style.webkitTransform = transform;
            datepickerPopperElement.style.msTransform = transform;
          }
        });
      });

      // Notify me of style changes
      var observerConfig = {
        attributes: true,
        attributeFilter: ['style']
      };

      observer.observe(datepickerPopperElement, observerConfig);

      return () => {
        observer.disconnect();
      };
    }
  }, [onDatepickerOpen]);

  return (
    <div className="alert-notification mb-3 mt-4">
      <p className="light-heading mb-2.5 heading-mb">Advanced options</p>
      <p className="heading-mb ">Send notification to</p>
      <div className="vstack gap-2 mb-3 alert-notification_email-listing">
        {apiUserEmailList.map((eachUser, i) => {
          return (
            <div key={'userEmail_' + i} className="flex-btw mr-2">
              <span className="alert-notification_email">{eachUser.email}</span>
              <label className="daz-switch small-dark">
                <input
                  type="checkbox"
                  onChange={() => toggleUserEmail(eachUser.id)}
                  checked={eachUser.status}
                />
                <span className="slider  round"></span>
              </label>
            </div>
          );
        })}
      </div>

      <p className="mb-2">
        Every <span className="light-heading">(select days)</span>{' '}
      </p>
      <div className="mb-3 hstack gap-2">
        {weekDaysObject.map((eachday, i) => {
          return (
            <button
              key={i}
              type="button"
              data-testid={'weekday' + i}
              onClick={() => selectWeekDays(i)}
              className={
                eachday.selected == true ? 'weekdays-btn weekdays-btn_active ' : 'weekdays-btn'
              }>
              {eachday.name}
            </button>
          );
        })}
      </div>
      <p className="mb-2">
        Between <span className="light-heading">(select time)</span>{' '}
      </p>
      <div className="flex-c">
        <DatePicker
          selected={startTime}
          onChange={(date) => setStartTime(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          minTime={setHours(setMinutes(new Date(), 0), 0)}
          maxTime={setHours(setMinutes(new Date(), endTime.getMinutes()), endTime.getHours())}
          className="timerange"
          onCalendarOpen={() => setOnDatepickerOpen((prev) => !prev)}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />

        <span className="px-1">-</span>
        <DatePicker
          selected={endTime}
          onChange={(date) => setEndTime(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          minTime={setHours(setMinutes(new Date(), startTime.getMinutes()), startTime.getHours())}
          maxTime={setHours(setMinutes(new Date(), 45), 23)}
          className="timerange"
          popperPlacement="bottom"
          popperClassName="popper-end-time"
          onCalendarOpen={() => setOnDatepickerOpen((prev) => !prev)}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
      </div>
    </div>
  );
}
