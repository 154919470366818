import { useEffect } from 'react';
import ChartExpandComponent from '../components/chartexpand.component';

export default function ChartExpandContainer({
  shouldExpand,
  onWindowChange,
  charRef,
  chartContainerRef
}) {
  useEffect(() => {
    if (charRef && chartContainerRef && charRef.current) {
      charRef.current.chart.reflow();
    }
  }, [shouldExpand]);

  return <ChartExpandComponent shouldExpand={shouldExpand} onWindowChange={onWindowChange} />;
}
