const initialState = {
  filter_button_click: false
};

const filterButtonReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FILTER_BUTTON':
      return {
        ...state,
        filter_button_click: action.payload
      };
    default:
      return state;
  }
};

export default filterButtonReducer;
