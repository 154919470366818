import AppRouter from './route/app.router';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.scss';
import httpService from './services/http.service';
import { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
function App() {
  useEffect(() => {
    httpService.httpInterceptors();
  }, []);
  return (
    <Suspense fallback={<span>Loading....</span>}>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
