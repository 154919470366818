
export const validateMachineStatusCustomize = ({input_type,source_mapping,formErrors,machineStatusData}) => {
    let errorStatus = false,
    errorMsg = '';
    let currentInputObj = machineStatusData.machine_status.find((v) => source_mapping == v.source_mapping);
    switch(input_type){
      case 'standby_at_below':
            if (currentInputObj.standby_at_below  && currentInputObj.sleep_at_below) {
              if (currentInputObj.sleep_at_below >= currentInputObj.standby_at_below) {
                errorMsg = 'Standby should be greater than sleep-mode';
                errorStatus = true;
              }
            } else if (
              (currentInputObj.standby_at_below  && currentInputObj.off_at_below)
            ) {
              if (currentInputObj.off_at_below >= currentInputObj.standby_at_below) {
                errorMsg = 'Standby should be greater than off';
                errorStatus = true;
              }
            } else {
              errorStatus = false;
              errorMsg = '';
            }
            break; 
      case 'sleep_at_below': 
            if (currentInputObj.sleep_at_below && currentInputObj.off_at_below) {
              if (currentInputObj.off_at_below >= currentInputObj.sleep_at_below) {
                errorMsg = 'Sleep-mode should be greater than off';
                errorStatus = true;
              } 
            }
            else {
              errorStatus = false;
              errorMsg = '';
            }
            break;
      case 'off_at_below': 
            if (currentInputObj.off_at_below == null) {
              errorMsg = 'Off value is required';
              errorStatus = true;
            }
            else {
              errorStatus = false;
              errorMsg = '';
            }
            break;
      default:
            break;      
    }

    let index = formErrors.findIndex((v) => v.source_mapping == source_mapping);
    let tempFormErrors = [...formErrors];
    if (errorStatus) {
      tempFormErrors[index]['errorMsg'][input_type] = errorMsg;
    } else {
      if (tempFormErrors[index].errorMsg && tempFormErrors[index].errorMsg[input_type]) {
        delete tempFormErrors[index].errorMsg[input_type];
      }
    }

  return tempFormErrors;
};
