import { useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router-dom';
import { roundToTwoDecimal } from '../../../utilities/common.computations';

export default function WidgetGraphComponent({
  jsChartCallback,
  summaryData,
  widgetURLName,
  widgetTooltipSuffix,
  widgetTitle,
}) {
  const charRef = useRef(undefined);
  const navigate = useNavigate();

  const config = {
    chart: {
      type: 'column',
      height: 90,
      spacingTop: 10,
      spacingBottom: 10,
      style: {
        fontFamily: 'Poppins',
        color: '#4E4E4E'
      }
    },
    title: {
      text: undefined
    },
    series: [
      {
        data: summaryData && summaryData.data,
        tooltip: {
       valueSuffix: ` ${widgetTooltipSuffix}`,
          pointFormat:
            summaryData && summaryData.time
              ? `<div class="semi-bold">{point.y}</div>${summaryData.time}`
              :'<div class="semi-bold">{point.y}</div>',
          valueDecimals: widgetURLName == 'energy_price' ? 2 : 0
        },
        groupPadding: 0,
        pointPadding: 0.05
      }
    ],
    legend: {
      enabled: false
    },
    xAxis: {
      //  categories:summaryData && summaryData.categories,
      lineWidth: 0,
      type: 'category',
      labels: {
        style: {
          cursor: 'pointer',
          fontSize: '11px'
        }
      }
    },
    yAxis: {
      visible: false
    },
    credits: {
      enabled: false
    },
    navigation: {
      buttonOptions: {
        enabled: false
      }
    },
    tooltip: {
      outside: true,
   //  pointFormat: `<span class="semi-bold">{point.y}</span></div>`,
     useHTML:true,
     formatter: widgetURLName == 'energy_use' ? 
     function () {
      console.log("formatter", this)
         let label = `<div class="txt-10">${this.point.label}</div>`;
         let bolt = "<span class=' material-icons notranslate-outlined tooltip-bolt-icon'>bolt</span>";
         let avgEnergy = `<div class="flex-c">${bolt}<span class="txt-10">Average: <span class="semi-bold"> ${roundToTwoDecimal(this.point.y) } </span> kWh</span></div>`;
         let totalEnergy = `<div class="flex-c">${bolt}<span class="txt-10">Total: <span class="semi-bold">${roundToTwoDecimal(this.point.energy)} </span>  kWh</span></div>`;
         return `${label}${avgEnergy}${totalEnergy}`
       }
      :
      undefined,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: false
        }
      },
      column: {
        cursor: 'pointer',
        color: '#C4C4C4',
        minPointLength: 5,
        point: {
          events: {
            click: function () {
              navigate(`/widget/?widget_name=${widgetURLName}`);
            }
          }
        }
      }
    }
  };

  return (
    <div
      className=""
      onClick={() => {
        navigate(`/widget/?widget_name=${widgetURLName}`);
      }}>
      <h5>{widgetTitle}</h5>
      <div className="flex-c-m percentage-value">
        {summaryData && summaryData.percentageDiff ? (
          summaryData && summaryData.positiveValue == true ? (
            <>
              <img src="../../../arrow-round-red.svg" className="perc-arrow" alt="Arrow up"></img>
              <span className="ml-2">+</span>
            </>
          ) : (
            <>
              <img
                src="../../../arrow-round-green.svg"
                className="perc-arrow"
                alt="Arrow down"></img>
              <span className="ml-2">-</span>
            </>
          )
        ) : null}
        {summaryData && summaryData.percentageDiff ? (
          <span>{summaryData && summaryData.percentageDiff}%</span>
        ) : (
          <div>&nbsp;</div>
        )}
      </div>

      <HighchartsReact
        ref={charRef}
        highcharts={Highcharts}
        options={config}
        callback={jsChartCallback}
      />
    </div>
  );
}
