import WidgetGraphComponent from '../components/widgetgraph.component';

function EnergyPriceContainer({ summaryData }) {
  return (
    <WidgetGraphComponent
      widgetURLName="energy_price"
      widgetTitle="Energy price (SEK)"
      summaryData={summaryData}
      widgetTooltipSuffix="SEK/kWh"
    />
  );
}

export default EnergyPriceContainer;
