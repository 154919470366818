// ==============================|| PUBLIC PAGES ROUTING WITH HEADER ||============================== //

import { lazy, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PublicLayoutComponent from '../hoc/layout/components/public.layout.component';
import { isAuthenticated } from '../utilities/local.storage';
import { RedirectLogin } from './redirection.login.routes';
const LoginContainer = lazy(() => import('../modules/login/containers/login.container'));
const ResetContainer = lazy(() => import('../modules/resetpassword/containers/reset.container'));

export const PublicRoute = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated()) {
      navigate('/home');
    }
  }, []);

  return children;
};

const publicRoutes = {
  path: '/',
  element: (
    <PublicRoute>
      <PublicLayoutComponent />
    </PublicRoute>
  ),
  children: [
    {
      path: '/',
      element: <RedirectLogin />
    },
    {
      path: '/login',
      element: <LoginContainer />
    },
    {
      path: '/reset-password/:token',
      element: <ResetContainer />
    }
  ]
};

export default publicRoutes;
