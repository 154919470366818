const initialState = {
  combination_id: null
};

const equimentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ALERT':
      return {
        ...state,
        combination_id: action.payload
      };
    default:
      return state;
  }
};

export default equimentReducer;
