import moment from 'moment';
import { roundToTwoDecimal } from '../utilities/common.computations';

const axisLablesFontSizeSet = (userAccessibility) => {
  return userAccessibility ? `${(userAccessibility.text_size_percentage / 100) * 0.92}em` : '11px'; //If not set
};

const chartCommonConfig = {
  credits: {
    enabled: false
  },
  navigation: {
    buttonOptions: {
      enabled: false
    }
  },
  legend: {
    enabled: false
  },
  time: {
    useUTC: false
  }

  //   responsive: {
  //     rules: [{
  //         condition: {
  //             minWidth: 400
  //         },
  //         chartOptions: {
  //             title: {
  //               style: {
  //                 fontSize: '25px',
  //               }
  //             },
  //             xAxis:{
  //               labels:{
  //                 style:{
  //                   fontSize: '20px'
  //                 }
  //               }
  //             },
  //             yAxis:{
  //               labels:{
  //                 style:{
  //                   fontSize: '20px'
  //                 }
  //               }
  //             }

  //         }
  //     }]
  // }
};

const chartTitleConfig = ({ graphTitle, userAccessibility, graphTooltip }) => {
  const title = {
    title: {
      // useHTML:true,
      text: graphTooltip ? `<div  title="${graphTooltip}" >${graphTitle}</div>` : graphTitle,
      margin: 20,
      style: {
        color: '#4E4E4E',
        fontSize: userAccessibility
          ? `${(userAccessibility.text_size_percentage / 100) * 1.5}em`
          : '18px', //If not set,
        fontWeight: 'normal'
      }
    }
  };
  return title;
};

const chartChartConfig = ({ userAccessibility }) => {
  const chartChartConfig = {
    style: {
      fontFamily: 'Poppins',
      color: '#4E4E4E',
      fontWeight: userAccessibility ? userAccessibility.font_weight : 'normal' //If not set,
    },
    zoomType: 'x',
    resetZoomButton: {
      position: {
        x: 9,
        y: -55
      }
    }
  };
  return chartChartConfig;
};

const chartLineTooltipConfig = {
  tooltip: {
    xDateFormat: '%a, %b %d, %H:%M',
    valueDecimals: 2,
    pointFormatter: function () {
      return `<span style="color:${this.color}">\u25CF </span><div>${
        this.series.name
      }:</div> <span class="semi-bold">${roundToTwoDecimal(this.y)} ${
        this.series.tooltipOptions.valueSuffix
      }<br/>`;
    }
  }
};

const chartLineExportingConfig = {
  exporting: {
    //   buttons: {
    //     customButton: {
    //         x: -62,
    //         onclick: function () {
    //             alert('Clicked');
    //         },
    //         symbol: 'circle'
    //     }
    // },
    chartOptions: {
      subtitle: {
        text: ''
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false,
            format: '{point.y:,.2f}',
            style: { textShadow: 'none' }
          }
        }
      }
    }
  }
};

const chartLineColorsConfig = {
  colors: [
    '#71B2FF',
    '#BAC5FF',
    '#FFABAB',
    '#B07FB4',
    '#FADCFF',
    '#C6E0FF',
    '#BDD0CD',
    '#B2B2B2',
    '#CFB8CB',
    '#FCB9C9',
    '#FFC0AC',
    '#FFD685',
    '#F9F871'
  ]
};

function chartSubtitleConfig({ chartApiData, userAccessibility }) {
  const subtitle = {
    subtitle: {
      text:
        chartApiData && chartApiData.last_updated_time
          ? `<span>Last updated: ${
              chartApiData && moment.utc(chartApiData.last_updated_time).local().format('HH:mm')
            }</span>`
          : '',
      style: {
        color: '#9F9F9F',
        fontSize: userAccessibility
          ? `${(userAccessibility.text_size_percentage / 100) * 1}em`
          : '12px', //If not set,
        fontWeight: 'normal'
      }
    }
  };
  return subtitle;
}
function chartLineXaxisConfig({
  graphFilter,
  userAccessibility,
  setMinMaxValue,
  isPlotedLine,
  // minMaxValue,
  // setProcessedXAxis
}) {

  const xAxis = {
    xAxis: {
      lineColor: '#cccccc',
      events: {
        afterSetExtremes: function (e) {
          setMinMaxValue && setMinMaxValue([e.min, e.max]);

          if(isPlotedLine){
            const maxdataIndex=this.series[0]?.yData.indexOf(this.chart?.series[0]?.dataMax)
            this.chart.xAxis[0].options.plotLines[0].value = this?.chart?.series[0]?.xData[maxdataIndex];
            this.chart.xAxis[0].userOptions.title.text=`Peak: ${this.chart?.series[0]?.dataMax?.toFixed(2)} kW -  ${ moment.utc(this?.chart?.series[0]?.xData[maxdataIndex]).local().format('ddd, MMM D, HH:mm')}`
            isPlotedLine&& this.chart.xAxis[0].update();
            // setMinMaxValue && setMinMaxValue([e.min, e.max]);
          }
        }
      },

      title: {
        margin:5,
        fontSize:14,
        fontWeight:600,
        text: '',
        align: 'high'
        
    },
      tickLength: 0,
  plotLines:[isPlotedLine?{
  value:0,
  color: '#242D5C',
  width:1,
  zIndex: 5,
  dashStyle:'LongDash',
  events: {
},
}:{},],
      type: 'datetime',
      dateTimeLabelFormats: {
        hour: graphFilter.filterMain == '4hr' ? '%H:%M' : '%d %b,  %H:%M ',
        day: '%d %b,  %H:%M',
        week: '%d %b, %H:%M'
      },
      labels: {
        padding: 20,
        style: {
          fontSize: axisLablesFontSizeSet(userAccessibility)
        }
      }
    }
  };
  return xAxis;
}

function chartYaxisConfig({ userAccessibility }) {
  const yAxis = {
    yAxis: {

      title: {
        enabled: false
      },
      labels: {
        style: {
          fontSize: axisLablesFontSizeSet(userAccessibility)
        }
      },
      
    }
  };
  return yAxis;
}

export {
  chartCommonConfig,
  chartTitleConfig,
  chartChartConfig,
  chartLineTooltipConfig,
  chartLineExportingConfig,
  chartLineColorsConfig,
  chartSubtitleConfig,
  chartLineXaxisConfig,
  axisLablesFontSizeSet,
  chartYaxisConfig
};
