import SelectEquipmentComponent from '../../../common/equipmentlisting/components/select.equipment.component';

// const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => {
//     return (
//       <input id={name} type={type} className="daz-checkbox right" name={name} checked={checked} onChange={onChange} />
//     );
//   };

export default function SelectSourceComponent({
  apiEquipmentList,
  checkedItems,
  onCheckEquipment,
  onSourceNextClick,
  openAlertScreen,
  setSelectSensorClassification,
  setIsSelectAlertType,
  isSelectAlertType

}) {
  return (
    <div className="select-source">
      {/* {isTempHumidityClassificationEnabled ? ( */}
        <i
          className=" material-icons notranslate graphoptionicons flip-icon top-left-btn"
          onClick={() => {
            !isSelectAlertType && setSelectSensorClassification(false);
            openAlertScreen('select_source');
            isSelectAlertType && setIsSelectAlertType(false);
          }}>
          forward
        </i>
      {/* ) : (
        ''
      )} */}
      <h3 className="mb-5">Select equipment</h3>
      {/* <ul className="daz-listing">
               
                {apiEquipmentList && apiEquipmentList.map((eachEquipment,i) => {
                         var id_name = `equipment_${i}`
                return <li key={"equipment_" + i} className="flex-btw">
                    <div>
                        <span className="font-bold">{eachEquipment.equipment_name} </span> <span className="font-light">- {eachEquipment.process_display_name}</span>
                    </div> 
                    <div>
                        <Checkbox    name={id_name} checked = {checkedItems[id_name]}  onChange={onCheckEquipment}  />
                        <label htmlFor={id_name}></label>
                    </div>
                </li>
                }) 
                }





           </ul>  */}
      <SelectEquipmentComponent
        checkedItems={checkedItems}
        apiEquipmentList={apiEquipmentList}
        onCheckEquipment={onCheckEquipment}
      />
      <button
        className="btn mt-5 btn-grad pr-5 pl-5 mx-auto"
        disabled={!Object.values(checkedItems).some((val) => val === true)}
        type="submit"
        onClick={onSourceNextClick}>
        Next
      </button>
    </div>
  );
}
