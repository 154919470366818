import SelectSensorClassificationComponent from '../components/select.sensor.classification.component';

export default function SelectSensorClassificationContainer({
  sensorTypeList,
  selectEquipmentAPICall,
  setSelectSensorClassification,
  setCheckedItems,
  setSensorUnit,
  setApiEquipmentList
}) {
  return (
    <div>
      <SelectSensorClassificationComponent
        sensorTypeList={sensorTypeList}
        selectEquipmentAPICall={selectEquipmentAPICall}
        setSelectSensorClassification={setSelectSensorClassification}
        setCheckedItems={setCheckedItems}
        setSensorUnit={setSensorUnit}
        setApiEquipmentList={setApiEquipmentList}
      />
    </div>
  );
}
