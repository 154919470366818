import React, { useRef, useCallback } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CustomEvents from 'highcharts-custom-events';
import DownloadChartContainer from '../../../common/downloadchart/containers/downloadchart.container';
import ChartExpandContainer from '../../../common/chartexpand/containers/chartexpand.container';
import ChartTopFilterContainer from '../../../common/charttopfilter/containers/charttopfilter.container';
import ChartSettingsContainer from '../../../common/chartsettings/containers/chartsettings.container';
CustomEvents(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/offline-exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

export default function MachineKpiTotalGraphComponent({
  chartData,
  jsChartCallback,
  expandWindow,
  minimizeWindow,
  chartId,
  shouldExpand,
  hasFilter,
  hasSettings,
  charRef,
  graphFilter,
  setGraphFilter,
  graphFilterObject,
  chartApiData,
  setKpiMachineStatus,
  setCurrentKpiId,
  kpiId,
  kpiUnitId,
  isCustomGraph,
  setCurrentKpiUnitId,
  setOpenEnterKpiSlider,
  setOpenEquipmentFilter,
  idParams,
  setCustomApiDetails,
  kpiGraphDetails
}) {
  // const [dropdownActive, setDropdownActive] = useState(false);
  // const [buttonFilter, setButtonFilter] = useState(undefined);
  // const [dropdownFilter, setDropdownFilter] = useState(undefined);
  const chartContainerRef = useRef();

  const onWindowChange = useCallback(() => {
    !shouldExpand ? expandWindow(chartId) : minimizeWindow();
  }, [shouldExpand, expandWindow, minimizeWindow, chartId]);

  return (
    <div className="home-chart-layout four-layout">
      <div className="flex-btw top-functions ">
        <div>
          {hasFilter ? (
            <div className="flex-c">
              <ChartTopFilterContainer
                graphFilterObject={graphFilterObject}
                graphFilter={graphFilter}
                setGraphFilter={setGraphFilter}
              />
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="flex-c">
          {chartApiData && chartApiData.equipment_count > 2 && !idParams.equipment_id ? (
            <>
              <span
                className={
                  chartApiData.is_equipment_filter_enabled
                    ? 'equipment-filter active'
                    : 'equipment-filter'
                }
                onClick={() => setOpenEquipmentFilter(true)}>
                Filter
              </span>
            </>
          ) : (
            ''
          )}

          <DownloadChartContainer charRef={charRef} />
          <ChartExpandContainer
            shouldExpand={shouldExpand}
            onWindowChange={onWindowChange}
            charRef={charRef}
            chartContainerRef={chartContainerRef}
          />
          {hasSettings ? (
            <ChartSettingsContainer
              kpiGraphDetails={kpiGraphDetails}
              isGreen={!chartApiData.is_kpi_value_set}
              machineKpi={true}
              setKpiMachineStatus={setKpiMachineStatus}
              setCurrentKpiId={setCurrentKpiId}
              setCurrentKpiUnitId={setCurrentKpiUnitId}
              kpiId={kpiId}
              kpiUnitId={kpiUnitId}
              setOpenEnterKpiSlider={setOpenEnterKpiSlider}
              isCustomGraph={isCustomGraph}
              setCustomApiDetails={setCustomApiDetails}
            />
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="highcharts-wrp" ref={chartContainerRef}>
        <HighchartsReact
          ref={charRef}
          callback={jsChartCallback}
          highcharts={Highcharts}
          options={chartData}
          containerProps={{ style: { height: '100%' } }}
        />
      </div>
    </div>
  );
}
