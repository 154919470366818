import React from 'react';
import { NavLink } from 'react-router-dom';

const CommonNavLink = ({ to, activeClassName, className, children }) => {
  return (
    <NavLink to={to} activeClassName={activeClassName} className={className}>
      {children}
    </NavLink>
  );
};

export default CommonNavLink;