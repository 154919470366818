/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import { useRef } from 'react';
import MainProcessInstantaneousPowerComponent from '../../../common/layout/components/main.process.instantaneous.power.component';
import MachineStatusStackContainer from '../containers/machine.status.stack.container';
import MachineStatusProductionContainer from '../containers/machine.status.production.container';
import MainLayoutComponent from '../../../common/layout/components/main.layout.component';
import MachineStatusFilterEquipmentsContainer from '../containers/machine.status.filter.equipments.container';
import MachineStatusTimelineContainer from '../containers/machine.status.timeline.container';
const routeExpand = new URLSearchParams(location.search).get('expand');
export default function MachineStatusDetailComponent({
  processDetails,
  setOpenMachineStatusSlider,
  selectEquipments,
  setSelectEquipments,
  setOpenEquipmentFilterSlider,
  openEquipmentFilterSlider,
  filterGraphId,
  setFilterGraphId,
  setFilterUpdate,
  filterUpdate,
  customizeUpdate
}) {
  const productionRef = useRef();
  const statusStackRef = useRef();
  const statusTimelineRef = useRef();
  const instantRef = useRef();
  const chartWindows = [
    {
      ref: productionRef,
      component: () => {
        return [
          MachineStatusProductionContainer,
          {
            ...processDetails,
            graph_name: 'Production (%)',
            setOpenMachineStatusSlider,
            setSelectEquipments,
            selectEquipments,
            openEquipmentFilterSlider,
            setOpenEquipmentFilterSlider,
            graphId: 1,
            setFilterGraphId,
            filterUpdate,
            customizeUpdate
          }
        ];
      },
      id: 'productionRef'
    },
  
    {
      ref: statusStackRef,
      component: () => {
        return [
          MachineStatusStackContainer,
          {
            ...processDetails,
            graph_name: 'Equipment status (%)',
            setOpenMachineStatusSlider,
            setSelectEquipments,
            selectEquipments,
            openEquipmentFilterSlider,
            setOpenEquipmentFilterSlider,
            graphId: 2,
            setFilterGraphId,
            filterUpdate,
            customizeUpdate
          }
        ];
      },
      id: 'statusStack'
    },
    {
      ref: statusTimelineRef,
      component: () => {
        return [
            MachineStatusTimelineContainer,
          {
            ...processDetails,
            graph_name: 'Equiment status timeline',
            openEquipmentFilterSlider,
            setOpenEquipmentFilterSlider,
            filterUpdate,
            setOpenMachineStatusSlider,
            setFilterGraphId,
            setFilterUpdate,
            graphId: 3,
            customizeUpdate

          }
        ];
      },
      id: 'statusTimeline'
    },
    {
      ref: instantRef,
      component: () => {
        return [
          MainProcessInstantaneousPowerComponent,
          {
            ...processDetails,
            graph_name: 'Equipment Instantaneous Power (kW)',
            four_layout: true
          }
        ];
      },
      id: 'instantaneousProcess'
    }
  ];

  return (
    <>
      <MainLayoutComponent chartWindows={chartWindows} routeExpand={routeExpand} />
      {openEquipmentFilterSlider && (
        <MachineStatusFilterEquipmentsContainer
          processDetails={processDetails}
          setOpenEquipmentFilterSlider={setOpenEquipmentFilterSlider}
          filterGraphId={filterGraphId}
          setFilterUpdate={setFilterUpdate}
        />
      )}
    </>
  );
}
