import React from 'react';
import DownloadLinkComponent from '../../../modules/exceldownload/components/downloadlink.component';
export default function ContentWrapperComponent(props) {
  return (
    <>
      <DownloadLinkComponent />
      {props.children}
    </>
  );
}
