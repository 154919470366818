/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import betaTag from '../../../assets/images/beta.png';
import { useSelector } from 'react-redux';

function TooltipComponent(props) {
  return (
    <OverlayTrigger placement="auto-start" overlay={<Tooltip>{props.content}</Tooltip>}>
      {props.children}
    </OverlayTrigger>
  );
}

export default function ChangePageViewComponent({ pageView, setPageView }) {
  const location = useLocation();
  const navigate = useNavigate();
  //const searchParamsInitial = new URLSearchParams(location.search);
  //const energyView = searchParamsInitial.get('energy');
  const [changeViewClick, setChangeViewClick] = useState(null);
  // const [prevSearchParams, setPrevSearchParams] = useState(new URLSearchParams(location.search));
   const [searchParams] = useSearchParams();
  const isMachineStatusEnabled = useSelector((state) => state.company.is_machine_status_enabled);
  const pageViewObject = {
    energy: true,
    machine_status: false,
    kpi: false
  };

  useEffect(() => {
    // Update prevSearchParams when location changes
    const kpi = JSON.parse(searchParams.get('kpi'));
    const energy = JSON.parse(searchParams.get('energy'));
   // const machine_status = JSON.parse(searchParams.get('machine_status'));
    
    if(energy == null || energy == true){
      setPageView(pageViewObject);
    }
    else if(kpi == true){
      setPageView({
        energy: false,
        machine_status: false,
        kpi: true
      })
    }else{
      setPageView({
        energy: false,
        machine_status: true,
        kpi:false
      })
    }
    

  }, [location]);

  // useEffect(() => {
  //   // Update prevSearchParams when location changes
  //   setPrevSearchParams(location.search);
  // }, [location]);

  // useEffect(() => {
  //   const previousSearchParams = new URLSearchParams(prevSearchParams);
  //   const kpi = JSON.parse(searchParams.get('kpi'));
  //   const energy = JSON.parse(searchParams.get('energy'));
  //   const machine_status = JSON.parse(searchParams.get('machine_status'));
  //   const prevKpi = JSON.parse(previousSearchParams.get('kpi'));
  //   const prevEnergy = JSON.parse(previousSearchParams.get('energy'));
  //   const prevMachineStatus = JSON.parse(previousSearchParams.get('machine_status'));

  //   if (energy || kpi || machine_status) {
  //     let tempPageView = { kpi: kpi, machine_status: machine_status, energy: energy };
  //     setPageView(tempPageView);
  //   } else {
  //     // When navigating from entirely different page. Page view is default set to energy so just replace the URL
  //     if (prevEnergy || prevKpi || prevMachineStatus) {
  //       searchParams.set('energy', prevEnergy);
  //       searchParams.set('kpi', prevKpi);
  //       searchParams.set('machine_status', prevMachineStatus);
  //     } else {
  //       searchParams.set('energy', true);
  //       searchParams.set('kpi', false);
  //       searchParams.set('machine_status', false);
  //     }
  //     const newQueryString = searchParams.toString();
  //     navigate(`${location.pathname}?${newQueryString}`);
  //   }
  // }, [location.search]);

  // Function to update multiple query parameters at once
  const updateQueryParams = (params) => {
    const searchParams = new URLSearchParams(location.search);
    // Loop through the params object and update each query parameter
    Object.keys(params).forEach((key) => {
      searchParams.set(key, params[key]);
    });

    // Construct the new query string with the updated parameters
    const newQueryString = searchParams.toString();

    // Update the URL with the new query string
    navigate(`${location.pathname}?${newQueryString}`);
  };

  useEffect(() => {
    if (changeViewClick) {
      let tempPageViewObject = pageViewObject;
      Object.keys(tempPageViewObject).forEach((k) => {
        if (tempPageViewObject[k] == true) {
          tempPageViewObject[k] = false;
        }
        if (k == changeViewClick) {
          tempPageViewObject[k] = true;
        }
      });
      updateQueryParams(tempPageViewObject);
      setPageView(tempPageViewObject);
    }
  }, [changeViewClick]);

  return (
    <>
      <span className="pointer" style={{ display: 'flex', alignItems: 'center' }}>
        {pageView.energy ? (
          <>
            <TooltipComponent content="KPI view">
              <i className="r-rotate-icon material-icons material-symbols-outlined notranslate medium-grey "
                    onClick={() => setChangeViewClick('kpi')}
              >vpn_key</i>
            </TooltipComponent>
            {isMachineStatusEnabled && (
              <TooltipComponent content="Machine status view">
                <span style={{ position: 'relative', bottom: '-3px' }}>
                  <i
                    className=" material-icons material-symbols-outlined notranslate medium-grey "
                    onClick={() => setChangeViewClick('machine_status')}
                    style={{ fontSize: '27px' }}
                  >
                    schedule
                  </i>
                </span>
              </TooltipComponent>
            )}
            <TooltipComponent content="Energy view">
            <i className=" material-icons  notranslate  grad-icon " style={{ fontSize: '26px' }} >bolt</i>
            </TooltipComponent>
          </>
        ) : pageView.machine_status ? (
          <>
            <TooltipComponent content="KPI view">
            <i className="r-rotate-icon material-icons material-symbols-outlined notranslate medium-grey "
                    onClick={() => setChangeViewClick('kpi')}
              >vpn_key</i>
            </TooltipComponent>

            {isMachineStatusEnabled && (
              <TooltipComponent content="Machine status view">
                <span style={{ position: 'relative', bottom: '-3px' }}>
                  <i
                    className=" material-icons material-symbols-outlined notranslate grad-icon"
                    style={{ fontSize: '27px' }}
                  >
                    schedule
                  </i>
                  <img
                    src={betaTag}
                    alt="beta"
                    style={{
                      display: 'block',
                      position: 'absolute',
                      width: '30px',
                      bottom: '-10px'
                    }}
                  />
                </span>
              </TooltipComponent>
            )}

            <TooltipComponent content="Energy view">
            <i className=" material-icons  notranslate  medium-grey "  style={{ fontSize: '26px' }} onClick={() => setChangeViewClick('energy')}>bolt</i>
            </TooltipComponent>
          </>
        ) : (
          <>
            <TooltipComponent content="KPI view">
            <i className="r-rotate-icon material-icons material-symbols-outlined notranslate  grad-icon ">vpn_key</i>
            </TooltipComponent>
            {isMachineStatusEnabled && (
              <TooltipComponent content="Machine status view">
                <span style={{ position: 'relative', bottom: '-3px' }}>
                  <i
                    className=" material-icons material-symbols-outlined notranslate  medium-grey "
                    onClick={() => setChangeViewClick('machine_status')}
                    style={{ fontSize: '27px' }}
                  >
                    schedule
                  </i>
                </span>
              </TooltipComponent>
            )}
            <TooltipComponent content="Energy view">
            <i className=" material-icons  notranslate medium-grey "  style={{ fontSize: '26px' }} onClick={() => setChangeViewClick('energy')}>bolt</i>
            </TooltipComponent>
          </>
        )}
      </span>
    </>
  );
}
