import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import useHttpApiCall from '../../../services/useHttpApiCall';
// import { context as ProcessContext } from '../../../context/process.context';

export default function SensorMenuComponent() {
  const [payload, setPayload] = useState(undefined);
  // let [searchParams] = useSearchParams();
  // const kpi_view = searchParams.get('kpi');
  // const energy_view = searchParams.get('energy');

  const [sensor,setSensor]=useState([])
  const [data] = useHttpApiCall(payload);
  // const navigate = useNavigate();

  useEffect(() => {
    setPayload({
      auth: true,
      url: 'v0/company/custom-graph-types/'
    });
  }, []);
  // const [data] = useHttpApiCall(payload);

useEffect(()=>{
  setSensor(data?.data?.data)
},[data])

  // let fetchSidebarLinks = useContext(ProcessContext).fetchSidebarLinks;

  // useEffect(() => {
  //   if (data && data.data) {
  //     fetchSidebarLinks(data.data.data);
  //   }
  // }, [data]);

  // const toggleActiveClass = (e) => {
  //   if (e.target.classList.contains('active')) {
  //     e.target.classList.remove('active');
  //   } else {
  //     e.target.classList.add('active');
  //   }
  // };

  if (sensor?.length) {
    return (
      <div className="daz-sidebar__hoverinfo shadow daz-sidebar__processListing">
        {sensor.map((sensors, i) => {
          return (
            <div key={`${sensors.graph_type}_${i}`} className="daz-sidebar__dropdown-wrp">
              <div
        
                className="daz-sidebar__hoverinfo--item   ">
                {/* <i class="material-icons notranslate graphoptionicons red-color">notifications</i> */}
                <NavLink
                style={sensors?.is_graph_type_enabled?{}:{pointerEvents:'none',opacity:0.5}}
                  className={({ isActive }) => (isActive ? ' daz-sidebar__hoverinfo--active' : '')}
                  to= {`/sensor-add-ons/${sensors?.graph_type}?graph_type_id=${sensors?.graph_type_id}&is_all_timeseries_graph=${sensors?.is_all_timeseries_graph}`}>
                  {' '}
                  {sensors.graph_type}
                </NavLink>
              </div>

            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
}
