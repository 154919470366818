import { useEffect } from 'react';
import moment from 'moment';
import { TimeConvertion } from '../../utilities/utc.time.convertion';

export default function DateRangeSet({
  filterByDateCheck,
  graphFilter,
  dateRange,
  setDateRange,
  setGraphFilter,
  initialFilter,
  setApiDateRange,
  graphInfo
}) {
  useEffect(() => {
    if (!filterByDateCheck && graphFilter.filterMain == '') {
      //if filter is not set reset the filter main
      setGraphFilter({
        filterMain: initialFilter
      });
      setDateRange([null, null]);
    }
  }, [filterByDateCheck]);

  useEffect(() => {
    if (
      filterByDateCheck &&
      graphFilter.filterMain != '' &&
      dateRange.every((val) => val != null)
    ) {
      setDateRange([null, null]);
    }
  }, [graphFilter]);

  useEffect(() => {
    if (dateRange.every((val) => val != null)) {
      let formatedDateRange = dateRange.map((eachDate,index) => {
        if(graphInfo && graphInfo?.isSummaryGraph !== true){
          return TimeConvertion( moment(eachDate).format(index===0?'YYYY-MM-DDT00:00:00':'YYYY-MM-DDT23:59:00'))
          // return  moment(eachDate).utc().format(index===0?'YYYY-MM-DDT00:00:00':'YYYY-MM-DDT23:59:00')+'Z'

        }
    
        return moment(eachDate).format(index===0?'YYYY-MM-DDT00:00:00':'YYYY-MM-DDT23:59:00')
      });

      setApiDateRange(formatedDateRange);
      setGraphFilter({ filterMain: '' });
    }
    if (dateRange.some((val) => val == null && graphFilter.filterMain == '')) {
      setGraphFilter({
        filterMain: initialFilter
      });
    }
  }, [dateRange]);

  return <></>;
}
