import { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { context as KpiContext } from '../../../context/kpi.context';

function MainLayoutComponent(props) {
  const { chartWindows,process } = props;
  const [shouldExpand, setShouldExpand] = useState(false);
  var fetchKpiLinks = useContext(KpiContext).fetchKpiLinks;
  const set_user_accessibility = useSelector(
    (state) => state.userAccessibility.set_user_accessibility
  );

  const openKpiSlider = (e) => {
    e.preventDefault();
    if (props.kpiMessage) {
      var kpiShareStateObject = {
        openKpiScreen: true
      };
    }
    fetchKpiLinks(kpiShareStateObject);
  };

  const minimizeWindow = () => {
    chartWindows.forEach((_window) => {
      _window.ref.current.className = 'col-md-12 col-lg-6 each-col';
    });
    setShouldExpand(false);
  };
  const expandWindow = (id) => {
    chartWindows.forEach((_window) => {
      if (_window.id == id) {
        _window.ref.current.className = 'col-md-12 col-lg-12 h-100 chart-layout-expanded';
      } else {
        _window.ref.current.className = 'd-none';
      }
    });
    setShouldExpand(true);
  };

  useEffect(() => {
    if (!props.routeExpand) {
      minimizeWindow();
    }
  }, [props.routeExpand]);

  if (chartWindows) {
    return (
      <div className="row">
        {chartWindows.length > 0 ? (
          chartWindows.map((_window) => {
            const [ChartComponent, xprops] = _window.component();
            return (
              <div key={_window.id} ref={_window.ref} className="col-md-12 col-lg-6">
                <ChartComponent
                  expandWindow={expandWindow}
                  minimizeWindow={minimizeWindow}
                  id={_window.id}
                  kpi_id={_window.kpi_id}
                  shouldExpand={shouldExpand}
                  userAccessibility={set_user_accessibility}
                  {...xprops}
                  process={process}

                />
              </div>
            );
          })
        ) : props.kpiMessage ? (
          <h4 className="text-center pt-3 w-100">
            KPI is not set. Please{' '}
            <a href="#" onClick={(e) => openKpiSlider(e)}>
              Set KPI
            </a>{' '}
            to view graphs.
          </h4>
        ) : (
          ''
        )}
      </div>
    );
  } else {
    return null;
  }
}

export default MainLayoutComponent;
