import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
export default function DownloadChartComponent({ downloadChart }) {
  return (
    <Dropdown className="daz-icon__dropdown" align="end">
      <Dropdown.Toggle>
        <OverlayTrigger placement="top" overlay={<Tooltip>Download</Tooltip>}>
          <i className="material-icons notranslate graphoptionicons">file_download</i>
        </OverlayTrigger>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          <button
            onClick={() => {
              downloadChart('image/jpeg');
            }}>
            <span className="semi-bold">JPEG</span> (Graph)
          </button>
        </Dropdown.Item>
        {/* <Dropdown.Item><button disabled  onClick = { () => { downloadChart("csv")} } ><span className="semi-bold">CSV</span> (Data)</button></Dropdown.Item> */}
        {/* <div><button  onClick = { () => { downloadChart("excel")} } ><span className="semi-bold">Excel</span> (Data)</button>
                        <div>
                             <Dropdown.Item>One</Dropdown.Item>
                        </div>
                        
                        </div> */}
        {/* <Dropdown drop='end'>
                        <Dropdown.Toggle >
                        <span className="semi-bold">Excel</span> (Data)
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                           <Dropdown.Item><button  onClick = { () => { downloadChart("image/jpeg")} } >Summary</button></Dropdown.Item>
                           <Dropdown.Item><button  onClick = { () => { downloadChart("image/jpeg")} } >Hourly data</button></Dropdown.Item>
                        </Dropdown.Menu>
                        </Dropdown> */}
      </Dropdown.Menu>
    </Dropdown>
  );
}
