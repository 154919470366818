import { forwardRef, useEffect, useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import useOutsideAlerter from '../../../utilities/close.slider.outside.click';

export default function MachineKpiEnterComponent({
  inputHandler,
  onFormSubmit,
  startDate,
  kpiMonthShow,
  kpiYearShow,
  onMonthChange,
  formEdited,
  showToast,
  setShowToast,
  setOpenEnterKpiSlider,
  kpiApiData,
  updatedEquipments
}) {
  const ExampleCustomInput = forwardRef(({ onClick }, ref) => (
    <i onClick={onClick} ref={ref} className=" material-icons notranslate graphoptionicons">
      arrow_drop_down
    </i>
  ));
  ExampleCustomInput.displayName = 'ExampleCustomInput';
  const sliderRef = useRef(null);
  const [inputFocus,setInputFocus]=useState(false)
  var isClickedOutside = useOutsideAlerter(sliderRef);
  useEffect(() => {
    if (isClickedOutside) {
      setOpenEnterKpiSlider(false);
    }
  }, [isClickedOutside]);
const handleBlur=(e,method)=>{
  setInputFocus(e.target.id)
  inputHandler(e,method)
}

const getInputValue=(value,type,id)=>{
  if(type==='AUTO' && inputFocus!==id && !updatedEquipments.includes(id)){
    return value +' (Automatic entry)'
  }
  else{
    return value

  }
}
  return (
    <div
      ref={sliderRef}
      className={
        formEdited
          ? 'sidebar-slider enterKpiSlider formEdited inside-component'
          : 'sidebar-slider enterKpiSlider inside-component'
      }>
      <Alert
        className="sidebar-slider_alert"
        show={showToast}
        variant="success"
        onClose={() => setShowToast(false)}>
        <p className="text-center">KPI values updated successfully</p>
      </Alert>

      <i
        className="sidebar-slider_close material-icons notranslate graphoptionicons"
        onClick={() => {
          setOpenEnterKpiSlider(false);
        }}>
        cancel
      </i>
      <div className="enterKpiSlider_enter ">
        <div className="text-center">
          <h3>Enter KPI</h3>
          <p className="mt-2 mb-2 txt-15">for</p>
          <div className="flex-c-m position-relative mb-3">
            <div className="kpiDatepicker_monthyear">
              <h3>{kpiMonthShow}</h3>
              <p>{kpiYearShow}</p>
            </div>
            {/* <label   className="kpiDatepicker_label"> */}

            <DatePicker
              selected={startDate}
              onChange={onMonthChange}
              dateFormat="MMMM yyyy"
              showMonthYearPicker
              calendarClassName="kpiDatepickerCalendar"
              className="kpiDatepicker"
              customInput={<ExampleCustomInput />}
              openToDate={startDate}
            />
          </div>
        </div>
        <form onSubmit={onFormSubmit} className="kpiEnterForm">
          {kpiApiData &&
            kpiApiData.map((eachKpi, i) => {
              return (
                <div key={eachKpi.kpi + i}>
                  <div className="mb-2 mt-4 semi-bold">
                    {eachKpi.kpi} <span className="unitname font-light">({eachKpi.kpi_unit})</span>
                  </div>
                  {eachKpi.equipments.map((eachEquipment) => {
                    return (
                      <div key={eachEquipment.equipment_kpi_id} className="form-group">
                        <label className="mb-1">{eachEquipment.equipment_name} </label>
                        <input
                          className="form-control shadow border-0 py-4"
                          type="text"
                          min="0"
                          step="any"
                          id={eachEquipment.equipment_kpi_id}
                          data-kpi-id={eachKpi.kpi_id}
                          data-kpi-unit-id={eachKpi.kpi_unit_id}
                          name={eachEquipment.equipment_name}
                          // defaultValue={eachEquipment.kpi_value +!inputFocus?'Automatic entry':''}
                         value={getInputValue(eachEquipment.kpi_value,eachEquipment?.input_method,eachEquipment.equipment_kpi_id)}
                          onFocus={(e)=>{eachEquipment?.input_method==='AUTO'?handleBlur(e,eachEquipment?.input_method):()=>{}}}
                          onChange={(e) => inputHandler(e,eachEquipment?.input_method)}></input>
                      </div>
                    );
                  })}
                </div>
              );
            })}

          <button
            className="btn mt-4 btn-grad pr-5 pl-5 mx-auto"
            type="submit"
            disabled={!formEdited}>
            Update
          </button>
        </form>
      </div>
    </div>
  );
}
