const Checkbox = ({ type = 'checkbox', name, checked = false, onChange }) => {
  return (
    <input
      id={name}
      type={type}
      className="daz-checkbox right"
      name={name}
      checked={checked}
      onChange={onChange}
    />
  );
};
export default function SelectEquipmentComponent({
  apiEquipmentList,
  onCheckEquipment,
  checkedItems,
  isSelectUnselect
}) {
  return (
    <ul className="daz-listing">
      {apiEquipmentList &&
        apiEquipmentList.map((eachEquipment, i) => {
          var id_name;
          isSelectUnselect
            ? (id_name = `equipment_${eachEquipment.id}`)
            : (id_name = `equipment_${i}`);
          return (
            <li key={'equipment_' + i} className="flex-btw">
              <div>
              {
                  eachEquipment?.color&& <span style={{ backgroundColor: `${ eachEquipment?.color}`, width: '10px',height:'10px',display:'inline-block',borderRadius:'50px',marginRight:'10px' }}></span>
                }
             

                <span className="font-bold">{eachEquipment.equipment_name} </span>{' '}
                <span className="font-light"> {eachEquipment.process_display_name&&'-'}{eachEquipment.process_display_name}</span>
              </div>
              <div>
                <Checkbox
                  name={id_name}
                  checked={checkedItems[id_name]}
                  onChange={onCheckEquipment}
                />
                <label htmlFor={id_name}></label>
              </div>
            </li>
          );
        })}
    </ul>
  );
}
