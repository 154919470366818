import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Toast, ToastContainer } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setExcelDownload } from '../../../redux/exceldownload/exceldownload.action';
import useHttpApiCall from '../../../services/useHttpApiCall';
import { replaceHyphenWithSpace } from '../../../utilities/common.computations';
export default function DownloadLinkComponent() {
  // const TIME_TO_POLL = 1*60*1000 // 1min
  const TIME_TO_POLL = 1000 * 10; // 1min
  const set_excel_download = useSelector((state) => state.excelDownload.set_excel_download);
  const [payload, setPayload] = useState(null);
  const [downloadURL, setDownloadURL] = useState([]);
  const [data] = useHttpApiCall(payload);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log('set_excel_download', set_excel_download);
    if (set_excel_download && set_excel_download.cache_download_id) {
      console.log('now');
      callDownloadURL();
    } else if (set_excel_download && set_excel_download.download_ids) {
      console.log('timeout');
      setTimeout(() => {
        callDownloadURL();
      }, TIME_TO_POLL);
    }
  }, [set_excel_download]);

  const callDownloadURL = () => {
    let download_ids = localStorage.getItem('download_ids');
    if (download_ids) {
      setPayload({
        auth: true,
        url: 'v0/downloads/',
        params: { ids: download_ids }
      });
    }
  };

  const onCancelDownload = (id) => {
    let closeIndex = downloadURL.findIndex((v) => {
      return v.id == id;
    });
    let downloadURLCopy = [...downloadURL];
    downloadURLCopy[closeIndex].showStatus = false;
    setDownloadURL(downloadURLCopy);
  };

  useEffect(() => {
    if (data && data.data) {
      dispatch(
        setExcelDownload({
          ...set_excel_download,
          download_ids: null,
          cache_download_id: null
        })
      );
      let apiData = data.data.data;
      let apiDataWithShowStatus = data.data.data.map((v) => {
        return { ...v, showStatus: true };
      });

      let completedDownloadURL = apiDataWithShowStatus.filter((v) => v.download_status == true);
      setDownloadURL((prev) => [...prev, ...completedDownloadURL]);

      console.log('completedDownloadURL', completedDownloadURL);
      let download_ids;
      if (localStorage.getItem('download_ids') == undefined) {
        download_ids = [];
      } else {
        download_ids = localStorage
          .getItem('download_ids')
          .split(',')
          .map(function (item) {
            return parseInt(item, 10);
          });
      }

      if (completedDownloadURL.length > 0) {
        console.log('localstorage fase id if true', download_ids);

        completedDownloadURL.forEach((completedUnit) => {
          if (download_ids.includes(completedUnit.id)) {
            let index = download_ids.findIndex((v) => v == completedUnit.id);
            download_ids.splice(index, 1);
            console.log('removed id presennt', download_ids);
          }
        });
      }

      if (apiData.some((v) => v.download_status == false)) {
        //at least one download staus is 0, then poll again
        console.log('remaining fase ids', download_ids);
        localStorage.setItem('download_ids', download_ids.join(','));
        setTimeout(() => {
          callDownloadURL();
        }, TIME_TO_POLL);
      } else {
        localStorage.removeItem('download_ids');
      }
    }
  }, [data]);

  useEffect(() => {
    if (downloadURL.length > 0) {
      console.log('download url set', downloadURL);
      dispatch(
        setExcelDownload({
          ...set_excel_download,
          download_status: false
        })
      );
    }
  }, [downloadURL]);

  return (
    <div className="position-relative">
      <ToastContainer position="top-end" className="p-3 excel-download-toast">
        {downloadURL &&
          downloadURL
            .slice(0)
            .reverse()
            .map((eachUrl, i) => {
              return (
                <Toast
                  key={i}
                  onClose={() => onCancelDownload(eachUrl.id)}
                  show={eachUrl.showStatus}>
                  <Toast.Header>
                    <strong className="me-auto">Excel Export Completed </strong>
                  </Toast.Header>
                  <Toast.Body>
                    <p>
                      The <span className="semi-bold">{replaceHyphenWithSpace(eachUrl.data)}</span>{' '}
                      data with{' '}
                      <span className="semi-bold">
                        {eachUrl.data_resolution == 'least-interval'
                          ? '2 minute'
                          : eachUrl.data_resolution == 'temperature-humidity-least-interval'
                          ? '30 minutes'
                          : eachUrl.data_resolution}
                      </span>{' '}
                      values from <br />
                      <span className="semi-bold">
                        {moment.utc(eachUrl.start_date_time).format('yyyy-MM-DD, HH:mm')}
                      </span>{' '}
                      &#8212;{' '}
                      <span className="semi-bold">
                        {moment.utc(eachUrl.end_date_time).format('yyyy-MM-DD, HH:mm')}
                      </span>
                      .
                    </p>
                    <div className="flex-c">
                      <Button
                        size="sm"
                        className="btn-grad ms-0"
                        href={eachUrl.download_url}
                        variant="primary">
                        Download
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => onCancelDownload(eachUrl.id)}
                        variant="secondary">
                        Cancel
                      </Button>
                    </div>
                  </Toast.Body>
                </Toast>
              );
            })}
      </ToastContainer>
    </div>
  );
}
