import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// import { roundToTwoDecimal } from '../../utilities/common.computations';

export default function TotalInstantaniousPowerTotalValue({ total }) {
  return (
    <div className="flex-c-e txt-11">
      <span className=''>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Total value for the equipment during the selected time frame</Tooltip>}>
          <div className="flex-c">
            <span className="yellow material-icons notranslate-outlined txt-20 ">bolt</span>
            Total:&nbsp;<span className="semi-bold"> {total!==0? (total?.toFixed(2)+" kWh"):""} </span>
            {/* &nbsp; - &nbsp;<span className=""> {time} </span> */}

          </div>
        </OverlayTrigger>
      </span>
    </div>
  );
}
