function roundToTwoDecimal(number) {
  return Math.round(number * 100) / 100;
}

function replaceUnderscoreWithHyphen(str) {
  return str.replace(/_/g, '-');
}

function replaceHyphenWithSpace(str) {
  return str.replace(/-/g, ' ');
}

export { roundToTwoDecimal, replaceUnderscoreWithHyphen, replaceHyphenWithSpace };
