// ==============================|| PRIVATE PAGES ROUTING WITH HEADER ||============================== //

import { Navigate } from 'react-router-dom';
import PrivateLayoutComponent from '../hoc/layout/components/private.layout.component';
import { isAuthenticated } from '../utilities/local.storage';
import { lazy } from 'react';
import PlantLayerContainer from '../modules/plantLayer/containers/plantlayer.container';
import CompanyLayerComponent from '../modules/companyLayer/components/company.component';
import PlantDetailComponent from '../modules/plantdetail/components/plant.detail.component';
// import ProcessLayerLayoutComponent from '../modules/processLayerDetail/components/processLayerlayout.component';
// import ProcessLayerDetailComponent from '../modules/processLayerDetail/components/processLayer.detail.component';
import ProcessLayerContainer from '../modules/processlayer/containers/processlayer.container';
import ProcessLayerDetailComponent from '../modules/processLayerDetail/components/processLayer.detail.component';
// import EquipmentDetailComponent from '../modules/equipmentDeail/components/equipment.detail.component';
import ComponentDetailComponent from '../modules/componentDeail/components/component.detail.component';
import EquipmentLayerDetailComponent from '../modules/EquipmentLayerDetail/components/equipmentLayer.detail.component';

const HomeComponent = lazy(() => import('../modules/home/components/home.component'));
const ProcessesContainer = lazy(() =>
  import('../modules/processes/containers/processes.container')
);
const ProcessDetailComponent = lazy(() =>
  import('../modules/processdetail/components/process.detail.component')
);
const MachineDetailComponent = lazy(() =>
  import('../modules/machinedetail/components/machine.detail.component')
);
const KpiComponent = lazy(() => import('../modules/kpi/components/kpi.component'));
const WidgetDetailContainer = lazy(() =>
  import('../modules/widgetdetail/containers/widgetdetail.container')
);
const CustomGraphContainer = lazy(() =>
  import('../modules/customgraph/containers/customgraph.container')
);
const CustomGraphListContainer = lazy(() =>
  import('../modules/customgraph/containers/customgraph.list.container')
);
const ProfileContainer = lazy(() => import('../modules/profile/containers/profile.container'));

export const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/login" />;
};
const privateRoutes = {
  path: '/',
  element: (
    <PrivateRoute>
      <PrivateLayoutComponent />
    </PrivateRoute>
  ),
  children: [
    {
      path: '/home',
      element: <HomeComponent />
    },
    {
      path: '/company',
      element: <CompanyLayerComponent />
    },
    {
      path: '/plant',
      element: <PlantDetailComponent />
    },
    {
      path: '/plant/:process',
      element: <PlantDetailComponent />
    },
    {
      path: '/layer_process/:process',
      element: <ProcessLayerDetailComponent />
    },
    {
      path: '/layer_process',
      element: <ProcessLayerContainer/>
    },
    {
      path: '/component/:process',
      element: <ComponentDetailComponent/>
    },
    {
      path: '/layer_equipment/:process',
      element: <EquipmentLayerDetailComponent/>
    },
    // {
    //   path: '/layer_equipment',
    //   element: <ProcessLayerContainer/>
    // },
       {
      path: '/processes',
      element: <ProcessesContainer />
    },
    {
      path: '/processes/:process',
      element: <ProcessDetailComponent />
    },
    {
      path: '/processes/:process/:machine',
      element: <MachineDetailComponent/>
    },
    {
      path: '/kpi',
      element: <KpiComponent />
    },
    {
      path: '/sensor-add-ons/:type',
      element: <CustomGraphListContainer />
    },
    {
      path: '/sensor-add-ons',
      element: <CustomGraphContainer />
    },
    {
      path: '/widget',
      element: <WidgetDetailContainer />
    },
    {
      path: '/profile',
      element: <ProfileContainer />
    },
    {
      path: '/dummy',
      element: <PlantLayerContainer />
    }
  ]
};

export default privateRoutes;
