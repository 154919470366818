/* eslint-disable no-undef */
import useHttpApiCall from '../../../services/useHttpApiCall';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  apiCallOnce,
  customGraphEnabled,
  sensorClassification,
  variablePricingEnabled,
  singleEquipmentCustomer,
  externalAccessEnabled,
  machineStatusEnabled,
  incomersEnabled,
  generatorsEnabled,
  flexibleEquipmentEnabled,
  // drilldownLayerList
} from '../../../redux/company/company.action';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { setUserAccessibility } from '../../../redux/useraccessibility/useraccessibility.action';
import { useLocation, } from 'react-router-dom';
import ReactGA from 'react-ga4';
import addClarityScript from '../../../analytics/clarity';
//const { NODE_ENV } = process.env;

export default function MainWrapperComponent(props) {
  const [payload, setPayload] = useState(null);
  const [data] = useHttpApiCall(payload);
  const [payload2, setPayload2] = useState(null);
  const [data2] = useHttpApiCall(payload2);
  // const [layerListingProps, setLayerListingProps] = useState(null);
  // const [layers] = useHttpApiCall(layerListingProps);
  // const set_user_accessibility = useSelector(
  //   (state) => state.userAccessibility.set_user_accessibility
  // );
  const apiCallOnceRedux = useSelector((state) => state.company.api_call_once);
  const [isOperator] = useState(localStorage.getItem('is_operator'));
  const { trackPageView } = useMatomo();
  const dispatch = useDispatch();
  // const [callAPI, setCallAPI] = useState({ data1: false, data2: false });
  const location = useLocation();
  // const navigate = useNavigate();
  const flexible_equipment=localStorage.getItem('flexible_equipment')=='true'?true:false
  // const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')

  // const flexibleEquipmentEnabled = useSelector((state) => state.company.is_flexible_equipment_categorization_enabled);
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line no-undef
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_KEY) {
      ReactGA.send({ hitType: 'pageview', page: location });
    }
  }, [location]);

  useEffect(() => {
    if (!apiCallOnceRedux.company) {
      if(flexible_equipment){
        setPayload({
          auth: true,
          url: 'v0/layers/parent-companies/basic',
          params:{
            layer_type:'company'
          }
        });
      }
      else{
        setPayload({
          auth: true,
          url: 'v1/company/'
        });
      }
     
    }
  }, []);

  // useEffect(() => {
  //   setPlantListPayload({
  //     auth: true,
  //     url: 'v1/layers/plants/',
  //   });
  // }, []);

  useEffect(() => {
    if (data && data.data) {
      let apiData = data.data.data;
      

      dispatch(apiCallOnce({ ...apiCallOnceRedux, company: true }));
      dispatch(sensorClassification(apiData.is_temp_humidity_classification_enabled));
      dispatch(customGraphEnabled(apiData.is_custom_graph_enabled));
      dispatch(variablePricingEnabled(apiData.is_variable_pricing_enabled));
      dispatch(singleEquipmentCustomer(apiData.is_single_equipment_customer));
      dispatch(externalAccessEnabled(data.data.data.is_external_access_enabled));
      dispatch(machineStatusEnabled(apiData.is_machine_status_enabled));
      dispatch(incomersEnabled(apiData.is_incomers_classification_enabled));
      dispatch(generatorsEnabled(apiData.is_generators_classification_enabled));
      dispatch(flexibleEquipmentEnabled(apiData?.is_flexible_equipment_categorization_enabled));
      // if(apiData?.is_flexible_equipment_categorization_enabled){
      //   setLayerListingProps({
      //     auth: true,
      //     url: 'v1/layers/'
      //   });
      // }
      if (
        process.env.REACT_APP_GOOGLE_ANALYTICS_KEY &&
        !apiData.is_demo_account &&
        apiData.enable_alarm_notification
      ) {
        console.log('enter google analytics');
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
      }

      if (
        process.env.REACT_APP_CLARITY_ANALYTICS_KEY &&
        !apiData.is_demo_account &&
        apiData.enable_alarm_notification
      ) {
        addClarityScript(process.env.REACT_APP_CLARITY_ANALYTICS_KEY);
      }

      if (
        apiData.is_demo_account != true &&
        apiData.is_operator == true &&
        apiData.enable_alarm_notification
      ) {
        document.title = `DAZOQ - ${apiData.name} - OPERATOR`;
      } else {
        document.title = `DAZOQ - ${apiData.name}`;
      }
      // trackPageView()
    }
  }, [data]);
  // useEffect(()=>{
  //   dispatch(drilldownLayerList(layers?.data?.data?.layers));
  //   if (data && data.data) {


      // `/plant/${encodeURIComponent(data.point.category)}?process_type=${
      //   data.point.layer_type
      // }&plant_id=${data.point.layer_id}&plant_code=${data.point.plant_code}`


      // let apiData = data.data.data;

      // if(apiData?.is_flexible_equipment_categorization_enabled){
      //   if(apiData?.is_company_layer_active){
      //     navigate(`/${plantMenu.layer_type}/${encodeURIComponent(plantMenu?.name)}?process_type=${plantMenu.layer_type}&plant_id=${plantMenu?.layer_id}&plant_code=${plantMenu?.plant_code}`)
      //   }
      // }
      //   else{
      //     navigate('/plant')
      //     localStorage.setItem('plantCode',layers?.data?.data?.layers[0].plant_code)

      //   }
      // }
    // }
    // navigate('/company')
  // },[layers])

  useEffect(() => {
    if (!apiCallOnceRedux.accessibilitySettings && isOperator == 'true') {
      setPayload2({
        auth: true,
        url: 'v0/users/accessibility-settings/'
      });
    }
  }, [apiCallOnceRedux]);

  // useEffect(() => {
  //   console.log("callAPI",callAPI.data2)

  // }, [callAPI.data2])

  useEffect(() => {
    if (data2 && data2.data) {
      dispatch(apiCallOnce({ ...apiCallOnceRedux, accessibilitySettings: true }));
      if (data2.data.status == 1) {
        let responseData = data2.data.data;
        localStorage.setItem(
          'chart_font_weight',
          responseData.font_weight == 'regular' ? 'normal' : responseData.font_weight
        );
        localStorage.setItem('chart_font_size_percentage', responseData.text_size_percentage);

        dispatch(
          setUserAccessibility({
            text_size_percentage: responseData.text_size_percentage,
            font_weight:
              responseData.font_weight == 'regular' ? 'normal' : responseData.font_weight,
            id: responseData.id
          })
        );
      }
      // else{
      //   dispatch(setUserAccessibility(""))
      // }
    }
  }, [data2]);



  return <>{props.children}</>;
}
