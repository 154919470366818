import React, { useState, useRef, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MainLayoutComponent from '../../../common/layout/components/main.layout.component';
import { context as ProcessContext } from '../../../context/process.context';
import { NavLink, Link, useSearchParams } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import MainProcessEnergyUseComponent from '../../../common/layout/components/main.process.energy.use.component';
import MainInstantaneousPowerComponent from '../../../common/layout/components/main.instantaneous.power.component';
import MainTotalEnergyUseComponent from '../../../common/layout/components/main.total.energy.use.component';
import MainProcessInstantaneousPowerComponent from '../../../common/layout/components/main.process.instantaneous.power.component';
import ReactGA from 'react-ga4';
import KpiMachineLayoutComponent from '../../../common/layout/components/kpi.machine.layout.component';
import ChangePageViewComponent from '../../../common/layout/components/change.page.view.component';
import MachineStatusDetailContainer from '../../machinestatus/containers/machine.status.detail.container';
import {  useDispatch, useSelector } from 'react-redux';
import useHttpApiCall from '../../../services/useHttpApiCall';
import { machineStatusEnabled } from '../../../redux/company/company.action';

// function getInitialIndex(list,process){
//   console.log(list.indexOf(process))
//   return list.indexOf(process)
// }

// function getInitialIndex(list,process_id,sub_process_id,process_type){

//   var index = 0;
//   for(let i=0 ;i < list.length ; i ++){
//     if(list[i].process_id == process_id && list[i].process_type == process_type && list[i].sub_process_id == sub_process_id ){
//       index = i;
//     }
//   }
//   return index;
// }

function PlantDetailComponent() {
  const energyRef = useRef();
  const processRef = useRef();
  const peakRef = useRef();
  const instantRef = useRef();
  let processList = useContext(ProcessContext).state.sidebarLinks?.layers;
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')=='true'?true:false

  const [payload, setPayload] = useState(null);
  const [data] = useHttpApiCall(payload);
  const dispatch = useDispatch();

  let chartWindows;
  processList = processList?.map((process) => ({
    
    process: process.name,
    layer_id: process.layer_id,
    process_type: process.layer_type,
    // sub_process_id: process.sub_process_id,
    is_zone_enabled: process.ugn_zones ? true : false,
    is_layer_active:process.is_layer_active,
    plant_code:process?.plant_code
  }));

  let [searchParams] = useSearchParams();

  const isMachineStatusEnabled = useSelector((state) => state.company.is_machine_status_enabled);
  const isCompanyLayer=localStorage.getItem('company_layer')=='true'?true:false


  const process_type = searchParams.get('process_type');
  const process_id = searchParams.get('process_id');
 
const plant_id = searchParams.get('plant_id')=='null'?processList?.[0].layer_id:searchParams.get('plant_id');
const plant_code = searchParams.get('plant_code')=='null'?processList?.[0]?.plant_code:searchParams.get('plant_code');
  const flexible_equipment=localStorage.getItem('flexible_equipment')=='true'?true:false


  
  const sub_process_id = searchParams.get('sub_process_id');
  // const is_zone_enabled = searchParams.get('is_zone_enabled');
  const kpi_view = searchParams.get('kpi');
  const machine_status_view = searchParams.get('machine_status');
  //const energy_view = searchParams.get('energy');
  let pageViewObject;
  if (kpi_view == 'true') {
    pageViewObject = {
      energy: false,
      machine_status: true,
      kpi: false
    };
  } else if (machine_status_view == 'true') {
    pageViewObject = {
      energy: false,
      machine_status: true,
      kpi: false
    };
  } else {
    pageViewObject = {
      energy: false,
      machine_status: true,
      kpi: false
    };
  }

  const [pageView, setPageView] = useState(pageViewObject);

  const { process } = useParams();
  useEffect(()=>{
    if(flexible_equipment){
      setPayload({
        auth: true,
        url: 'v0/layers/parent-companies/basic',
        params:{
          layer_type:'plant',
          plant_code:plant_code
        }
      });
    }
  },[plant_code])
  useEffect(()=>{
    if(flexibleEquipmentEnabled){
      if (data?.data?.data?.company_info?. kpi_view == 'true') {
        pageViewObject = {
          energy: false,
          kpi: true
        };
      } else if (data?.data?.data?.company_info?. is_machine_status_enabled == true) {
      // dispatch(machineStatusEnabled(data?.data?.data?.company_info?. is_machine_status_enabled));
      dispatch(machineStatusEnabled(false));


        pageViewObject = {
          energy: false,
          machine_status: true,
          kpi: false
        };
      } else {
        pageViewObject = {
          energy: true,
          kpi: false,
          // machine_status: true,
        };
      }
      // setPageView(pageViewObject)
    }
   
  },[data])

  //const [currentProcessIndex,setCurrentProcessIndex]= useState(getInitialIndex(processList,searchParams.get('process_id'),searchParams.get('sub_process_id'),searchParams.get('process_type')))
  chartWindows = [
    {
      ref: energyRef,
      component: () => {
        return [
          MainTotalEnergyUseComponent,
          {
            drilldownlevel: 'Plant',
            process_type: process_type,
            process_id: process_id,
            sub_process_id: sub_process_id,
            graph_name: 'Total energy use (kWh)',
            plant_id:plant_id,
            plant_code:plant_code,
            layer_id:plant_id,
            graph_tooltip:
              'The amount of power consumed by all measured equipment within this process.'
          }
        ];
      },
      id: 'energyProcess'
    },
    {
      ref: processRef,
      component: () => {
        return [
          MainProcessEnergyUseComponent,
          {
            drilldownlevel: 'Plant',
            process_type: process_type,
            process_id: process_id,
            sub_process_id: sub_process_id,
            graph_name: 'Process energy use (kWh)',
            plant_id:plant_id,
            plant_code:plant_code,
          }
        ];
      },
      id: 'processProcess'
    },
    {
      ref: peakRef,
      component: () => {
        return [
          MainInstantaneousPowerComponent,
          {
            drilldownlevel: 'Plant',
            process_type: process_type,
            process_id: process_id,
            sub_process_id: sub_process_id,
            plant_id:plant_id,
            plant_code:plant_code,
            graph_name: 'Instantaneous Power (kW)'
          }
        ];
      },
      id: 'peakProcess'
    },
    {
      ref: instantRef,
      component: () => {
        return [
          MainProcessInstantaneousPowerComponent,
          {
            drilldownlevel: 'Plant',
            process_type: process_type,
            process_id: process_id,
            sub_process_id: sub_process_id,
            plant_id:plant_id,
            plant_code:plant_code,
            graph_name: 'Equipment Instantaneous Power (kW)'
          }
        ];
      },
      id: 'instantaneousProcess'
    }
  ];
  // let params = new URLSearchParams(document.location.search);
  // let energy = params.get("energy");
  // let machine = params.get("machine_status");
  // let kpi = params.get("kpi");

    return (
    <>
          <div className="flex-btw mb-4">
       <h6 className="inner-breadcrumb w-25">
       {
        isCompanyLayer&&   <Link to={flexibleEquipmentEnabled==true?"/company":"/home"}>
        <i className=" material-icons notranslate graphoptionicons flip-icon">forward</i>Home
      </Link>
       } 
       
        </h6>
        {
          !flexibleEquipmentEnabled?<h4 className="font-light">{decodeURIComponent(process)}</h4>:<h4 className="font-light">{decodeURIComponent(process)=='null'?processList?.length>0?processList[0]?.process:'':decodeURIComponent(process)}</h4>
        }
     

        <div className="flex-c w-25 justify-content-end">
          <div className="me-3">
            <div className="process-nav-dropdown">
              <Dropdown className="bt-dropdown-nav ">
                <Dropdown.Toggle size="sm" variant="secondary">
                  Plants
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {processList?.map((process, i) => {
                  // to={`/${plantMenu.layer_type}/${encodeURIComponent(plantMenu?.name)}?process_type=${plantMenu.layer_type}&plant_id=${plantMenu?.layer_id}&plant_code=${plantMenu?.plant_code}`}
                     return (
                      <Dropdown.Item key={`processdropdown${i}`} disabled={!process?.is_layer_active}>
                        <NavLink
                        style={{fontWeight:'100'}}
                          end
                          activeClassName={'bt-dropdown--active'}
                          disabled={!process?.is_layer_active}
                          to={process?.is_layer_active&&
                           `/${process?.process_type}/${encodeURIComponent(process?.process)}?process_type=${process.layer_type}&plant_id=${process?.layer_id}&plant_code=${process?.plant_code}`
                          }
                          onClick={ReactGA.event({
                            category: 'Process dropdown click',
                            action: 'Selected process from dropdown',
                            label: `Process ${encodeURIComponent(process.process)} selected`
                          })}>
                          {process.process}
                        </NavLink>
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <ChangePageViewComponent pageView={pageView} setPageView={setPageView}plant_code={plant_code} />
          
        </div>
      </div>

      {/* <h4 className="process-nav">
     
      { Boolean(currentProcessIndex) && <i onClick={()=>{setCurrentProcessIndex(prev => { changeRoute(prev - 1); return prev -1  })} } className="material-icons notranslate graphoptionicons flip-icon">forward</i>}
      {
        processList[currentProcessIndex].process
      }

      { Boolean(currentProcessIndex !== processList.length - 1 ) &&<i onClick={()=>{setCurrentProcessIndex(prev => { changeRoute(prev + 1); return prev +1  })} } className=" material-icons notranslate graphoptionicons">forward</i>}
      </h4> */}

            {pageView.energy ? (
                <MainLayoutComponent chartWindows={chartWindows} process={process} />
      ) : pageView.machine_status && isMachineStatusEnabled ? (
                        <MachineStatusDetailContainer
          processDetails={{
            drilldownlevel: 'Plant',
            process_type: process_type,
            process_id: process_id,
            sub_process_id: sub_process_id,
            plant_id:plant_id,
            plant_code:plant_code
          }}
        />
              ) : (
        <KpiMachineLayoutComponent
          processDetails={{
            drilldownlevel: 'Plant',
            process_type: process_type,
            process_id: process_id,
            sub_process_id: sub_process_id,
            plant_id:plant_id,
            plant_code:plant_code,
          }}
        />
      )}
    </>
  );
}

export default React.memo(PlantDetailComponent);
