import SelectSourceDownloadComponent from '../components/select.source.download.component';

export default function SelectSourceDownloadContainer({
  onSourceNextClick,
  setCheckedItems,
  checkedItems,
  apiEquipmentList,
  isTempHumidityClassificationEnabled,
  openDownloadScreen,
  setSelectSensorClassification
}) {
  const onCheckEquipment = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
  };
  return (
    <SelectSourceDownloadComponent
      apiEquipmentList={apiEquipmentList}
      setCheckedItems={setCheckedItems}
      checkedItems={checkedItems}
      onCheckEquipment={onCheckEquipment}
      onSourceNextClick={onSourceNextClick}
      isTempHumidityClassificationEnabled={isTempHumidityClassificationEnabled}
      openDownloadScreen={openDownloadScreen}
      setSelectSensorClassification={setSelectSensorClassification}
    />
  );
}
