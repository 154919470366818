function SelectSensorClassificationComponent({
  sensorTypeList,
  selectEquipmentAPICall,
  setSelectSensorClassification,
  setCheckedItems,
  setSensorUnit,
  setApiEquipmentList
}) {
  const onSensorTypeSelect = ({ slug, id }) => {
    //    console.log("qe",slug,id)
    setSensorUnit(slug);
    setCheckedItems([]);
    setApiEquipmentList([]);
    setSelectSensorClassification(true);
    selectEquipmentAPICall(id);
  };

  return (
    <div className="select-sensor-classification">
      <h3>Select sensor type</h3>
      <p className="mt-2 mb-5 txt-15">
        Select the type of sensor you <br></br> want to download data for.
      </p>

      <div>
        {sensorTypeList &&
          sensorTypeList.map((eachType) => {
            return (
              <button
                key={eachType.id}
                onClick={() => onSensorTypeSelect({ slug: eachType.slug, id: eachType.id })}
                data-query-paramerter={eachType.query_param}
                className="btn-grad btn w-100 mb-3">
                {eachType.name}
              </button>
            );
          })}
      </div>
    </div>
  );
}

export default SelectSensorClassificationComponent;
