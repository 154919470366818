import equimentReducer from './alerts/alert.reducer';
import companyReducer from './company/company.reducer';
import comparisonFilterReducer from './comparisonfilter/comparisonfilter.reducer';
import excelDownloadReducer from './exceldownload/exceldownload.reducer';
import filterButtonReducer from './filterbutton/filterbutton.reducer';
import filterEquimentsReducer from './filterequipments/filterequipments.reducer';
import sidebarReducer from './sidebar/sidebar.reducer';
import userAccessibilityReducer from './useraccessibility/useraccessibility.reducer';

const rootReducer = {
  equipmentAlert: equimentReducer,
  equipmentFilter: filterEquimentsReducer,
  sidebarClick: sidebarReducer,
  comparisonFilter: comparisonFilterReducer,
  company: companyReducer,
  excelDownload: excelDownloadReducer,
  userAccessibility: userAccessibilityReducer,
  filterButtonClick: filterButtonReducer
};

export default rootReducer;
