import { useEffect, useRef } from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setExcelDownload } from '../../../redux/exceldownload/exceldownload.action';
import useOutsideAlerter from '../../../utilities/close.slider.outside.click';

export default function ExcelDownloadComponent({
  DownloadScreenSelect,
  openDownloadScreen,
  showToast,
  setShowToast
}) {
  const sliderRef = useRef(null);
  var isClickedOutside = useOutsideAlerter(sliderRef);
  const dispatch = useDispatch();
  const set_excel_download = useSelector((state) => state.excelDownload.set_excel_download);

  useEffect(() => {
    if (isClickedOutside) {
      openDownloadScreen('close_slider');
      dispatch(
        setExcelDownload({
          ...set_excel_download,
          download_status: false
        })
      );
    }
  }, [isClickedOutside]);
  return (
    <div>
      <div ref={sliderRef} className="sidebar-slider excel-download">
        <Alert
          className="sidebar-slider_alert"
          show={showToast}
          variant="success"
          onClose={() => setShowToast(false)}>
          <p className="text-center">Download initiated successfully</p>
        </Alert>

        <i
          className="sidebar-slider_close material-icons notranslate graphoptionicons"
          onClick={() => openDownloadScreen('close_slider')}>
          cancel
        </i>

        {DownloadScreenSelect()}
      </div>
    </div>
  );
}
