import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  chartChartConfig,
  chartCommonConfig,
  chartTitleConfig,
  chartYaxisConfig
} from '../../../config/chartCommon';
import { machineKpiTotalGraphFilterButtons } from '../../../config/chartFilterButtons';
import useHttpApiCall from '../../../services/useHttpApiCall';
import eventFire from '../../../utilities/eventFire-inside-highcharts';
import MachineKpiTotalGraphComponent from '../components/machine.kpi.total.graph.component';
import MachineKpiEnterContainer from './machine.kpi.enter.container';
import MachineKpiEquipmentFilterContainer from './machine.kpi.equipment.filter.container';

function MachineKpiTotalGraphContainer(props) {
  const INITIALFILTER = '1month';
  const charRef = useRef(null);
  // const EXTRATIME_TO_TRIGGER = 5 * 60 * 1000; //Refresh the api after 5 min
  const [chartApiData, setChartApiData] = useState([]);
  const [payload, setPayload] = useState(null);
  const [graphFilter, setGraphFilter] = useState({
    filterMain: INITIALFILTER
  });
  const navigate = useNavigate();
  const graphFilterObject = machineKpiTotalGraphFilterButtons;
  const [data] = useHttpApiCall(payload);
  const userAccessibility = useSelector((state) => state.userAccessibility.set_user_accessibility);
  // const [xaxisList, setXaxisList] = useState([]);
  const [openEquipmentFilter, setOpenEquipmentFilter] = useState(false);
  const [filterUpdate, setFilterUpdate] = useState(false);
  const [openEnterKpiSlider, setOpenEnterKpiSlider] = useState(false);
  const location = useLocation();
  const is_operator = localStorage.getItem('is_operator') == 'true' ? true : false;
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')
  const plantCode=localStorage.getItem('plantCode')
  const graphApiCall = () => {
    flexibleEquipmentEnabled==true?setPayload({
      auth: true,
      url: 'v0/layers/equipments/kpis/graphs/2',
      params: {
        ...props.idParams,
        plant_code:plantCode,
        layer_id:'',
        layer_type:'plant',
        kpi_id: props.kpi_id,
        kpi_unit_id: props.kpi_unit_id,
        duration: graphFilter.filterMain
      }
    }):
    //Main view
    setPayload({
      auth: true,
      url: 'v0/equipments/kpis/graphs/2',
      params: {
        ...props.idParams,
        kpi_id: props.kpi_id,
        kpi_unit_id: props.kpi_unit_id,
        duration: graphFilter.filterMain
      }
    });
  };

  useEffect(() => {
    if (props.idParams) {
      graphApiCall();
    }
  }, [props.idParams, graphFilter, filterUpdate, props.enterKpiUpdate]);

  useEffect(() => {
    if (data && data.data) {
      setChartApiData(data.data.data);
    }
  }, [data]);

  useEffect(() => {
    let tempXaxisList = [];
    if (chartApiData && charRef && charRef.current.chart) {
      charRef.current.chart.series.forEach((eachSeries) => {
        eachSeries.data.forEach(function (p) {
          if (p.incomplete_flag || p.y == 0.0) {
            p.update({
              color: 'red',
              className: 'value-present'
            });
            tempXaxisList.push(p.name);
          } else {
            p.update({
              color: '#71B2FF',
              className: 'value-absent'
            });
          }
        });
      });

      //  setXaxisList(tempXaxisList);
    }
  }, [chartApiData, charRef]);

  const config = {
    ...chartTitleConfig({
      graphTitle: `Energy use (kWh) / ${chartApiData && chartApiData.kpi_name||''} (${
        chartApiData && chartApiData.kpi_unit_name||''
      })`,
      userAccessibility: userAccessibility
    }),
    ...chartCommonConfig,
    chart: {
      className: 'process-energy-use',
      ...chartChartConfig({ userAccessibility: userAccessibility })
    },

    exporting: {
      chartOptions: {
        subtitle: {
          text: ''
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.y:,.0f}'
            }
          }
        }
      }
    },
    colors: ['#71B2FF', '#A2C3C5', '#C4C4C4', '#9B9B9B'],

    plotOptions: {
      // series: {
      //     colorByPoint: true
      // },
      column: {
        cursor: 'pointer',
        minPointLength: 5,
        colorByPoint: true,

        point: {
          events: {
            click: function (data) {
              if (!props.idParams.equipment_id) {
                navigate(
                  `${location.pathname}/${encodeURIComponent(data.point.name)}/?&process_id=${
                    data.point.process_id
                  }&sub_process_id=${data.point.sub_process_id}&equipment_id=${
                    data.point.equipment_id
                  }&kpi=true&energy=false`
                );
              }
            }
          }
        }
      }
    },
    xAxis: {
      // type: 'category',
      lineColor: '#cccccc',
      labels: {
        formatter: function () {
          return chartApiData.equipments[this.value].name;
        },

        padding: 10,
        autoRotation: undefined,
        events: {
          click: function () {
            var xaxisLableElement = document.querySelectorAll(
              '.process-energy-use .highcharts-point'
            )[this.pos];
            eventFire(xaxisLableElement, 'click');
          }
        },
        style: {
          cursor: 'pointer'
        }
      },
      tickLength: 0
    },
    ...chartYaxisConfig({ userAccessibility }),
    // tooltip: {
    //   valueDecimals: 2,
    //   valueSuffix: chartApiData && chartApiData.kpi_unit_name,
      tooltip: {
        useHTML:true,
        valueDecimals: 2,
        valueSuffix: chartApiData && chartApiData.kpi_unit_name,
        formatter: function () {
          let name = !props.idParams.equipment_id
            ? `<div class="txt-10">${this.point.name}</div>`
            : '';
          let incomplete = this.point.incomplete_flag
            ? `<br><span style="color:${this.color}">\u25CF</span><span class=" txt-10"> Incomplete period </span>`
            : '';
            let kpiMonths = this.point.kpi_set_months
            ? `<br><div class="txt-10">Months: ${this.point.kpi_set_months}</div>`
            : '';  
            let energyUse = `<br><div class="txt-10">Energy use: ${this.point.energy_usage?.toFixed(2)||0} kWh
              </div>`;
              let productionHours = `<br><div class="txt-10">${chartApiData?.kpi_name}: ${this.point.total?.toFixed(2)||0} ${chartApiData?.kpi_metric_unit||chartApiData?.kpi_unit_name}
              </div>`;
          // let total = `<br><div class="txt-10">Total: ${this.point.total} ${
          //   chartApiData.kpi_metric_unit ? chartApiData.kpi_metric_unit : ''
          // }</div>`;

          return`<div class="customTooltip ">
          ${name}<br>
          <div class=" " >
         <span  class="semi-bold">${this.y.toFixed(
            2
          )} kWh/${
            chartApiData && chartApiData.kpi_unit_name
          }</span>
          </div>
          <div>
          ${kpiMonths}${incomplete} ${energyUse} ${productionHours}
          </div>
          </div>`;
        },
        outside: true,
        positioner: function(labelWidth, labelHeight, point) {
          var x = Math.min(Math.max(point.plotX + this.chart.plotLeft - labelWidth / 2, this.chart.plotLeft), this.chart.plotWidth + this.chart.plotLeft - labelWidth);
        var y = point.plotY + this.chart.plotTop - labelHeight - 5;
        return { x: x, y: y };
        }
      },
    series: [
      {
        data: chartApiData && chartApiData.equipments,
        // name:`Total: ${2010}kWh`,
        type: 'column'
      }
    ]
  };

  return (
    <div>
      {openEnterKpiSlider && (
        <MachineKpiEnterContainer
          viewKpiList={props.viewKpiList}
          idParams={props.idParams}
          openEnterKpiSlider={openEnterKpiSlider}
          setOpenEnterKpiSlider={setOpenEnterKpiSlider}
          setEnterKpiUpdate={props.setEnterKpiUpdate}
        />
      )}
      {openEquipmentFilter && (
        <MachineKpiEquipmentFilterContainer
          idParams={props.idParams}
          graphId={2}
          kpiId={props.kpi_id}
          kpiUnitId={props.kpi_unit_id}
          setOpenEquipmentFilter={setOpenEquipmentFilter}
          setFilterUpdate={setFilterUpdate}
        />
      )}
      <MachineKpiTotalGraphComponent
        chartApiData={chartApiData}
        chartData={config}
        charRef={charRef}
        expandWindow={props.expandWindow}
        minimizeWindow={props.minimizeWindow}
        shouldExpand={props.shouldExpand}
        graphFilter={graphFilter}
        graphFilterObject={graphFilterObject}
        setGraphFilter={setGraphFilter}
        chartId={props.id}
        hasFilter={true}
        hasSettings={!is_operator} //hide settings if its an operator account
        setKpiMachineStatus={props.setKpiMachineStatus}
        setCurrentKpiId={props.setCurrentKpiId}
        kpiId={props.kpi_id}
        kpiUnitId={props.kpi_unit_id}
        isCustomGraph={props.is_custom}
        setCurrentKpiUnitId={props.setCurrentKpiUnitId}
        setOpenEquipmentFilter={setOpenEquipmentFilter}
        setOpenEnterKpiSlider={setOpenEnterKpiSlider}
        idParams={props.idParams}
        setEnterKpiUpdate={props.setEnterKpiUpdate}
        enterKpiUpdate={props.enterKpiUpdate}
        setCustomApiDetails={props.setCustomApiDetails}
        kpiGraphDetails={props.kpiGraphDetails}
      />
    </div>
  );
}

export default MachineKpiTotalGraphContainer;
