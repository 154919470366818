/* eslint-disable no-debugger */
import { useState, useEffect, useContext } from 'react';
import {  useSearchParams } from 'react-router-dom';
import useHttpApiCall from '../../../services/useHttpApiCall';
import { context as ProcessContext } from '../../../context/process.context';
import SidebarList from './sidebarList';
import SidebarLayerList from './sidebarLayerList';

export default function ProcessMenuComponent() {
  const [payload, setPayload] = useState(undefined);

  let [searchParams] = useSearchParams();
  const kpi_view = searchParams.get('kpi');
  const energy_view = searchParams.get('energy');
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')

  useEffect(() => {
    setPayload({
      auth: true,
      url: flexibleEquipmentEnabled=='true'?'v1/layers/':'v0/process/'
    });
    // debugger
  }, []);

  const [data] = useHttpApiCall(payload);
  let fetchSidebarLinks = useContext(ProcessContext).fetchSidebarLinks;
  let processmenu = useContext(ProcessContext).state.sidebarLinks;

  useEffect(() => {
    if (data && data.data) {
      fetchSidebarLinks(data.data.data);
    }
  }, [data]);

  const toggleActiveClass = (e) => {
    if (e.target.classList.contains('active')) {
      e.target.classList.remove('active');
    } else {
      e.target.classList.add('active');
    }
  };

  if (processmenu.length||flexibleEquipmentEnabled=='true'&&(processmenu?.layers?.length  )) {
    // const dataList=flexibleEquipmentEnabled?processmenu?.layers:processmenu
    return (
      <div className="daz-sidebar__hoverinfo shadow daz-sidebar__processListing">
        {
          flexibleEquipmentEnabled=='true'?<SidebarLayerList
          dataList ={processmenu?.layers}
          toggleActiveClass={toggleActiveClass}
          kpi_view={kpi_view}
          energy_view={energy_view}
          />:
          <SidebarList
          dataList ={processmenu}
          toggleActiveClass={toggleActiveClass}
          kpi_view={kpi_view}
          energy_view={energy_view}
          />
        }
      {/* <SidebarList
      dataList ={dataList}
      toggleActiveClass={toggleActiveClass}
      kpi_view={kpi_view}
      energy_view={energy_view}
      />
       */}
      </div>
    );
  } else {
    return null;
  }
}
