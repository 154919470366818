/* eslint-disable prettier/prettier */
import { useState } from 'react';
import MachineStatusDetailComponent from '../components/machine.status.detail.component';
import MachineStatusCustomizeContainer from './machine.status.customize.container';
export default function MachineStatusDetailContainer({ processDetails }) {
  const [openMachineStatusSlider, setOpenMachineStatusSlider] = useState(false);
  const [openEquipmentFilterSlider, setOpenEquipmentFilterSlider] = useState(false);
  const [filterGraphId, setFilterGraphId] = useState(null);
  const [filterUpdate, setFilterUpdate] = useState({ filterGraphId: null, update: false });
  const [customizeUpdate, setCustomizeUpdate] = useState({ filterGraphId: null, update: false });
  
  return (
    <div>
      {openMachineStatusSlider && (
        <MachineStatusCustomizeContainer
          openMachineStatusSlider={openMachineStatusSlider}
          processDetails={processDetails}
          setOpenMachineStatusSlider={setOpenMachineStatusSlider}
          setCustomizeUpdate={setCustomizeUpdate}
          filterGraphId={filterGraphId}
        />
      )}

      <MachineStatusDetailComponent
        processDetails={processDetails}
        setOpenMachineStatusSlider={setOpenMachineStatusSlider}
        setOpenEquipmentFilterSlider={setOpenEquipmentFilterSlider}
        openEquipmentFilterSlider={openEquipmentFilterSlider}
        filterGraphId={filterGraphId}
        setFilterGraphId={setFilterGraphId}
        setFilterUpdate={setFilterUpdate}
        filterUpdate={filterUpdate}
        customizeUpdate={customizeUpdate}
      />
    </div>
  );
}
