import {
  axisLablesFontSizeSet,
  chartChartConfig,
  chartCommonConfig,
  chartTitleConfig,
  chartYaxisConfig
} from '../../../config/chartCommon';
import eventFire from '../../../utilities/eventFire-inside-highcharts';
import getTotalEnergyUseBarTooltop from '../utilities/main.total.energy.use.bar.tooltip';

function totalEnergyUseChartDataConfig(
  chartApiData,
  graphFilter,
  graphName,
  drillDownLevel,
  userAccessibility,
  setDaySummaryDrilldownDate,
  daySummaryDrilldownDate,
  daySummaryDrilldownEnabled,
  graphTooltip,
  chartUnit,
  chartSlugInfo,
  graphDetails
) {
  const config = {
    ...chartTitleConfig({
      graphTitle: graphName,
      userAccessibility: userAccessibility,
      graphTooltip: graphTooltip
    }),
    ...chartCommonConfig,
    chart: {
      ...chartChartConfig({ userAccessibility: userAccessibility }),
      spacingTop: 10,
      spacingBottom: 15,
      className: 'totalenergyuse-main'
    },

    series: [
      {
        data: chartApiData && chartApiData,
        // name:`Total: ${2010}kWh`,
        type: 'column'
      }
    ],
    exporting: {
      chartOptions: {
        chart: {
          spacingBottom: 30
          

        },
        // chartOptions: {
          title: {
          text: `${graphName} - ${graphDetails?.process? graphDetails.process: graphDetails?.drilldownlevel == 'Home' && 'Plant'}`

            // text: `${graphName} - ${graphDetails.process}`
          },
        // }

        subtitle: {
          text: ''
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.y:,.0f}',
              style: {
                fontSize: graphFilter.filterMain != '1week' ? '9px' : '11px'
              }
            }
          }
        }
      }
    },
    legend: {
      enabled: false,
      floating: false,
    },
    plotOptions: {
      column: {
        minPointLength: 3,
        cursor: 'pointer',
        color: '#71B2FF',
        point: {
          events: {
            click: function (data) {
              if (daySummaryDrilldownEnabled && data.point.summary_date ) {
                setDaySummaryDrilldownDate(data.point.summary_date);
              }
            }
          }
        }
      }
    },
    yAxis: [
      {
        opposite: false
      }
    ],
    xAxis: {
      reversedStacks: false,
      lineColor: '#cccccc',
      categories: null,
      labels: {
        autoRotation: undefined,
        formatter: function () {
          if (this.chart.options.chart.className == 'totalenergyuse-main') {
            return chartApiData && chartApiData[this.value]?.name;
          }
        },
        style: {
          fontSize: axisLablesFontSizeSet(userAccessibility),
          cursor:
            (graphFilter.filterMain == '1week' || graphFilter.filterMain == '1month') &&
            daySummaryDrilldownEnabled &&
            !daySummaryDrilldownDate
              ? 'pointer'
              : 'initial'
        },
        padding: 20,
        step: graphFilter.filterMain == '1day' || daySummaryDrilldownDate ? 2 : 0,
        events: {
          click: function () {
            var xaxisLableElement = document.querySelectorAll(
              '.totalenergyuse-main .highcharts-point'
            )[this.pos];
            if (
              (graphFilter.filterMain == '1week' || graphFilter.filterMain == '1month') &&
              daySummaryDrilldownEnabled
            ) {
              eventFire(xaxisLableElement, 'click');
            }
          }
        }
      },
      tickLength: 0,
      tickPixelInterval: 70
    },
    ...chartYaxisConfig({ userAccessibility }),
    tooltip: {
      useHTML: true,
      style: {
        lineHeight: '20px',
        height: "100%",
        whiteSpace: 'nowrap' ,
        width: '250px',
        minWidth:'250px',
        padding:'20px',
      },
      formatter: function () {
        return getTotalEnergyUseBarTooltop({
          drillDownLevel: drillDownLevel,
          graphFilter: graphFilter,
          thisPoint: this.point,
          chartUnit:chartUnit,
          chartSlugInfo:chartSlugInfo,
          yPoint: this.y
        });
      },
      outside: true,
      positioner: function(labelWidth, labelHeight, point) {
        var x = Math.min(Math.max(point.plotX + this.chart.plotLeft - labelWidth / 2, this.chart.plotLeft), this.chart.plotWidth + this.chart.plotLeft - labelWidth);
        var y = point.plotY + this.chart.plotTop - labelHeight - 5;
        return { x: x, y: y };
    }
    }
  };
  return config;
}

export default totalEnergyUseChartDataConfig;
