import axios from 'axios';
import baseUrlConfig from '../config/baseUrl';

function RequestInterceptor(config) {
  return config;
}

function RequestInterceptorError(error) {
  return Promise.reject(error);
}

function ResponseInterceptor(config) {
  return config;
}

function ResponseInterceptorError(error) {
  // console.log("interceptor erro",error.config)
  // const originalRequest = error.config;
  // if (error.response.status === 401) {
  //   // Logic to handle refresh token.
  //   console.log("refresh")
  //   axios({
  //     method: 'POST',
  //     url: 'http://34.194.191.52/reports/v0/api/token/refresh/',
  //     data: {
  //       refresh: localStorage.getItem('refresh_token'),
  //     }
  //   }).then(res => {
  //     localStorage.setItem('token',res.data.access)
  //     originalRequest.headers.Authorization = "Bearer " + localStorage.getItem("token")
  //   axios.request(originalRequest)
  //     // window.location.reload();
  //   }
  // ).catch(
  //   function (error) {
  //     console.log('Show error notification!',)
  //     localStorage.removeItem('token');
  //     window.location.reload()
  //   }
  // )

  // }
  // else
  //console.log('error inside interceptor', error);
  return Promise.reject(error.response);
}

class HttpService {
  constructor() {
    this.axios = axios.create();
  }

  async fetch(payload, cancelToken) {
    try {
      const config = {};
      config.headers = {
        'Content-Type': 'application/json',
        ...payload.headers
      };
      config.headers = payload.auth
        ? { ...config.headers }
        : { ...config.headers };
      // config.headers = payload.hasAuth
      //   ? { ...config.headers, Authorization: "Bearer " + localStorage.getItem("token") }
      //   : config.headers;
      // config.url = payload.url.includes(baseUrlConfig.baseUrl) ? payload.url : baseUrlConfig.baseUrl + payload.url;
      config.url = payload.url.includes('http') ? payload.url : baseUrlConfig.baseUrl + payload.url;

      //config.url = payload.url;

      config.cancelToken = cancelToken;
      config.method = payload.method ? payload.method : 'GET';
      config.data = JSON.stringify(payload.data);
      config.params = payload.params;
      config.credentials='include';

      //  config.auth = payload.withCredentials ? payload.auth : {};
       config.withCredentials = true;
      const data = await this.axios(config);
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request Cancelled!');
      }
      throw error;
    }
  }

  httpInterceptors() {
    this.axios.interceptors.request.use(RequestInterceptor, RequestInterceptorError);
    this.axios.interceptors.response.use(ResponseInterceptor, ResponseInterceptorError);
  }
}

const httpService = new HttpService();

export default httpService;
