import React, { useState } from 'react';
import HeaderComponents from '../components/header.components';

export default function HeaderContainer() {
  const [modalShow, setModalShow] = useState(false);

  // const [formData, setFormData] = useState({
  //   support_text: {
  //     label: 'Support text',
  //     value: ''
  //   }
  // });
  // const onSupportTextSubmit = () => {
  // console.log(formData.support_text.value);
  // setPayload2({
  //     auth:false,
  //     url:'v0/forgot-password/',
  //     method:'POST',
  //     data:{
  //     "email": formData.reset_email.value},
  // })
  // };

  // const inputHandler = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prev) => ({ ...prev, [name]: { ...prev[name], value } }));
  // };
  return (
    <HeaderComponents
      //formData={formData}
      // onSupportTextSubmit={onSupportTextSubmit}
      // inputHandler={inputHandler}
      modalShow={modalShow}
      setModalShow={setModalShow}
    />
  );
}
