import React, { useRef } from 'react';
import MainLayoutComponent from '../../../common/layout/components/main.layout.component';
import { useLocation } from 'react-router-dom';
import MainProcessEnergyUseComponent from '../../../common/layout/components/main.process.energy.use.component';
import MainTotalEnergyUseComponent from '../../../common/layout/components/main.total.energy.use.component';
import MainInstantaneousPowerComponent from '../../../common/layout/components/main.instantaneous.power.component';
import MainProcessInstantaneousPowerComponent from '../../../common/layout/components/main.process.instantaneous.power.component';
import { useSelector } from 'react-redux';

function CompanyLayerComponent() {
  const energyRef = useRef();
  const processRef = useRef();
  const peakRef = useRef();
  const instantRef = useRef();
  const location = useLocation();
  const routeExpand = new URLSearchParams(location.search).get('expand');
  const isSingleEquipment = useSelector((state) => state.company.is_single_equipment_customer);
  let chartWindows;
  if (isSingleEquipment) {
    chartWindows = [
      {
        ref: energyRef,
        component: () => {
          return [
            MainTotalEnergyUseComponent,
            {
              drilldownlevel: 'Company',
              graph_name: 'Total energy use (kWh)',
              graph_tooltip: 'The amount of power consumed by all measured equipment.'
            }
          ];
        },
        id: 'energyHome'
      },
      {
        ref: peakRef,
        component: () => {
          return [
            MainInstantaneousPowerComponent,
            { drilldownlevel: 'Company', graph_name: 'Instantaneous Power (kW)' }
          ];
        },
        id: 'peakHome'
      }
    ];
  } else {
    chartWindows = [
      {
        ref: energyRef,
        component: () => {
          return [
            MainTotalEnergyUseComponent,
            {
              drilldownlevel: 'Company',
              graph_name: 'Total energy use (kWh)',
              graph_tooltip: 'The amount of power consumed by all measured equipment.'
            }
          ];
        },
        id: 'energyHome'
      },
      {
        ref: processRef,
        component: () => {
          return [
            MainProcessEnergyUseComponent,
            { drilldownlevel: 'Company', graph_name: 'Process energy use (kWh)' }
          ];
        },
        id: 'processHome'
      },
      {
        ref: peakRef,
        component: () => {
          return [
            MainInstantaneousPowerComponent,
            { drilldownlevel: 'Company', graph_name: 'Instantaneous Power (kW)' }
          ];
        },
        id: 'peakHome'
      },
      {
        ref: instantRef,
        component: () => {
          return [
            MainProcessInstantaneousPowerComponent,
            { drilldownlevel: 'Company', graph_name: 'Equipment Instantaneous Power (kW)' }
          ];
        },
        id: 'instantaneousHome'
      }
    ];
  }

  return (
    <>
      <h4 className="text-center layout-heading">Company</h4>
      <MainLayoutComponent chartWindows={chartWindows} routeExpand={routeExpand} />
    </>
  );

  // return null
}

export default CompanyLayerComponent;
