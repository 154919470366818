import DatePicker from 'react-datepicker';
import { addDays } from 'date-fns';
import moment from 'moment';
export default function DateRangePicker({ setDateRange, dateRange, calendarRef,graphInfo }) {
  const [startDate, endDate] = dateRange;


  const dateFilter = (date) => {
    if(graphInfo?.isSummaryGraph){
     return moment() > date
    }
    else{
      return ((moment().subtract(30, 'days') < date) &&  moment() > date );
    }
  };


  return (
    <div>
      <DatePicker
        ref={calendarRef}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
        placeholderText="Start date - End date"
        isClearable={true}
        wrapperClassName="daz-daterangepicker"
        maxDate={addDays(startDate, 30)}
        excludeDates={ graphInfo?.isSummaryGraph ? [new Date()] : []}
        dateFormat="dd/MM/yy"
        calendarStartDay={1}
        filterDate = {dateFilter}
      />
    </div>
  );
}
