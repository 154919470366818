const kpiListSlug = {
  production_hours: {
    icon: 'conveyor_belt',
    description: 'Select the time frame for calculating production length '
  },
  produced_units: { icon: 'timer', description: 'Select the type of unit you produce' },
  turn_over: {
    icon: 'currency_exchange',
    description: 'Select preferred amount for turnover entries'
  },
  custom: { icon: 'add', description: 'Enter a graph label and the KPI unit you want to track' }
};

const kpiUnitSlug = {
  hours: 'pace',
  numbers: 'pin',
  square_meter: 'square_foot',
  cubic_meter: 'deployed_code',
  kilogram: 'exercise',
  tonnes: 'weight',
  ksek: 'monetization_on',
  msek: 'payments'
};

var customKpiFormObject = {
  custom_kpi_name: {
    label: 'Graph Label',
    value: '',
    name: 'custom_kpi_name'
  },
  custom_kpi_unit: {
    label: 'Kpi Unit',
    value: '',
    name: 'custom_kpi_unit'
  }
};

export { kpiListSlug, customKpiFormObject, kpiUnitSlug };
