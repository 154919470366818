import HeaderContainer from '../../../common/header/container/header.container';
import SidebarContainer from '../../../common/sidebar/container/sidebar.container';
import { provider as ProcessProvider } from '../../../context/process.context';
import { provider as KpiProvider } from '../../../context/kpi.context';
import { provider as FilterProvider } from '../../../context/filter.context';
import { Provider } from 'react-redux';
import MainWrapperComponent from './mainwrapper.component';
import store from '../../../redux/store';
import ContentWrapperComponent from './contentwrapper.component';
import { Outlet } from 'react-router-dom';

export default function PrivateLayoutComponent() {
  const isOperator = localStorage.getItem('is_operator');
  return (
    <Provider store={store}>
      <ProcessProvider>
        <KpiProvider>
          <FilterProvider>
            <MainWrapperComponent>
              <div className={isOperator == 'true' ? 'daz-user-operator' : null}>
                <HeaderContainer />
                <ContentWrapperComponent>
                  <div className="daz-main-wrp">
                    <SidebarContainer />
                    <div className="daz-main-content">
                      <Outlet />
                    </div>
                  </div>
                </ContentWrapperComponent>
              </div>
            </MainWrapperComponent>
          </FilterProvider>
        </KpiProvider>
      </ProcessProvider>
    </Provider>
  );
}
