/* eslint-disable no-undef */
const { NODE_ENV } = process.env;
console.log('REACT_APP_BASE_URL', process.env.REACT_APP_BASE_URL);
const baseUrlConfig = {
  baseUrl:
    NODE_ENV == 'production' && process.env.REACT_APP_ENV != 'staging'
      ? 'https://api.dazoq.com/reports/'
      : 'https://dev.api.dazoq.com/reports/'
};

export default baseUrlConfig;
