import moment from "moment";

export const TimeConvertion=(time)=>{
    // const originalDate = new Date(time.replace(/\./g, '-')); // Replace dots with dashes for better compatibility

    // // Get the year, month, day, hours, minutes, and seconds
    // const year = originalDate.getUTCFullYear();
    // const month = (originalDate.getUTCMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
    // const day = (originalDate.getUTCDate().toString()).padStart(2, '0');
    // const hours = originalDate.getUTCHours().toString().padStart(2, '0');
    // const minutes = originalDate.getUTCMinutes().toString().padStart(2, '0');
    // const seconds = originalDate.getUTCSeconds().toString().padStart(2, '0');
    // // Construct the formatted timestamp with UTC offset 'Z'
    // const formattedTimestamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
    
   // const originalDate = moment(time.replace(/\./g, '-'), 'YYYY-MM-DDTHH:mm:ss');
    const formattedTimestamp = moment(time).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

   // console.log("Formatted Timestamp:",hours,minutes,seconds);
    return(formattedTimestamp)
}