import { axisLablesFontSizeSet } from '../../config/chartCommon';
import { roundToTwoDecimal } from '../../utilities/common.computations';
import getTotalEnergyUseBarTooltop from './utilities/main.total.energy.use.bar.tooltip';

function energyPriceTotalEnergyUseConfig({
  chartApiData,
  graphFilter,
  userAccessibility,
  drillDownLevel,
  daySummaryDrilldownDate
}) {
  const config = {
    plotOptions: {
      spline: {
        marker: {
          enabled: false
        }
      }
    },
    subtitle: {
      text:
        chartApiData.energy_pricing.length > 0
          ? ''
          : 'Energy price for tomorrow are published at 14:45',
      verticalAlign: 'middle',
      style: {
        fontWeight: 'regular',
        fontSize: '20px'
      }
    },
    legend: {
      enabled: false,
      align: 'center',
      verticalAlign: 'bottom',
      floating: false,
      y: 30,
      itemStyle: {
        fontWeight: 'lighter',
        fontSize: axisLablesFontSizeSet(userAccessibility)
      }
    },
    tooltip: {
      formatter: function () {
        console.log('series', this.series.type, drillDownLevel);
        if (this.series.type == 'spline') {
          return `<div class="txt-10">${this.point.name}</div><span style="color:${this.series.color}">\u25CF</span><span class=" txt-10"> ${this.series.name}: </span><div class="semi-bold">${ roundToTwoDecimal(this.y)} SEK/kWh</div>`;
        } else {
          return getTotalEnergyUseBarTooltop({
            drillDownLevel: drillDownLevel,
            graphFilter: graphFilter,
            thisPoint: this.point,
            yPoint: this.y
          });
        }
      }
    },
    xAxis: {
      categories: chartApiData && chartApiData.combined_xaxis_labels,
      lineColor: '#cccccc',
      labels: {
        autoRotation: undefined,
        formatter: undefined,
        step:
          graphFilter.filterMain == 'today' ||
          graphFilter.filterMain == 'tomorrow' ||
          graphFilter.filterMain == '1month' ||
          (graphFilter.filterMain == '1week' && daySummaryDrilldownDate)
            ? 5
            : 0,
        padding: 15
      }
    },
    yAxis: [
      {
        // Primary yAxis
        // labels: {
        //     format: '{value}°C',
        // },
        // title: {
        //     text: 'Energy price',
        // },
      },
      {
        // Secondary yAxis
        title: {
          text: ''
        },
        opposite: true
      }
    ],
    series: [
      {
        name: 'Total energy',
        type: 'column',
        data: chartApiData.energy_summary,
        color: '#71B2FF'
      },
      {
        name: 'Energy Price',
        type: 'spline',
        data: chartApiData.energy_pricing,
        color: '#F5DD87',

        yAxis: 1,
        marker: {
          symbol: 'circle'
        }
      }
    ]
  };
  return config;
}

export default energyPriceTotalEnergyUseConfig;
