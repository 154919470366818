/* eslint-disable prettier/prettier */
import { Modal, Button } from 'react-bootstrap';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';
import useHideAfterTimeout from '../../../hooks/useHideAfterTimeout';
import { useEffect } from 'react';


export default function GenerateLinkPopupComponent({
  modalShow,
  setModalShow,
  linkGenerated
}) {
    const [copyToClipboard, isCopied] = useCopyToClipboard(5000);
    const { isVisible, show } = useHideAfterTimeout(5000);
  const handleClose = () =>{
    setModalShow(false);
  }  
  useEffect(() => {
    if(isCopied){
        show();
    }
  }, [isCopied])
  

  return (
    <Modal className="support-modal" show={modalShow} onHide={handleClose}>
      <Modal.Header closeButton>
        <h5>External access link</h5>
      </Modal.Header>
      <Modal.Body>
          <a className='text-break'>{linkGenerated}</a>
          {isCopied && isVisible && <div className='text-success mt-2'>Copied to clipboard!</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="btn-grad"
          onClick = {() => copyToClipboard(linkGenerated)}
          >
           Copy Link
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>

    </Modal>
  );
}
