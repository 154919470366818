/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import useHttpApiCall from '../../../services/useHttpApiCall';
import SetRecurringDownloadComponent from '../components/set.recurring.download.component';
import moment from 'moment';
import GenerateLinkPopupComponent from '../components/generate.link.popup.component';
export default function SetRecurringDownloadContainer({
  checkedItems,
  openDownloadScreen,
  apiEquipmentList,
  selectSensorClassification,
  sensorUnit,
  equipmentTypeId
}) {
  const INITIAL_DATE = new Date().setHours(0, 0, 0, 0);
  //const dispatch = useDispatch();
  const [payload, setPayload] = useState(null);
  const [data] = useHttpApiCall(payload); //API for download initation
  const [payload2, setPayload2] = useState(null);
  const [data2] = useHttpApiCall(payload2); //API for getting details
  const [recurringDownloadInfo, setRecurringDownloadInfo] = useState(null);
  const [modalShow, setModalShow] = useState(false);  
  const [linkGenerated, setLinkGenerated] = useState(false);  
  const dateInitialObject = {
    end_date: INITIAL_DATE ,
    start_date: INITIAL_DATE 
  };
  var initialFormObject = {
    period: null,
    span: false,
    start_date: INITIAL_DATE,
    end_date: INITIAL_DATE,
    data_type: null,
    data_resolution: null,
    format: null
  };
  const [formData, setFormData] = useState(initialFormObject);

  useEffect(() => {
    setPayload2({
      auth: true,
      url: `v0/downloads/ui/`,
      params:{
        equipment_type_id: equipmentTypeId
      }
    });
  }, []);

  const getEquipmentList = () => {
    let equipmentArray = [];
    Object.keys(checkedItems).map((item, index) => {
      if (checkedItems[item]) {
        let indexNum = item.split('_')[1];
        console.log('equipmentList', indexNum, index, apiEquipmentList);
        equipmentArray.push({
          source_mapping: apiEquipmentList[index].id
        });
      }
    });
    return equipmentArray;
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    let postEquipmentsSendArray = getEquipmentList();
    let postStartDate = moment(formData.start_date).format('yyyy-MM-DD HH:mm:SS');
    let postEndDate = moment(formData.end_date).format('yyyy-MM-DD HH:mm:SS');
    let postApiData = {
        download_type: 'RECURRING',
        level: 'equipment',
        data: formData.data_type,
        data_resolution: formData.data_resolution,
        start_date_time: postStartDate,
        end_date_time: postEndDate,
        period:  formData.period,
        equipments: postEquipmentsSendArray,
        format: formData.format
      }
    if(formData.span){
        delete postApiData['period'];
    } else{
        delete postApiData['start_date_time'];
        delete postApiData['end_date_time'];
    }

    setPayload({
      auth: true,
      url: 'v0/downloads/',
      method: 'POST',
      data: postApiData
    });
  };

  const onSelectOption = (e) => {
    let catergory = e.target.parentNode.getAttribute('id');
    // let option = e.target.id;
    // let selectedValueCopy = formData[catergory].value;
    // let dependentValueCopy = formData.values.value;
    // let dateInitialObject = {
    //   end_date: { value: INITIAL_DATE },
    //   start_date: { value: INITIAL_DATE }
    // };
  };

  useEffect(() => {
    if (data && data.data) {
      let apiData = data.data.data;
      console.log('apidata', apiData);
      setModalShow(true)
      setLinkGenerated(apiData.recurring_link)
    }
  }, [data]);

  useEffect(() => {
    if (data2 && data2.data) {
      let apiData = data2.data.data;
      console.log('apidata2', apiData);
      setRecurringDownloadInfo(apiData);
      setFormData((prev) => ({...prev, format:apiData.format[0].slug , period : apiData.data_type_values[0].data_resolution[0].period_mappings[0].period_id, data_type: apiData.data_type_values[0].slug, data_resolution:apiData.data_type_values[0].data_resolution[0].slug}))
    }
  }, [data2]);


  useEffect(() => {
   console.log("f",formData)
  }, [formData])
  
  return (
    <>
      <SetRecurringDownloadComponent
        onFormSubmit={onFormSubmit}
        formData={formData}
        onSelectOption={onSelectOption}
        setFormData={setFormData}
        openDownloadScreen={openDownloadScreen}
        selectSensorClassification={selectSensorClassification}
        sensorUnit={sensorUnit}
        recurringDownloadInfo={recurringDownloadInfo}
      />
      <GenerateLinkPopupComponent modalShow={modalShow} setModalShow={setModalShow} linkGenerated={linkGenerated}  />
    </>
  );
}
