import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import NotificationsAlertComponent from './notifications.alert.component';
import sensorClassificationUnit from '../containers/sensor.classification.unit';
export default function SetAlertsComponent({
  onFormSubmit,
  inputHandler,
  alertLimit,
  occur,
  setOccur,
  setOpenEnded,
  openEnded,
  openAlertScreen,
  onDateTimeChange,
  startDate,
  apiEquipmentList,
  checkedItems,
  singleEquimentIndex,
  rangeNotSetError,
  editMode,
  apiEquipmentEdit,
  directEquipmentEdit,
  directEquipmentCreate,
  directEquipmentCreateList,
  apiUserEmailList,
  setApiUserEmailList,
  weekDaysObject,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  setWeekDaysObject,
  // onOverwriteAlarmConfirm,
  modalShow,
  handleClose,
  // overlapEquipements,
  showExistingActiveAlertList,
  existingAlertMode,
  sensorUnit,
  isTempHumidityClassificationEnabled,
  selectedAlertType,
  isSelectAlertType
}) {
  const [onDatepickerOpen, setOnDatepickerOpen] = useState(false);
  useEffect(() => {
    var datepickerPopperElement = document.getElementsByClassName('react-datepicker-popper')[0];
    if (datepickerPopperElement) {
      var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation, index) {
          if (mutation.attributeName === 'style' && index == 0) {
            var transformX = Math.floor(
              datepickerPopperElement.style.transform
                .split('(')[1]
                .split(')')[0]
                .split(',')[0]
                .split('px')[0]
            );
            var transformY = Math.floor(
              datepickerPopperElement.style.transform
                .split('(')[1]
                .split(')')[0]
                .split(',')[1]
                .split('px')[0]
            );
            var transform = `translate(${transformX}px, ${transformY}px)`;
            datepickerPopperElement.style.transform = transform;
            datepickerPopperElement.style.webkitTransform = transform;
            datepickerPopperElement.style.msTransform = transform;
          }
        });
      });

      // Notify me of style changes
      var observerConfig = {
        attributes: true,
        attributeFilter: ['style']
      };

      observer.observe(datepickerPopperElement, observerConfig);

      return () => {
        observer.disconnect();
      };
    }
  }, [onDatepickerOpen]);

  const checkDisableCreate=()=>{
    if(
      (isSelectAlertType && selectedAlertType && selectedAlertType.slug == "kw_span") || (editMode && apiEquipmentEdit.alarm_type_slug == "kw_span" ) 
    ){
      if((alertLimit.if_above.value ===null || alertLimit.if_below.value===null)){
        return true
      }
      else{
        return false

      }
    }
    else{
      if((alertLimit.if_above.value ===null && alertLimit.if_below.value===null ||  parseFloat(alertLimit.if_above.value)<parseFloat(alertLimit.if_below.value))){
        return true
      }
      else{
        return false

      }
    }
  }
  return (
    <div className="set-alert">
      <Modal show={modalShow} onHide={handleClose} centered>
        <div className="modal-wrap">
          <Modal.Header closeButton>
            <h5>Limit Exceeded!</h5>
          </Modal.Header>
          <Modal.Body>
            <p>
            You’ve reached the alert limit of 100. You can remove alerts in 
              {/* There is already an{' '} */}{' '}
              <a
                className=" pointer"
                href="javascript:void(0)"
                onClick={showExistingActiveAlertList}>
                active alerts
              </a>{' '}
              {/* for the selected days for this equipment. */}
              {/* {editMode
                ? 'Updating will overwrite the previous alert.'
                : 'Creating a new one will overwrite the previous alert.'}{' '} */}
            </p>
            {/* <p className="txt-14 light2-grey mt-2">Overlapping equipment: {overlapEquipements}</p> */}
          </Modal.Body>
          <Modal.Footer>
            {/* <Button
              variant="primary"
              className="btn-grad"
              onClick={() => {
                onOverwriteAlarmConfirm();
                handleClose();
              }}>
              Confirm
            </Button> */}
            <Button
              variant="secondary"
              onClick={() => {
                openAlertScreen('select_source');
                handleClose();
              }}>
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Modal>

      <i
        className=" material-icons notranslate graphoptionicons flip-icon top-left-btn"
        onClick={() => {
          !editMode || existingAlertMode
            ? openAlertScreen('select_source')
            : openAlertScreen('active_alerts');
        }}>
        forward
      </i>
      <h3>Set alert</h3>
      <p className="mt-2 mb-2 txt-15">for</p>
      {editMode || directEquipmentEdit || directEquipmentCreate ? (
        <>
          <h3 className="sidebar-slider__sub-heading">
            {directEquipmentCreate
              ? directEquipmentCreateList && directEquipmentCreateList.equipment_name
              : apiEquipmentEdit && apiEquipmentEdit.equipment_name}
          </h3>
          <p className="sidebar-slider__sub-tagline">
            {directEquipmentCreate
              ? directEquipmentCreateList && directEquipmentCreateList.process_display_name
              : apiEquipmentEdit && apiEquipmentEdit.process_display_name}
          </p>
        </>
      ) : singleEquimentIndex != null ? (
        <>
          <h3 className="sidebar-slider__sub-heading">
            {apiEquipmentList[singleEquimentIndex].equipment_name}
          </h3>
          <p className="sidebar-slider__sub-tagline">
            {apiEquipmentList[singleEquimentIndex].process_display_name}
          </p>
        </>
      ) : (
        <h3 className="sidebar-slider__sub-heading">
          {Object.keys(checkedItems).length > 1 ? 'Multiple sources' : ''}
        </h3>
      )}
      <form onSubmit={onFormSubmit}>
      { 
        (isSelectAlertType && selectedAlertType && selectedAlertType.slug == "kw_span") || (editMode && apiEquipmentEdit.alarm_type_slug == "kw_span" ) ? 
        <>
        <div className='flex-btw'>
            <div className="form-group mt-3">
              <label className="mb-1">
                {' '}
                If between{' '}
                <span className="subheading-grey">
                  (
                  {isTempHumidityClassificationEnabled
                    ? editMode
                      ? sensorClassificationUnit[apiEquipmentEdit.equipment_type_slug]
                      : sensorClassificationUnit[sensorUnit]
                    : 'kW'}
                  )
                </span>{' '}
              </label>
              <div className='flex-btw'>
                  <input
                    className="form-control shadow border-0 py-4"
                    style={{width:'60px',padding:'6px'}}
                    name="if_above"
                    value={alertLimit.if_above.value}
                    onChange={inputHandler}
                    type="number"
                    step="any"
                  />
                  <span className='ms-2 me-2'> - </span> 
                  <input
                    className="form-control shadow border-0 py-4"
                    name="if_below"
                    style={{width:'60px',padding:'6px'}}
                    value={alertLimit.if_below.value}
                    onChange={inputHandler}
                    type="number"
                    step="any"
                  />
              </div>
            </div>
            <div className="form-group ms-4 mt-3">
              <label className="mb-1">
                For{' '}
                <span className="subheading-grey">
                  (minutes)
                </span>
              </label>
              <input
                    className="form-control shadow border-0 py-4"
                    name="for_minutes"
                    value={alertLimit.for_minutes.value}
                    onChange={inputHandler}
                    type="number"
                    step="any"
                    style={{width:'150px'}}
                    //placeholder='e.g. 30'
                  />
             
            </div>
        </div>
        <span className="input-error text-start">{rangeNotSetError}</span>
        
        </>
        :
        <>
        <div className="form-group mt-3">
          <label className="mb-1">
            {' '}
           { (selectedAlertType && selectedAlertType.slug  == "kwh_limit") || (editMode && apiEquipmentEdit.alarm_type_slug == "kwh_limit") ? "If hourly value is above" 
           :
            "If above" 
           }
           {' '}
            <span className="subheading-grey">
              (
              {
             (selectedAlertType &&  selectedAlertType.slug  == "kwh_limit") || (editMode && apiEquipmentEdit.alarm_type_slug == "kwh_limit") ? "kWh" :
              isTempHumidityClassificationEnabled
                ? editMode
                  ? sensorClassificationUnit[apiEquipmentEdit.equipment_type_slug]
                  : sensorClassificationUnit[sensorUnit]
                : 'kW'}
              )
            </span>{' '}
          </label>
          <input
            className="form-control shadow border-0 py-4"
            name="if_above"
            value={alertLimit.if_above.value}
            onChange={inputHandler}
            type="number"
            step="any"
          />
        </div>
        <div className="form-group">
          <label className="mb-1">
          {' '}
          { (selectedAlertType && selectedAlertType.slug  == "kwh_limit") || (editMode && apiEquipmentEdit.alarm_type_slug == "kwh_limit") ? "If hourly value is below" 
           :
            "If below" 
           }
           {' '}
            <span className="subheading-grey">
              (
              {
             (selectedAlertType &&  selectedAlertType.slug  == "kwh_limit") || (editMode && apiEquipmentEdit.alarm_type_slug == "kwh_limit") ? "kWh" :
              isTempHumidityClassificationEnabled
                ? editMode
                  ? sensorClassificationUnit[apiEquipmentEdit.equipment_type_slug]
                  : sensorClassificationUnit[sensorUnit]
                : 'kW'}
              )
            </span>
          </label>
          <input
            className="form-control shadow border-0 py-4"
            name="if_below"
            value={alertLimit.if_below.value}
            onChange={inputHandler}
            type="number"
            step="any"
          />
          <div style={{color:'red',fontSize:'10px',marginTop:'6px',minHeight:'15px'}}  >{alertLimit.if_above.value&&alertLimit.if_below.value && parseFloat(alertLimit.if_above.value)<parseFloat(alertLimit.if_below.value)?"'If above' value should be higher than 'If below' value":""}</div>

        </div>

        <span className="input-error text-start">{rangeNotSetError}</span>
        </>
        }
        <div className="form-group mt-4">
          <label className="mb-1">Occur</label>
          <div className="row">
            <div className="col col-6">
              <button
                type="button"
                onClick={() => {
                  setOccur('One time');
                }}
                className={
                  occur == 'One time' ? 'grey-lbtn btn-active-border w-100' : 'grey-lbtn w-100'
                }>
                One time
              </button>
            </div>
            <div className="col col-6">
              <button
                type="button"
                onClick={() => {
                  setOccur('Everytime');
                }}
                className={
                  occur == 'Everytime' ? 'grey-lbtn btn-active-border w-100' : 'grey-lbtn w-100'
                }>
                Everytime
              </button>
            </div>
          </div>
        </div>
        <div className="form-group mt-4">
          <label className="mb-1"> Expire at</label>
          <div className="row">
            <div className="col col-6">
              <DatePicker
                selected={startDate}
                minDate={new Date()}
                calendarStartDay={1}
                onChange={onDateTimeChange}
                disabled={openEnded ? true : false}
              />
            </div>
            <div className="col col-6">
              <DatePicker
                selected={startDate}
                onChange={onDateTimeChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                // timeCaption=""
                dateFormat="HH:mm"
                disabled={openEnded ? true : false}
                minDate={new Date()}
                timeFormat="HH:mm"
                //calendarClassName="timepicker-only"
                onCalendarOpen={() => setOnDatepickerOpen((prev) => !prev)}
                calendarStartDay={1}
              />
            </div>
          </div>
        </div>
        <div className="form-group flex-c">
          <input
            className="daz-checkbox right mr-1"
            type="checkbox"
            checked={openEnded}
            onChange={() => setOpenEnded(!openEnded)}
            id="openended"
          />
          <label htmlFor="openended" className="ml-1">
            Open-ended
          </label>
        </div>
        <NotificationsAlertComponent
          apiUserEmailList={apiUserEmailList}
          setApiUserEmailList={setApiUserEmailList}
          weekDaysObject={weekDaysObject}
          startTime={startTime}
          endTime={endTime}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
          setWeekDaysObject={setWeekDaysObject}
        />
        {/* {
                    apiAlarmDuplicateList.length > 0 && duplicateMode && !editMode ?
                    <div className="daz-note">
                        <h3>You have an active alert</h3>  
                        <p> There is already an <span className="blue pointer" onClick = {onDulplicateActiveAlertClick}>active alert</span> for the selected equipment. Creating a new one will overwrite the previous alert.</p> 
                    </div>
                    :
                    ""
                } */}
        <button className="btn mt-5 btn-grad pr-5 pl-5 mx-auto" disabled={checkDisableCreate()} type="submit">
          {editMode ? 'Update alert' : 'Create alert'}
        </button>
      </form>
    </div>
  );
}
