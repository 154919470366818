import { useEffect, useState } from 'react';
import SelectUnselectComponent from '../components/select.unselect.component';
export default function SelectUnselectContainer({ setCheckedItems, checkedItems }) {
  const [unselectAll, setUnselectAll] = useState(false);
  const onUnselectAll = () => {
    var tempCheckedItems = { ...checkedItems };

    if (!unselectAll) {
      Object.keys(tempCheckedItems).forEach((key) => {
        console.log(key);
        tempCheckedItems[key] = true;
      });
      setCheckedItems(tempCheckedItems);
    } else {
      Object.keys(tempCheckedItems).forEach((key) => {
        tempCheckedItems[key] = false;
      });
      setCheckedItems(tempCheckedItems);
    }
    setUnselectAll(!unselectAll);
  };

  useEffect(() => {
    if (
      Object.values(checkedItems).every((val) => val === true) &&
      Object.keys(checkedItems).length != 0
    ) {
      setUnselectAll(true);
    } else if (
      Object.values(checkedItems).every((val) => val === false) &&
      Object.keys(checkedItems).length != 0
    ) {
      setUnselectAll(false);
    }
  }, [checkedItems]);

  return <SelectUnselectComponent unselectAll={unselectAll} onUnselectAll={onUnselectAll} />;
}
