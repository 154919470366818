import { useEffect, useState } from 'react';
import useHttpApiCall from '../../../services/useHttpApiCall';
import MachineKpiEquipmentFilterComponent from '../components/machine.kpi.equipment.filter.component';

function MachineKpiEquipmentFilterContainer({
  idParams,
  graphId,
  kpiId,
  kpiUnitId,
  setOpenEquipmentFilter,
  setFilterUpdate,
  equipmentsLimit
}) {
  const [apiEquipmentList, setApiEquipmentList] = useState([]);
  const [payload, setPayload] = useState(undefined);
  const [data] = useHttpApiCall(payload);
  // const [payload1, setPayload1] = useState(undefined);
  // const [data1] = useHttpApiCall(payload1);
  const [payload2, setPayload2] = useState(undefined);
  const [data2] = useHttpApiCall(payload2);
  const [checkedItems, setCheckedItems] = useState({});
  // const set_equipment_filter =  useSelector(state => state.equipmentFilter.set_equipment_filter)
  // const dispatch = useDispatch()
  const [showToast, setShowToast] = useState({ show: false, variant: 'success' });
  // const [unselectAll,setUnselectAll] = useState(false)
  const [userEquipmentId, setUserEquipmentId] = useState(null);
  useEffect(() => {
    setPayload({
      auth: true,
      url: 'v2/equipments/',
      params: { ...idParams, kpi_id: kpiId, kpi_unit_id: kpiUnitId, graph_id: graphId }
    });
  }, [data2]);

  useEffect(() => {
    if (data && data.data) {
      let equipment_list = data.data.data.equipments;
      let checked_equipment = {};
      setUserEquipmentId(data.data.data.user_equipment_kpi_id);
      setApiEquipmentList(equipment_list);
      equipment_list.forEach((equipment) => {
        checked_equipment = {
          ...checked_equipment,
          [`equipment_${equipment.id}`]: equipment.is_user_preferred
        };
      });
      setCheckedItems(checked_equipment);
    }
  }, [data]);

  const onCheckEquipment = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
  };

  const onFilterSubmit = () => {
    var sendArray = [];
    if (Object.values(checkedItems).some((val) => val === true)) {
      Object.keys(checkedItems).map((item, index) => {
        if (checkedItems[item]) {
          //let equipmentId = item.split('_')[1];

          if (userEquipmentId) {
            sendArray.push({
              source_mapping: apiEquipmentList[index].id,
              id: apiEquipmentList[index].preference_id
            });
          } else {
            sendArray.push({
              source_mapping: apiEquipmentList[index].id
            });
          }
        }
      });

      if (userEquipmentId) {
        setPayload2({
          auth: true,
          url: `v0/equipments/kpis/preferences/${userEquipmentId}/`,
          method: 'PATCH',
          data: { equipment_preference: [...sendArray] }
        });
      } else {
        setPayload2({
          auth: true,
          url: 'v0/equipments/kpis/preferences/',
          method: 'POST',
          data: {
            ...idParams,
            kpi: kpiId,
            kpi_unit: kpiUnitId,
            graph_id: graphId,
            equipment_preference: [...sendArray]
          }
        });
      }
    } else {
      setShowToast({ show: true, variant: 'danger' });
      hideToast();
    }
  };

  const hideToast = () => {
    setTimeout(() => {
      setShowToast({ ...showToast, show: false });
    }, 3000);
  };

  useEffect(() => {
    if (data2 && data2.data) {
      setShowToast({ show: true, variant: 'success' });
      hideToast();
      setFilterUpdate((prev) => !prev);
    }
  }, [data2]);

  useEffect(() => {
    console.log('checked items', checkedItems);
    // On submit filter_update is changed to true to refresh graph,To inidiate there is a change in the checked item now its set to false
    // dispatch(setEquipmentsFilter({
    //     ...set_equipment_filter,
    //     'filter_update': false
    //   }))
    if (Object.values(checkedItems).filter((item) => item === true).length > equipmentsLimit) {
      setShowToast({ show: true, variant: 'danger' });
    } else {
      setShowToast({ ...showToast, show: false });
    }
  }, [checkedItems]);

  return (
    <MachineKpiEquipmentFilterComponent
      apiEquipmentList={apiEquipmentList}
      setCheckedItems={setCheckedItems}
      checkedItems={checkedItems}
      onCheckEquipment={onCheckEquipment}
      onFilterSubmit={onFilterSubmit}
      setShowToast={setShowToast}
      showToast={showToast}
      setOpenEquipmentFilter={setOpenEquipmentFilter}
      equipmentsLimit={equipmentsLimit}
    />
  );
}

export default MachineKpiEquipmentFilterContainer;
