import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function ChartExpandComponent({ shouldExpand, onWindowChange }) {
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{shouldExpand ? 'Minimize' : 'Expand'}</Tooltip>}>
      <i onClick={onWindowChange} className=" material-icons notranslate graphoptionicons">
        {shouldExpand ? 'minimize' : 'aspect_ratio'}
      </i>
    </OverlayTrigger>
  );
}
