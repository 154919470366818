export default function AlertTypeComponent({onAlertTypeSelect,openAlertScreen,setSelectSensorClassification,alertType,selectSensorClassification,setIsSelectAlertType}) {

    return (
    <div className="select-alert-type">
        {console.log("selectSensorClassification",selectSensorClassification)}
      { selectSensorClassification &&  <i
          className=" material-icons notranslate graphoptionicons flip-icon top-left-btn"
          onClick={() => {
            setSelectSensorClassification(false);
            openAlertScreen('select_source');
            setIsSelectAlertType(false);
          }}>
          forward
        </i>
      }
      <h3 className="mb-3">Select alert type</h3>
      <p className="mt-2 mb-5 txt-15">Select a type of alert.</p>
          
      <div>
        {alertType &&
          alertType.map((eachType) => {
            return (
              <button
                key={eachType.id}
                onClick={() => onAlertTypeSelect({ slug: eachType.slug, id: eachType.id })}
                className="btn-grad d-block btn w-100 mb-3">
                 <div className="txt-18" >{eachType.name}</div>
                 <div className="txt-12">{eachType.description}</div>
                

              </button>
            );
          })}
      </div>
    </div>
  )
}
