import React from 'react';

export default function ComparisonFilterComponent({
  comparisonFilterObject,
  onComparisonSecondaryFilterClick,
  onComparisonMainFilterClick,
  comparisonFilter
}) {
  return (
    <>
      <div className="comparison-fitler-buttons">
        <div className="flex-c">
          {comparisonFilterObject &&
            Object.keys(comparisonFilterObject).map((item) => {
              if (item === comparisonFilter.filterMain) {
                return (
                  <button
                    key={item}
                    onClick={onComparisonMainFilterClick}
                    id={item}
                    className="graph-filter-btn graph-filter-active">
                    {comparisonFilterObject[item].name}{' '}
                  </button>
                );
              } else if (comparisonFilterObject[item].disabled == true) {
                return (
                  <button
                    key={item}
                    id={item}
                    className="graph-filter-btn graph-filter-disabled"
                    disabled>
                    {comparisonFilterObject[item].name}
                  </button>
                );
              }
              return (
                <button
                  key={item}
                  onClick={onComparisonMainFilterClick}
                  id={item}
                  className="graph-filter-btn">
                  {comparisonFilterObject[item].name}
                </button>
              );
            })}
        </div>
        {comparisonFilter.filterSecondaryKey
          ? comparisonFilterObject &&
            comparisonFilterObject[comparisonFilter.filterMain].period.map((item) => {
              if (item.key === comparisonFilter.filterSecondaryKey) {
                return (
                  <button
                    key={item.key}
                    onClick={onComparisonSecondaryFilterClick}
                    id={item.key}
                    className="graph-filter-btn graph-filter-active">
                    {item.value}{' '}
                  </button>
                );
              }
              return (
                <button
                  key={item.key}
                  onClick={onComparisonSecondaryFilterClick}
                  id={item.key}
                  className="graph-filter-btn">
                  {item.value}
                </button>
              );
            })
          : ''}
      </div>
    </>
  );
}
