import SelectEquipmentComponent from '../../../common/equipmentlisting/components/select.equipment.component';
import SelectUnselectContainer from '../../../common/equipmentlisting/containers/select.unselect.container';
export default function SelectSourceDownloadComponent({
  apiEquipmentList,
  setCheckedItems,
  checkedItems,
  onCheckEquipment,
  onSourceNextClick,
  isTempHumidityClassificationEnabled,
  openDownloadScreen,
  setSelectSensorClassification
}) {
  return (
    <div className="select-source">
      {isTempHumidityClassificationEnabled ? (
        <i
          className=" material-icons notranslate graphoptionicons flip-icon top-left-btn"
          onClick={() => {
            setSelectSensorClassification(false);
            openDownloadScreen('select_source');
          }}>
          forward
        </i>
      ) : (
        ''
      )}
      <h3 className="mb-5">Select equipment</h3>
      <SelectUnselectContainer checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
      <SelectEquipmentComponent
        checkedItems={checkedItems}
        apiEquipmentList={apiEquipmentList}
        onCheckEquipment={onCheckEquipment}
        isSelectUnselect={true}
      />
      <button
        className="btn mt-5 btn-grad pr-5 pl-5 mx-auto"
        disabled={!Object.values(checkedItems).some((val) => val === true)}
        type="submit"
        onClick={onSourceNextClick}>
        Next
      </button>
    </div>
  );
}
