import { Modal, Button, Alert } from 'react-bootstrap';

export default function ContactSupportComponent({
  formData,
  inputHandler,
  onSupportTextSubmit,
  modalShow,
  handleClose,
  showToast,
  setShowToast
}) {
  return (
    <Modal className="support-modal" show={modalShow} onHide={handleClose}>
      <Modal.Header closeButton>
        <Alert
          className="sidebar-slider_alert"
          show={showToast.show}
          variant={showToast.variant}
          onClose={() => setShowToast({ ...showToast, show: false })}>
          <p className="text-center">
            {showToast.variant == 'success'
              ? 'Support mail send successfully'
              : 'Something went wrong! Please try again later'}
          </p>
        </Alert>
        <h5>Contact Support</h5>
      </Modal.Header>
      <Modal.Body>
        <form>
          <label className="mb-2">
            If you have feedback or need our support, send us a message and we will get back to you
            shortly.
          </label>
          <textarea
            className="form-control shadow border-0 py-4"
            name="support_text"
            value={formData.support_text.value}
            onChange={inputHandler}></textarea>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={!formData.support_text.value}
          className="btn-grad"
          onClick={onSupportTextSubmit}>
          Submit
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
