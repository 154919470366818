export default function ChartTopFilterComponent({ graphFilterObject, graphFilter, onFilterClick }) {
  return (
    <div className="flex-c">
      {graphFilterObject &&
        Object.keys(graphFilterObject).map((item) => {
          if (item === graphFilter.filterMain) {
            return (
              <button
                key={item}
                onClick={onFilterClick}
                id={item}
                className="graph-filter-btn graph-filter-active">
                <span> {graphFilterObject[item].name}</span>{' '}
              </button>
            );
          } else if (graphFilterObject[item].disabled == true) {
            return (
              <button
                key={item}
                id={item}
                className="graph-filter-btn graph-filter-disabled"
                disabled>
                <span> {graphFilterObject[item].name}</span>
              </button>
            );
          }
          return (
            <button key={item} onClick={onFilterClick} id={item} className="graph-filter-btn">
              <span> {graphFilterObject[item].name}</span>
            </button>
          );
        })}
    </div>
  );
}
