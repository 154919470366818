import React, { useRef, useCallback, useContext } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CustomEvents from 'highcharts-custom-events';
import { useDispatch } from 'react-redux';
import { setEquipmentsFilter } from '../../../redux/filterequipments/filterequipments.action';
import { context as FilterContext } from '../../../context/filter.context';
import { useSearchParams } from 'react-router-dom';
import DateRangePicker from '../../../common/datetimepicker/daterangepicker';
import DownloadChartContainer from '../../../common/downloadchart/containers/downloadchart.container';
import ChartExpandContainer from '../../../common/chartexpand/containers/chartexpand.container';
import ChartSettingsContainer from '../../../common/chartsettings/containers/chartsettings.container';
import ChartTopFilterContainer from '../../../common/charttopfilter/containers/charttopfilter.container';
import ComparisonFilterContainer from '../../../common/comparisonfilter/containers/comparisonfilter.container';
import InstantanousPowerTotalKwhComponent from '../../../common/commonchartcomponents/instantanous.power.total.kwh.component';
import InstantanousPowerPeakKwhComponent from '../../../common/commonchartcomponents/instantanous.power.peak.kwh';
import TotalInstantaniousPowerTotalValue from '../../../common/commonchartcomponents/total.energy.kwh';
CustomEvents(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/offline-exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

export default function PlantLayoutComponent({
  chartData,
  jsChartCallback,
  expandWindow,
  minimizeWindow,
  chartId,
  shouldExpand,
  hasFilter,
  hasAlarm,
  charRef,
  graphFilter,
  setGraphFilter,
  graphFilterObject,
  chartApiData,
  selectEquipments,
  hasSettings,
  filterByDate,
  filterByDateCheck,
  setFilterByDateCheck,
  dateRange,
  setDateRange,
  comparisonView,
  comparisonViewCheck,
  setComparisonViewCheck,
  isZoneLevel,
  resetZone,
  daySummaryDrilldownDate,
  unsetInnerDrilldown,
  togglePriceCheck,
  setTogglePriceCheck,
  graphFilterObjectEnergyPrice,
  totalKwh,
  graphInfo,
  isPeakVisible=false,
  peakvalue,
  totalEnergyUseVisible,
  totalEnergyUse,

  setIncomersValue,
setGeneratorsValue,
incomersValeue,
generatorsValue,
incomersFilter,
generatorFilter,
}) {
  const chartContainerRef = useRef();
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  const process_id = searchParams.get('process_id');
  const sub_process_id = searchParams.get('sub_process_id');
  var fetchFilterChart = useContext(FilterContext).fetchFilterChart;
  const onWindowChange = useCallback(() => {
    !shouldExpand ? expandWindow(chartId) : minimizeWindow();
  }, [shouldExpand, expandWindow, minimizeWindow, chartId]);

  const onSetFilterEquipments = () => {
    dispatch(
      setEquipmentsFilter({
        equipmet_list_api_url: selectEquipments.equipmet_list_api_url,
        equipment_update_params: {
          process_id: process_id,
          sub_process_id: sub_process_id
        },
        params: {
          process_id: process_id,
          sub_process_id: sub_process_id,
          get_equipment_preference: 1,
          get_color_codes:1
        },
        drilldown_level: 1,
        filter_update: false
      })
    );
    fetchFilterChart({
      equipmet_list_api_url: selectEquipments.equipmet_list_api_url,
      params: {
        process_id: process_id,
        sub_process_id: sub_process_id,
        get_equipment_preference: 1
      },
      drilldown_level: 1,
      filter_update: false
    });
  };
  // useEffect(()=>{
  //   isBooster&& HighchartsBoost(Highcharts);
  // },[isBooster])


  return (
    <div className="home-chart-layout process-level-page four-layout">
      <div
        className={
          comparisonViewCheck
            ? 'flex-btw top-functions comparison-view-top'
            : 'flex-btw top-functions'
        }>
        <div className="flex-c">
          {hasFilter && !comparisonViewCheck ? (
            <ChartTopFilterContainer
              graphFilterObject={
                togglePriceCheck ? graphFilterObjectEnergyPrice : graphFilterObject
              }
              graphFilter={graphFilter}
              setGraphFilter={setGraphFilter}
            />
          ) : (
            ''
          )}
          <label className="flex-c">
            {filterByDate && !comparisonViewCheck ? (
              <i
                onClick={() => {
                  setFilterByDateCheck((prev) => !prev);
                }}
                className={
                  filterByDateCheck
                    ? 'calendar-icon material-icons notranslate graphoptionicons material-icon-grey-active'
                    : 'calendar-icon material-icons notranslate graphoptionicons'
                }>
                calendar_month_mutlined_icon
              </i>
            ) : (
              ''
            )}
            {filterByDateCheck && !comparisonViewCheck ? (
              <div className="filter_by_date--inputs">
                <DateRangePicker setDateRange={setDateRange} dateRange={dateRange} graphInfo={graphInfo} />
              </div>
            ) : (
              ''
            )}
          </label>
          {comparisonViewCheck ? <ComparisonFilterContainer /> : ''}
        </div>
        <div className="flex-c top-functions-right">
          {hasAlarm ? (
            <i className=" material-icons notranslate graphoptionicons">notifications</i>
          ) : (
            ''
          )}
          {selectEquipments && selectEquipments.equipment_length > 5 ? (
            <>
              <span
                className={
                  selectEquipments.is_equipment_filter_enabled
                    ? 'equipment-filter active'
                    : 'equipment-filter'
                }
                onClick={onSetFilterEquipments}>
                Filter
              </span>
            </>
          ) : (
            ''
          )}
          <DownloadChartContainer charRef={charRef} />

          <ChartExpandContainer
            shouldExpand={shouldExpand}
            onWindowChange={onWindowChange}
            charRef={charRef}
            chartContainerRef={chartContainerRef}
          />
          {hasSettings ? (
            <ChartSettingsContainer
              chartId={chartId}
              filterByDateCheck={filterByDateCheck}
              setFilterByDateCheck={setFilterByDateCheck}
              filterByDate={filterByDate}
              comparisonView={comparisonView}
              comparisonViewCheck={comparisonViewCheck}
              setComparisonViewCheck={setComparisonViewCheck}
              togglePriceCheck={togglePriceCheck}
              setTogglePriceCheck={setTogglePriceCheck}
              incomersFilter={incomersFilter}
              generatorFilter={generatorFilter}
              setIncomersValue={setIncomersValue}
              setGeneratorsValue={setGeneratorsValue}
              incomersValeue={incomersValeue}
              generatorsValue={generatorsValue}
            />
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="highcharts-wrp" ref={chartContainerRef}>
        {isZoneLevel ? (
          <button
            className="grey-lbtn zone-back"
            onClick={() => {
              resetZone();
            }}>
            Back
          </button>
        ) : (
          ''
        )}

        {daySummaryDrilldownDate && (
          <button
            className="grey-lbtn inner-drilldown-back"
            onClick={() => {
              unsetInnerDrilldown();
            }}>
            Back
          </button>
        )}
        <HighchartsReact
          ref={charRef}
          callback={jsChartCallback}
          highcharts={Highcharts}
          options={chartData}
          containerProps={{ style: { height: '100%' } }}
          //constructorType={'stockChart'}
        />
      </div>
      {
        totalEnergyUseVisible && totalEnergyUse!==0 && <TotalInstantaniousPowerTotalValue total={totalEnergyUse} />
      }
      {
        isPeakVisible && <InstantanousPowerPeakKwhComponent peak={peakvalue?.value} time={peakvalue?.time}/>
      }
      {chartApiData.equipment_energy_usage && (
        <InstantanousPowerTotalKwhComponent totalKwh={totalKwh} emissionFactor ={chartApiData.emission_factor} />
      )}
      {/* {hasPeakLoadLegend ?
          <div className="flex-c custom-legend">Max peak load</div>
          :
          ''
      } */}
      {/* {
              hasIncompletePeriodLegend ?
              <div className="custom-legend incompletePeriod"><span class="bar-grey"></span>Incomplete period</div>
              :
              ''
        } */}
    </div>
  );
}
