/* eslint-disable prettier/prettier */
import { useEffect, useRef } from 'react';
import { Alert } from 'react-bootstrap';
import useOutsideAlerter from '../../../utilities/close.slider.outside.click';
import '../../machinestatus/machinestatus.scss';

function AtOrBelow(){
  return   <label className="mb-1 txt-15">
  &nbsp;At or below <span className="subheading-grey">(kW)</span>
</label>
}


export default function MachineStatusCustomizeComponent({
  inputHandler,
  onFormSubmit,
  formEdited,
  showToast,
  setShowToast,
  setOpenMachineStatusSlider,
  machineStatusData,
  formErrors,
  blurHandler,
  isFormError
}) {
  const sliderRef = useRef(null);
  var isClickedOutside = useOutsideAlerter(sliderRef);
  useEffect(() => {
    if (isClickedOutside) {
      setOpenMachineStatusSlider(false);
    }
  }, [isClickedOutside]);

  const errorObj = (eachEquipment) => {
    return formErrors && formErrors.find((v) => eachEquipment.source_mapping === v.source_mapping);
  };

  return (
    <div
      ref={sliderRef}
      className={
        formEdited
          ? 'sidebar-slider machineStatusSlider formEdited inside-component'
          : 'sidebar-slider machineStatusSlider inside-component'
      }
    >
      <Alert
        className="sidebar-slider_alert"
        show={showToast}
        variant="success"
        onClose={() => setShowToast(false)}
      >
        <p className="text-center">Machine status updated successfully</p>
      </Alert>

      <i
        className="sidebar-slider_close material-icons notranslate graphoptionicons"
        onClick={() => {
          setOpenMachineStatusSlider(false);
        }}
      >
        cancel
      </i>
      <div className="enterKpiSlider_enter ">
        <div className="text-center">
          <h3>Customize levels</h3>
          <p className="mt-2 mb-2 txt-15">
            Set your own kW levels to automatically calculate machine status. If an option does not
            contain a value, it will not be considered in the calculation.
          </p>
        </div>
        <form onSubmit={onFormSubmit} className="machineStatusForm">
          {machineStatusData &&
            machineStatusData.machine_status &&
            machineStatusData.machine_status.map((eachEquipment) => {
              return (
                <div key={eachEquipment.source_mapping}>
                  <div className="mb-2 mt-4 semi-bold">{eachEquipment.equipment_name}</div>
                    <p className='txt-12 mb-2'> &nbsp; &nbsp;Values above <span className='font-bold'>{eachEquipment.production_above } kW</span> are considered production</p>
                    

                    <div className="me-1">
                      <span className="dark3-grey">Standby <span className="subheading-grey">(optional)</span></span>
                      <div className="form-group">
                          <AtOrBelow />
                          <input
                            className="form-control shadow border-0 py-4"
                            type="number"
                            min="0"
                            step="any"
                            value={eachEquipment.standby_at_below ? eachEquipment.standby_at_below : null}
                            id="standby_at_below"
                            data-source-mapping={eachEquipment.source_mapping}
                            data-update-id={eachEquipment.id}
                            onBlur={(e) => blurHandler(e)}
                            onChange={(e) => inputHandler(e)}
                          ></input>
                      </div>
                    </div>
                    {errorObj(eachEquipment) &&
                      errorObj(eachEquipment)?.errorMsg.standby_at_below && (
                      <span className="input-error text-start">
                        {errorObj(eachEquipment).errorMsg.standby_at_below} <br />{' '}
                      </span>
                    )}
                    <div className="ms-1">
                      <span className="dark3-grey">Sleep-mode <span className="subheading-grey">(optional)</span></span>
                      <div className="form-group">
                          <AtOrBelow />
                          <input
                            className="form-control shadow border-0 py-4"
                            type="number"
                            min="0"
                            step="any"
                            id="sleep_at_below"
                            value={eachEquipment.sleep_at_below ? eachEquipment.sleep_at_below : null}
                            data-source-mapping={eachEquipment.source_mapping}
                            onBlur={(e) => blurHandler(e)}
                            onChange={(e) => inputHandler(e)}
                          ></input>
                      </div>
                    </div>
               
                  {errorObj(eachEquipment) &&
                    errorObj(eachEquipment)?.errorMsg.sleep_at_below 
                      && (
                      <span className="input-error text-start">
                        {errorObj(eachEquipment).errorMsg.sleep_at_below} 
                      </span>
                    )}

<div className="ms-1">
                      <span className="dark3-grey">Off</span>
                      <div className="form-group">
                       <AtOrBelow />
                        <input
                          className="form-control shadow border-0 py-4"
                          type="number"
                          min="0"
                          step="any"
                          id="off_at_below"
                          value={eachEquipment.off_at_below ? eachEquipment.off_at_below : null}
                          data-source-mapping={eachEquipment.source_mapping}
                          data-update-id={eachEquipment.id}
                          onBlur={(e) => blurHandler(e)}
                          onChange={(e) => inputHandler(e)}
                        ></input>
                      </div>
                    </div>
                  {errorObj(eachEquipment) &&
                      errorObj(eachEquipment)?.errorMsg.off_at_below && (
                      <span className="input-error text-start mb-2">
                        {errorObj(eachEquipment).errorMsg.off_at_below}
                      </span>
                    )}
                </div>
              );
            })}

          <button
            className="btn mt-4 btn-grad pr-5 pl-5 mx-auto"
            type="submit"
            disabled={!formEdited || isFormError}
          >
            Update
          </button>
        </form>
      </div>
    </div>
  );
}
