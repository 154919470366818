import ComparisonFilterComponent from '../components/comparisonfilter.component';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setComparisonfilter } from '../../../redux/comparisonfilter/comparisonfilter.action';

export default function ComparisonFilterContainer() {
  const dispatch = useDispatch();
  const INITIALFILTER = 'date';
  const comparisonFilterObject = {
    date: {
      name: 'D',
      period: [
        { key: '2day', value: '2' },
        { key: '3day', value: '3' },
        { key: '4day', value: '4' }
      ]
    },
    month: {
      name: 'M',
      period: [
        { key: '2month', value: '2' },
        { key: '3month', value: '3' },
        { key: '4month', value: '4' }
      ]
    }
  };
  const [comparisonFilter, setComparisonFilter] = useState({
    filterMain: INITIALFILTER,
    filterSecondaryKey: comparisonFilterObject[INITIALFILTER].period[0].key,
    filterSecondaryValue: comparisonFilterObject[INITIALFILTER].period[0].value
  });
  // console.log("comparisonFilter",comparisonFilter);

  const onComparisonSecondaryFilterClick = (e) => {
    const key = e.target.id;
    const value = comparisonFilterObject[comparisonFilter.filterMain].period.find(
      (item) => item.key == key
    ).value;
    setComparisonFilter((prev) => ({
      ...prev,
      filterSecondaryKey: key,
      filterSecondaryValue: value
    }));
  };
  const onComparisonMainFilterClick = (e) => {
    const key = e.target.id;
    setComparisonFilter((prev) => ({
      ...prev,
      filterMain: key,
      filterSecondaryKey: comparisonFilterObject[key].period[0].key,
      filterSecondaryValue: comparisonFilterObject[key].period[0].value
    }));
  };

  useEffect(() => {
    //  console.log("set",comparisonFilter);
    // dispatch(setComparisonfilter(prev => ({...prev, "totalEnergyUse" : comparisonFilter })));
    dispatch(setComparisonfilter(comparisonFilter));
  }, [comparisonFilter]);

  return (
    <ComparisonFilterComponent
      onComparisonMainFilterClick={onComparisonMainFilterClick}
      onComparisonSecondaryFilterClick={onComparisonSecondaryFilterClick}
      comparisonFilterObject={comparisonFilterObject}
      comparisonFilter={comparisonFilter}
    />
  );
}
