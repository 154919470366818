/* eslint-disable prettier/prettier */
import { useState, useEffect } from 'react';
import useHttpApiCall from '../../../services/useHttpApiCall';
import MachineStatusFilterEquipmentsComponent from '../components/machine.status.filter.equipments.component';
import machineStatusApiParams from '../../../utilities/machine.status.api.params';

export default function MachineStatusFilterEquipmentsContainer({
  processDetails,
  setOpenEquipmentFilterSlider,
  filterGraphId,
  setFilterUpdate
}) {
  const [apiEquipmentList, setApiEquipmentList] = useState([]);
  const [payload, setPayload] = useState(undefined);
  const [data] = useHttpApiCall(payload);
  const [payload1, setPayload1] = useState(undefined);
  const [data1] = useHttpApiCall(payload1);
  const [checkedItems, setCheckedItems] = useState({});
  const [showToast, setShowToast] = useState({ show: false, variant: 'success' });
  useEffect(() => {
    setPayload({
      auth: true,
      url: 'v0/machine-status/equipment/preference/',
      params: {
        ...machineStatusApiParams({ apiType: 'machineStatusGet', props: processDetails }),
        graph: filterGraphId
      }
    });
  }, [data1]);

  useEffect(() => {
    if (data && data.data) {
      let equipment_list = data.data.data;
      let checked_equipment = {};
      setApiEquipmentList(equipment_list);
      equipment_list.forEach((equipment) => {
        checked_equipment = {
          ...checked_equipment,
          [`equipment_${equipment.id}`]: equipment.is_user_preferred
        };
      });
      setCheckedItems(checked_equipment);
    }
  }, [data]);

  const onCheckEquipment = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
  };

  const onFilterSubmit = () => {
    var putSendArray = [];
    if (Object.values(checkedItems).some((val) => val === true)) {
      Object.keys(checkedItems).map((item, index) => {
        if (checkedItems[item]) {
          putSendArray.push({
            source_mapping: apiEquipmentList[index].id,
            id: apiEquipmentList[index].preference_id,
            process_id: apiEquipmentList[index].process,
            sub_process_id: apiEquipmentList[index].sub_process_id,
            graph: filterGraphId
          });
        }
      });
      setPayload1({
        auth: true,
        url: 'v0/machine-status/equipment/preference/',
        method: 'PUT',
        params: {...machineStatusApiParams({ apiType: 'machineStatusGet', props: processDetails }),  graph: filterGraphId},
        data: putSendArray
      });
    } else {
      setShowToast({ show: true, variant: 'danger' });
      hideToast();
    }
  };

  const hideToast = () => {
    setTimeout(() => {
      setShowToast({ ...showToast, show: false });
    }, 3000);
  };

  useEffect(() => {
    if (data1 && data1.data) {
      setShowToast({ show: true, variant: 'success' });
      hideToast();
      setFilterUpdate((prev) => ({ filterGraphId: filterGraphId, update: !prev.update }));
    }
  }, [data1]);

  return (
    <div>
      <MachineStatusFilterEquipmentsComponent
        apiEquipmentList={apiEquipmentList}
        setCheckedItems={setCheckedItems}
        checkedItems={checkedItems}
        onCheckEquipment={onCheckEquipment}
        onFilterSubmit={onFilterSubmit}
        setShowToast={setShowToast}
        showToast={showToast}
        setOpenEquipmentFilterSlider={setOpenEquipmentFilterSlider}
      />
    </div>
  );
}
