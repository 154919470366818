import ChartTopFilterComponent from '../components/charttopfilter.component';

export default function ChartTopFilterContainer({
  graphFilterObject,
  graphFilter,
  setGraphFilter
}) {
  // const handleSelect = (evtKey, evt) => {
  //   setGraphFilter((prev) => ({
  //     ...prev,
  //     filterSecondary: { key: evtKey, value: evt.target.outerText }
  //   }));
  // };
  const onFilterClick = (e) => {
    const value = e.currentTarget.id;
    setGraphFilter((prev) => ({
      ...prev,
      filterMain: value,
      filterSecondary: graphFilterObject[value].dropdown[0]
    }));
  };

  return (
    <ChartTopFilterComponent
      graphFilterObject={graphFilterObject}
      graphFilter={graphFilter}
      onFilterClick={onFilterClick}
    />
  );
}
