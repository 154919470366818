import { useDispatch } from 'react-redux';
import { recurringDownload } from '../../../redux/exceldownload/exceldownload.action';

export default function DownloadMenuComponent({ openDownloadScreen }) {
  const dispatch = useDispatch();
  return (
    <div>
      <div className="daz-sidebar__hoverinfo shadow">
        <div className="daz-sidebar__dropdown--item">
          <div
            className="daz-sidebar__hoverinfo--click"
            onClick={() => {
              openDownloadScreen('select_source');
              dispatch(recurringDownload(false));
            }}>
            One-time download
          </div>
        </div>
        <div className="daz-sidebar__dropdown--item">
          <div
            className="daz-sidebar__hoverinfo--click"
            onClick={() => {
              openDownloadScreen('select_source');
              dispatch(recurringDownload(true));
            }}>
            Recurring download
          </div>
        </div>
      </div>
    </div>
  );
}
