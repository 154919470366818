import { createRef, useEffect, useState } from 'react';
import useHttpApiCall from '../../../services/useHttpApiCall';
import ViewKpiMachineComponent from '../components/view.kpi.machine.component';
import MachineKpiComparisonGraphContainer from './machine.kpi.comparison.graph.container';
import MachineKpiTotalGraphContainer from './machine.kpi.total.graph.container';
import { customKpiFormObject } from './machine.kpi.utilities';

export default function ViewKpiMachineContainer({
  idParams,
  setKpiMachineStatus,
  currentKpiId,
  setCurrentKpiId,
  kpiMachineStatus,
  kpiList,
  isAllKpiSet,
  updateState,
  setUpdateState,
  currentKpiStatus,
  setCurrentKpiStatus,
  setShowKpiBox,
  showKpiBox
}) {
  const [payload, setPayload] = useState(null);
  const [data] = useHttpApiCall(payload);
  // const [payload2, setPayload2] = useState(null);
  // const [data2] = useHttpApiCall(payload2);
  const [payload3, setPayload3] = useState(null);
  const [data3] = useHttpApiCall(payload3);
  const [payload4, setPayload4] = useState(null);
  const [data4] = useHttpApiCall(payload4);
  const [chartWindows, setChartWindows] = useState([]);
  const [elRefs, setElRefs] = useState([]);
  const [setKpiIds, setSetKPIids] = useState(null);
  const [currentKpiUnitId, setCurrentKpiUnitId] = useState(null);
  const [viewKpiList, setViewKpiList] = useState([]);
  const [customApiDetails, setCustomApiDetails] = useState(customKpiFormObject);
  const [enterKpiUpdate, setEnterKpiUpdate] = useState(false);
  const is_operator = localStorage.getItem('is_operator') == 'true' ? true : false;
  const is_flexible_equipment_categorization_enabled = localStorage.getItem('flexible_equipment') == 'true' ? true : false;

  useEffect(() => {
    if (idParams) {
      setPayload({
        auth: true,
        url:is_flexible_equipment_categorization_enabled?'v0/layers/equipments/kpis/': 'v0/equipments/kpis/',
        params: { ...idParams }
      });
    }
  }, [idParams, enterKpiUpdate, updateState]);

  useEffect(() => {
    if (data && data.data) {
      let list = data.data.data;
      let tempArr = [];
      setViewKpiList(data.data.data);
      list.forEach((k) => tempArr.push(k.kpi.id));
      setSetKPIids(tempArr);
    }
  }, [data]);

  useEffect(() => {
    if (viewKpiList.length > 0) {
      let numberOfCharts;
      numberOfCharts = viewKpiList.length * 2;

      // To remove the Custom KPI box from view KPI
      let kpiUnitObject = viewKpiList.find((eachKpi) => eachKpi.kpi.is_custom == true);
      if (kpiUnitObject) {
        const kpi = kpiUnitObject.kpi;
        const unit = kpiUnitObject.kpi_unit;
        setCustomApiDetails((prev) => ({
          ...prev,
          custom_kpi_name: { ...prev.custom_kpi_name, value: kpi.name, id: kpi.id },
          custom_kpi_unit: { ...prev.custom_kpi_unit, value: unit.name, id: unit.id }
        }));
      }

      setElRefs((elRefs) =>
        Array(numberOfCharts)
          .fill()
          .map((_, i) => elRefs[i] || createRef())
      );
      setKpiMachineStatus(true);
    } else {
      setKpiMachineStatus(false);
    }
  }, [viewKpiList]);

  useEffect(() => {
    if (!kpiMachineStatus) {
      setChartWindows([]);
    }
  }, [kpiMachineStatus]);

  useEffect(() => {
    // add or remove refs
    if (elRefs.length > 0 && kpiMachineStatus) {
      console.log('viewKpi', viewKpiList);
      let graphList = [];
      let refIndex = 0;
      viewKpiList.forEach((graph) => {
        graphList.push({
          ref: elRefs[refIndex++],
          component: () => {
            return [
              MachineKpiComparisonGraphContainer,
              {
                drilldownlevel: 'Process',
                kpiGraphDetails: graph,
                kpi_id: graph.kpi.id,
                kpi_unit_id: graph.kpi_unit.id,
                is_custom: graph.kpi.is_custom,
                idParams: idParams,
                setKpiMachineStatus: setKpiMachineStatus,
                setCurrentKpiId: setCurrentKpiId,
                setCurrentKpiUnitId: setCurrentKpiUnitId,
                viewKpiList: viewKpiList,
                enterKpiUpdate: enterKpiUpdate,
                setEnterKpiUpdate: setEnterKpiUpdate,
                updateState: updateState,
                setCustomApiDetails: setCustomApiDetails
              }
            ];
          },
          id: `${graph.kpi.id}_${refIndex}`
        });
        graphList.push({
          ref: elRefs[refIndex++],
          component: () => {
            return [
              MachineKpiTotalGraphContainer,
              {
                drilldownlevel: 'Process',
                kpiGraphDetails: graph,
                kpi_id: graph.kpi.id,
                kpi_unit_id: graph.kpi_unit.id,
                is_custom: graph.kpi.is_custom,
                idParams: idParams,
                setKpiMachineStatus: setKpiMachineStatus,
                setCurrentKpiId: setCurrentKpiId,
                setCurrentKpiUnitId: setCurrentKpiUnitId,
                viewKpiList: viewKpiList,
                enterKpiUpdate: enterKpiUpdate,
                setEnterKpiUpdate: setEnterKpiUpdate,
                updateState: updateState,
                setCustomApiDetails: setCustomApiDetails
              }
            ];
          },
          id: `${graph.kpi.id}_${refIndex}`
        });
      });
      setChartWindows(graphList);
    }
  }, [elRefs]);

  useEffect(() => {
    if (data3 && data3.data.data && data3.data.status == 1) {
      setShowKpiBox(false);
      setCurrentKpiStatus({ ...currentKpiStatus, preference_id: data3.data.data.preference_id });
    }
  }, [data3]);

  useEffect(() => {
    if (data4 && data4.data) {
      setShowKpiBox(true);
    }
  }, [data4]);

  const toggleShowKpiBox = () => {
    if (showKpiBox) {
      console.log('POST');

      setPayload3({
        auth: true,
        url: 'v0/equipments/kpis/eye-icon/preferences/',
        method: 'POST',
        data: { ...idParams }
      });
    } else {
      console.log('DELETE');
      setPayload4({
        auth: true,
        url: `v0/equipments/kpis/eye-icon/preferences/${currentKpiStatus.preference_id}`,
        method: 'DELETE'
      });
    }
  };

  return (
    <ViewKpiMachineComponent
      isAllKpiSet={isAllKpiSet}
      chartWindows={chartWindows}
      setKpiMachineStatus={setKpiMachineStatus}
      idParams={idParams}
      setCurrentKpiId={setCurrentKpiId}
      currentKpiId={currentKpiId}
      kpiMachineStatus={kpiMachineStatus}
      setKpiIds={setKpiIds}
      setCurrentKpiUnitId={setCurrentKpiUnitId}
      currentKpiUnitId={currentKpiUnitId}
      viewKpiList={viewKpiList}
      setUpdateState={setUpdateState}
      setCustomApiDetails={setCustomApiDetails}
      customApiDetails={customApiDetails}
      kpiList={kpiList}
      currentKpiStatus={currentKpiStatus}
      toggleShowKpiBox={toggleShowKpiBox}
      showKpiBox={showKpiBox}
      setShowKpiBox={setShowKpiBox}
      is_operator={is_operator}
    />
  );
}
