import { useRef, useState, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HomeLayoutComponent from '../../../modules/home/components/homelayout.component';
import DateRangeSet from '../../datetimepicker/daterangeset';
import useHttpApiCall from '../../../services/useHttpApiCall';
import ProcessLayoutComponent from '../../../modules/processdetail/components/processlayout.component';
import MachineLayoutComponent from '../../../modules/machinedetail/components/machinelayout.component';
import {
  totalEnergyUseChartFilterButtons,
  energyPriceTotalEnergyUseChartFilterButtons,
  incomersAdnGeneratorsFilterButtons
} from '../../../config/chartFilterButtons';
import { widgetEndpoints, widgetEndpointsLayers } from '../../../config/widgetEndpoints';
import ComparisionApiParams from '../../../utilities/comparison.api.params';
import RefreshOnceTotalEnergyUse from '../../chartautorefresh/refresh.once.total.enery.use';
import MainTotalEnergyUseContainer from '../containers/main.total.energy.use.container';
// import PlantLayerComponent from '../../../modules/plantLayer/components/plantlayer.component';
import CompanyLayerLayoutComponent from '../../../modules/companyLayer/components/companyLayerlayout.component';
import PlantLayoutComponent from '../../../modules/plantdetail/components/plantlayout.component';
import EquipmentLayoutComponent from '../../../modules/componentDeail/components/componentlayout.component';
import { variablePricingEnabled } from '../../../redux/company/company.action';


export default function MainTotalEnergyUseComponent(props) {
  const INITIALFILTER = '1day';
  const graphInfo = {
    isSummaryGraph: true
  }
  const [initialFilter, setInitialFilter] = useState('1day');
  // const EXTRATIME_TO_TRIGGER = 5 * 60 * 1000; //Refresh the api after 5 min
  // const [chartData, setChartData] = useState({});
  //const [comparisonChartData, setComparisonChartData] = useState({});
  //const [toggleChartData, setToggleChartData] = useState({});
  // const [switchGraph, setSwitchGraph] = useState({
  //   main: true,
  //   comparison: false,
  //   energy_price: false
  // });
  const charRef = useRef(undefined);
  const [chartApiData, setChartApiData] = useState([]);
  const [chartApiData2, setChartApiData2] = useState([]);
  const [chartApiData3, setChartApiData3] = useState([]);
  const [chartApiDataIncomersAndGenerator, setChartApiDataIncomersAndGenerator] = useState([]);
  // const [loadingChart, setLoadingChart] = useState(false);
  const [payload, setPayload] = useState(undefined);
  // const [payload2, setPayload2] = useState(undefined);
  const [graphFilter, setGraphFilter] = useState({
    filterMain: initialFilter
  });
  const [filterByDateCheck, setFilterByDateCheck] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [apiDateRange, setApiDateRange] = useState([]);
  const comparisonFilter = useSelector((state) => state.comparisonFilter.set_comparison_filter);
  const incomersFilter = useSelector((state) => state.company.is_incomers_classification_enabled);
  const generatorFilter = useSelector((state) => state.company.is_generators_classification_enabled);
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')

  const [apiParams, setApiParams] = useState(null);
  const [comparisonViewCheck, setComparisonViewCheck] = useState(false);
  const [togglePriceCheck, setTogglePriceCheck] = useState(false);
  const [config, setConfig] = useState({});
  const graphFilterObject = totalEnergyUseChartFilterButtons;
  const graphFilterObjectEnergyPrice = energyPriceTotalEnergyUseChartFilterButtons;
  const graphFilterObjectIncomersAndGenerators = incomersAdnGeneratorsFilterButtons;

  const [data, loading, error] = useHttpApiCall(payload);
  const [daySummaryDrilldownDate, setDaySummaryDrilldownDate] = useState(null);
  const [incomersTogle, setIncmersToggle] = useState(false);
  const [incomersValeue, setIncomersValue] = useState(false);
  const [generatorsValue, setGeneratorsValue] = useState(false);
  const [totalEnergyUseVisible, setTotalEnergyUseVisible] = useState(false);
  const [totalEnergyUse, setTotalEnergyUse] = useState(0);
  const [drilldown,setDrilldown]=useState(false)
  const dispatch = useDispatch();

  useEffect(()=>{
    if(flexibleEquipmentEnabled=='true'){
      dispatch(variablePricingEnabled(data?.data?.is_variable_pricing_enabled));

    }


  },[data])

  const graphApiCall = () => {
    if (comparisonViewCheck) {
      setDrilldown(false)
      setPayload({
        auth: true,
        url: `${flexibleEquipmentEnabled=='true'?widgetEndpointsLayers?.totalEnergyUse: widgetEndpoints.totalEnergyUse}comparison/`,
        params: ComparisionApiParams('TotalEnergyUse', props, comparisonFilter)
      });
    } 
    
    else if (togglePriceCheck) {
      setDrilldown(false)

      if (
        (graphFilter.filterMain == '1week' || graphFilter.filterMain == '1month') &&
        daySummaryDrilldownDate
      ) {
        setPayload({
          auth: true,
          url: `${flexibleEquipmentEnabled=='true'?widgetEndpointsLayers?.totalEnergyUse: widgetEndpoints.totalEnergyUse}energy-price/`,
          params: {
            ...apiParams,
            duration: graphFilterObject[graphFilter.filterMain].drilldown,
            summary_date: daySummaryDrilldownDate
          }
        });
        charRef.current.chart.showLoading();
      }
      else if(apiParams?.end_date_time && apiParams?.start_date_time && daySummaryDrilldownDate){
        setPayload({
          auth: true,
          url: `${flexibleEquipmentEnabled=='true'?widgetEndpointsLayers?.totalEnergyUse: widgetEndpoints.totalEnergyUse}energy-price/`,
          params: flexibleEquipmentEnabled=='true'? {
            layer_type:apiParams.layer_type,
            duration: '1day',
            summary_date: daySummaryDrilldownDate
          }:
          {
            // ...apiParams,
            duration: '1day',
            summary_date: daySummaryDrilldownDate
          }
        });
   
      }
      
      
      else {
        setPayload({
          auth: true,
          url: `${flexibleEquipmentEnabled=='true'?widgetEndpointsLayers?.totalEnergyUse: widgetEndpoints.totalEnergyUse}energy-price/`,
          params: apiParams
        });
      }
    }
    else if (incomersValeue || generatorsValue) {
      setDrilldown(false)

      if (
        (graphFilter.filterMain == '1week' || graphFilter.filterMain == '1month') &&
        daySummaryDrilldownDate
      ) {
        setPayload({
          auth: true,
          url: `${flexibleEquipmentEnabled=='true'?widgetEndpoints.totalEnergyUseV1:widgetEndpoints.totalEnergyUseV1}`,
          params: {
            // ...apiParams,
            duration: graphFilterObject[graphFilter.filterMain].drilldown||'',
          energy_sources:`${generatorsValue ?'Produced':''}${(incomersValeue&& generatorsValue)?',':''}${incomersValeue ? 'Purchased':'' }`,
          summary_date: daySummaryDrilldownDate
          }
        });
        charRef.current.chart.showLoading();
        // setGraphFilter({filterMain:graphFilterObject[graphFilter.filterMain].drilldown})
      }
      else if(apiParams?.end_date_time && apiParams?.start_date_time && daySummaryDrilldownDate){
        setPayload({
          auth: true,
          url: `${flexibleEquipmentEnabled=='true'?widgetEndpoints.totalEnergyUseV1:widgetEndpoints.totalEnergyUseV1}`,
          params: flexibleEquipmentEnabled=='true'?{
            // ...apiParams,
            layer_type:apiParams.layer_type,
            duration: '1day',
            energy_sources:`${generatorsValue ?'Produced':''}${(incomersValeue&& generatorsValue)?',':''}${incomersValeue ? 'Purchased':'' }`,
            summary_date: daySummaryDrilldownDate
          }: {
            duration: '1day',
            energy_sources:`${generatorsValue ?'Produced':''}${(incomersValeue&& generatorsValue)?',':''}${incomersValeue ? 'Purchased':'' }`,
            summary_date: daySummaryDrilldownDate
          }
        });
      }
      else {
          setPayload({
          auth: true,
          url: `${flexibleEquipmentEnabled=='true'?widgetEndpoints.totalEnergyUseV1:widgetEndpoints.totalEnergyUseV1}`,
          params:{ ...apiParams, energy_sources: `${incomersValeue && 'Purchased'},${generatorsValue &&'Produced' }`  }
        });
      }
    }

    else if (
      (graphFilter.filterMain == '1week' || graphFilter.filterMain == '1month') &&
      daySummaryDrilldownDate
    ) {
      setTotalEnergyUseVisible(false)
      setDrilldown(true)
      setPayload({
        auth: true,
        url: flexibleEquipmentEnabled=='true'?widgetEndpointsLayers?.totalEnergyUse: widgetEndpoints.totalEnergyUse,
        params: {
          ...apiParams,
          duration: graphFilterObject[graphFilter.filterMain]?.drilldown||'',
          summary_date: daySummaryDrilldownDate
        }
      });
      charRef.current.chart.showLoading();
    } 
    else if(apiParams?.end_date_time && apiParams?.start_date_time && daySummaryDrilldownDate){
      setDrilldown(true)
      setPayload({
        auth: true,
        url: flexibleEquipmentEnabled=='true'?widgetEndpointsLayers?.totalEnergyUse: widgetEndpoints.totalEnergyUse,
        params: flexibleEquipmentEnabled=='true'? {
          process_id: apiParams?.process_id,
          sub_process_id: apiParams?.sub_process_id,
          equipment_id: apiParams?.equipment_id,
          duration: '1day',
          summary_date: daySummaryDrilldownDate,
          layer_type:apiParams.layer_type,
          plant_code:props?.plant_code,
          layer_id:props?.layer_id
        }:{
          process_id: apiParams?.process_id,
          sub_process_id: apiParams?.sub_process_id,
          equipment_id: apiParams?.equipment_id,
          duration: '1day',
          summary_date: daySummaryDrilldownDate
        }
      });
    }
  
    
    else {
      // setTotalEnergyUseVisible(false)
      setDrilldown(false)
      //Main view
      setPayload({
        auth: true,
        url: flexibleEquipmentEnabled=='true'?widgetEndpointsLayers?.totalEnergyUse: widgetEndpoints.totalEnergyUse,
        params: apiParams
      });
    }
  };

  const unsetInnerDrilldown = () => {
    setDaySummaryDrilldownDate(null);
  };
useEffect(()=>{
  // graphApiCall()
  if(incomersValeue || generatorsValue){
    setIncmersToggle(true)
  }
  else{
    setIncmersToggle(false)

  }
},[
  incomersValeue,generatorsValue
])
  return (
    <div>
      <Suspense fallback={<span>Loading</span>}>
        {
        props.drilldownlevel == 'Company' ? (
          <CompanyLayerLayoutComponent
             //  switchGraph={switchGraph}
             chartApiData={chartApiData}
             loading={loading}
             chartData={config}
             charRef={charRef}
             expandWindow={props.expandWindow}
             minimizeWindow={props.minimizeWindow}
             shouldExpand={props.shouldExpand}
             chartId={props.id}
             hasFilter={true}
             graphFilter={graphFilter}
             setGraphFilter={setGraphFilter}
             graphFilterObject={graphFilterObject}
             hasSettings={true}
             filterByDate={true}
             setFilterByDateCheck={setFilterByDateCheck}
             filterByDateCheck={filterByDateCheck}
             dateRange={dateRange}
             setDateRange={setDateRange}
             hasIncompletePeriodLegend={true}
             comparisonView={true}
             comparisonViewCheck={comparisonViewCheck}
             setComparisonViewCheck={setComparisonViewCheck}
             daySummaryDrilldownDate={daySummaryDrilldownDate}
             unsetInnerDrilldown={unsetInnerDrilldown}
             togglePriceCheck={togglePriceCheck}
             setTogglePriceCheck={setTogglePriceCheck}
             graphFilterObjectEnergyPrice={graphFilterObjectEnergyPrice}
             graphInfo={graphInfo}
             totalEnergyUse={totalEnergyUse}
             totalEnergyUseVisible={totalEnergyUseVisible}
             incomersTogle={incomersTogle}
             setIncomersValue={setIncomersValue}
             setGeneratorsValue={setGeneratorsValue}
             incomersValeue={incomersValeue}
             generatorsValue={generatorsValue}
             graphFilterObjectIncomersAndGenerators={graphFilterObjectIncomersAndGenerators}
             incomersFilter={data?.data?.is_incomers_classification_enabled}
             generatorFilter={data?.data?.is_generators_classification_enabled}


          />
        ):
        props.drilldownlevel == 'Home' ? (
          <HomeLayoutComponent
            //  switchGraph={switchGraph}
            chartApiData={chartApiData}
            loading={loading}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            hasSettings={true}
            filterByDate={true}
            setFilterByDateCheck={setFilterByDateCheck}
            filterByDateCheck={filterByDateCheck}
            dateRange={dateRange}
            setDateRange={setDateRange}
            hasIncompletePeriodLegend={true}
            comparisonView={true}
            comparisonViewCheck={comparisonViewCheck}
            setComparisonViewCheck={setComparisonViewCheck}
            daySummaryDrilldownDate={daySummaryDrilldownDate}
            unsetInnerDrilldown={unsetInnerDrilldown}
            togglePriceCheck={togglePriceCheck}
            setTogglePriceCheck={setTogglePriceCheck}
            graphFilterObjectEnergyPrice={graphFilterObjectEnergyPrice}
            graphInfo={graphInfo}
            incomersTogle={incomersTogle}
            setIncomersValue={setIncomersValue}
            setGeneratorsValue={setGeneratorsValue}
            incomersValeue={incomersValeue}
            generatorsValue={generatorsValue}
            graphFilterObjectIncomersAndGenerators={graphFilterObjectIncomersAndGenerators}
            incomersFilter={incomersFilter}
            generatorFilter={generatorFilter}
            totalEnergyUse={totalEnergyUse}
            totalEnergyUseVisible={totalEnergyUseVisible}

          />
        ) : props.drilldownlevel == 'Process' ? (
          <ProcessLayoutComponent
            //  switchGraph={switchGraph}
            chartApiData={chartApiData}
            loading={loading}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            hasSettings={true}
            filterByDate={true}
            setFilterByDateCheck={setFilterByDateCheck}
            filterByDateCheck={filterByDateCheck}
            dateRange={dateRange}
            setDateRange={setDateRange}
            hasIncompletePeriodLegend={true}
            comparisonView={true}
            comparisonViewCheck={comparisonViewCheck}
            setComparisonViewCheck={setComparisonViewCheck}
            daySummaryDrilldownDate={daySummaryDrilldownDate}
            unsetInnerDrilldown={unsetInnerDrilldown}
            togglePriceCheck={togglePriceCheck}
            setTogglePriceCheck={setTogglePriceCheck}
            graphFilterObjectEnergyPrice={graphFilterObjectEnergyPrice}
            graphInfo={graphInfo}
            totalEnergyUse={totalEnergyUse}
            totalEnergyUseVisible={totalEnergyUseVisible}

          />
        ) : props.drilldownlevel == 'Plant'|| props.drilldownlevel=='ProcessLayer' || props.drilldownlevel=='EquipmentLayer'? (
          <PlantLayoutComponent
            //  switchGraph={switchGraph}
            chartApiData={chartApiData}
            loading={loading}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            hasSettings={true}
            filterByDate={true}
            setFilterByDateCheck={setFilterByDateCheck}
            filterByDateCheck={filterByDateCheck}
            dateRange={dateRange}
            setDateRange={setDateRange}
            hasIncompletePeriodLegend={true}
            comparisonView={true}
            comparisonViewCheck={comparisonViewCheck}
            setComparisonViewCheck={setComparisonViewCheck}
            daySummaryDrilldownDate={daySummaryDrilldownDate}
            unsetInnerDrilldown={unsetInnerDrilldown}
            togglePriceCheck={togglePriceCheck}
            setTogglePriceCheck={setTogglePriceCheck}
            graphFilterObjectEnergyPrice={graphFilterObjectEnergyPrice}
            graphInfo={graphInfo}
            totalEnergyUse={totalEnergyUse}
            totalEnergyUseVisible={totalEnergyUseVisible}
            // totalEnergyUse={totalEnergyUse}
            // totalEnergyUseVisible={totalEnergyUseVisible}
            incomersTogle={incomersTogle}
            setIncomersValue={setIncomersValue}
            setGeneratorsValue={setGeneratorsValue}
            incomersValeue={incomersValeue}
            generatorsValue={generatorsValue}
            graphFilterObjectIncomersAndGenerators={graphFilterObjectIncomersAndGenerators}
            incomersFilter={data?.data?.is_incomers_classification_enabled}
            generatorFilter={data?.data?.is_generators_classification_enabled}

          />
        ) 
        
        : props.drilldownlevel == 'Machine' ? (
          <MachineLayoutComponent
            chartApiData={chartApiData}
            loading={loading}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            hasSettings={true}
            filterByDate={true}
            setFilterByDateCheck={setFilterByDateCheck}
            filterByDateCheck={filterByDateCheck}
            dateRange={dateRange}
            setDateRange={setDateRange}
            hasIncompletePeriodLegend={true}
            comparisonView={true}
            comparisonViewCheck={comparisonViewCheck}
            setComparisonViewCheck={setComparisonViewCheck}
            daySummaryDrilldownDate={daySummaryDrilldownDate}
            unsetInnerDrilldown={unsetInnerDrilldown}
            togglePriceCheck={togglePriceCheck}
            setTogglePriceCheck={setTogglePriceCheck}
            graphFilterObjectEnergyPrice={graphFilterObjectEnergyPrice}
            graphInfo={graphInfo}
            totalEnergyUse={totalEnergyUse}
            totalEnergyUseVisible={totalEnergyUseVisible}
          />
        ) : props.drilldownlevel == 'ComponentLayer' ? (
          <EquipmentLayoutComponent
            chartApiData={chartApiData}
            loading={loading}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            hasSettings={true}
            filterByDate={true}
            setFilterByDateCheck={setFilterByDateCheck}
            filterByDateCheck={filterByDateCheck}
            dateRange={dateRange}
            setDateRange={setDateRange}
            hasIncompletePeriodLegend={true}
            comparisonView={true}
            comparisonViewCheck={comparisonViewCheck}
            setComparisonViewCheck={setComparisonViewCheck}
            daySummaryDrilldownDate={daySummaryDrilldownDate}
            unsetInnerDrilldown={unsetInnerDrilldown}
            togglePriceCheck={togglePriceCheck}
            setTogglePriceCheck={setTogglePriceCheck}
            graphFilterObjectEnergyPrice={graphFilterObjectEnergyPrice}
            graphInfo={graphInfo}
            totalEnergyUse={totalEnergyUse}
            totalEnergyUseVisible={totalEnergyUseVisible}
          />
        ) : (
          ''
        )}
        <DateRangeSet
          graphFilter={graphFilter}
          setFilterByDateCheck={setFilterByDateCheck}
          filterByDateCheck={filterByDateCheck}
          dateRange={dateRange}
          setDateRange={setDateRange}
          setGraphFilter={setGraphFilter}
          initialFilter={initialFilter}
          setApiDateRange={setApiDateRange}
          graphInfo={graphInfo}
        />
        <RefreshOnceTotalEnergyUse
          graphApiCall={graphApiCall}
          data={data}
          loading={loading}
          error={error}
          comparisonViewCheck={comparisonViewCheck}
          comparisonFilter={comparisonFilter}
          graphFilter={graphFilter}
        />
        <MainTotalEnergyUseContainer
          INITIALFILTER={INITIALFILTER}
          comparisonViewCheck={comparisonViewCheck}
          data={data}
          charRef={charRef}
          setGraphFilter={setGraphFilter}
          setConfig={setConfig}
          chartApiData={chartApiData}
          chartApiData2={chartApiData2}
          config={config}
          setFilterByDateCheck={setFilterByDateCheck}
          apiDateRange={apiDateRange}
          apiParams={apiParams}
          setApiParams={setApiParams}
          props={props}
          graphFilter={graphFilter}
          graphApiCall={graphApiCall}
          initialFilter={initialFilter}
          setInitialFilter={setInitialFilter}
          filterByDateCheck={filterByDateCheck}
          setChartApiData={setChartApiData}
          setChartApiData2={setChartApiData2}
          setComparisonViewCheck={setComparisonViewCheck}
          comparisonFilter={comparisonFilter}
          userAccessibility={props.userAccessibility}
          daySummaryDrilldownDate={daySummaryDrilldownDate}
          setDaySummaryDrilldownDate={setDaySummaryDrilldownDate}
          daySummaryDrilldownEnabled={true}
          togglePriceCheck={togglePriceCheck}
          setTogglePriceCheck={setTogglePriceCheck}
          setChartApiData3={setChartApiData3}
          chartApiData3={chartApiData3}
          chartApiDataIncomersAndGenerator={chartApiDataIncomersAndGenerator}
          setChartApiDataIncomersAndGenerator={setChartApiDataIncomersAndGenerator}
          incomersValeue={incomersValeue}
          generatorsValue={generatorsValue}
          setIncomersValue={setIncomersValue}
          setGeneratorsValue={setGeneratorsValue}
          setTotalEnergyUse={setTotalEnergyUse}
          setTotalEnergyUseVisible={setTotalEnergyUseVisible}
          // setChartData={setChartData}
          // comparisonChartData={comparisonChartData}
          // toggleChartData={toggleChartData}
          drilldown={drilldown}
        />
      </Suspense>
    </div>
  );
}
