/* eslint-disable react/jsx-key */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import CommonNavLink from "./CommonNavLink";

export default function SidebarLayerList({ dataList, toggleActiveClass, kpi_view, energy_view }) {
  const [openMenu, setOpenMenu] = useState(null);
  const [openNestedMenu, setOpenNestedMenu] = useState(null);
  const [openNestedChildMenu, setOpenNestedChildMenu] = useState(null);
  const [openNestedChildMenu1, setOpenNestedChildMenu1] = useState(null);  // New state for third-level menu

  const toggleMenu = (menu) => {
    setOpenMenu(menu);
  };

  const toggleNestedMenu = (menu) => {
    setOpenNestedMenu(menu);
  };

  const toggleNestedChildMenu = (menu) => {
    setOpenNestedChildMenu(menu);
  };

  const toggleNestedChildMenu1 = (menu) => {  // New function to handle third-level menu
    setOpenNestedChildMenu1(menu);
  };

  // Helper function to determine if a menu has child layers
  const hasChildLayers = (menu) => menu?.layers && menu.layers.length > 0;
  const createNavLinks = (dataList) => {
    return dataList.map((plantMenu) => {
      if (plantMenu?.is_layer_active) {
        return {
          name: plantMenu?.name,
          to: `/${plantMenu.layer_type}/${encodeURIComponent(plantMenu?.name)}?process_type=${plantMenu.layer_type}&plant_id=${plantMenu?.layer_id}&plant_code=${plantMenu?.plant_code}`,
          is_active: plantMenu?.is_layer_active,
          layers: plantMenu?.layers?.map((processMenu) => ({
            name: processMenu?.name,
            to: `/layer_process/${processMenu?.name}?process_type=${processMenu.layer_type}&process_id=${processMenu?.layer_id}&plant_code=${plantMenu?.plant_code}&plant_id=${plantMenu?.layer_id}`,
            is_active: processMenu?.is_layer_active,
            layers: processMenu?.layers?.map((equipmentMenu) => ({
              name: equipmentMenu?.name,
              to: `/layer_equipment/${processMenu?.name}?/${encodeURIComponent(equipmentMenu?.name)}?process_type=${equipmentMenu.layer_type}&equipment_id=${equipmentMenu?.layer_id}&plant_code=${plantMenu?.plant_code}&process_id=${processMenu?.layer_id}&plant_id=${plantMenu?.layer_id}`,
              is_active: equipmentMenu?.is_layer_active,
              layers: equipmentMenu?.layers?.map((componentMenu) => ({
                name: componentMenu?.name,
                to: `/${componentMenu.layer_type}/${encodeURIComponent(componentMenu?.name)}?process_type=${componentMenu.layer_type}&process_id=${processMenu?.layer_id}&plant_code=${plantMenu?.plant_code}&plant_id=${plantMenu?.layer_id}&component_id=${componentMenu?.layer_id}`,
                is_active: componentMenu?.is_layer_active,
              }))
            }))
          }))
        };
      }
      return null;
    }).filter(Boolean); // Filter out any null entries
  };
  
  const navLinks = dataList?.length>=1?createNavLinks(dataList)[0]?.layers:createNavLinks(dataList);
  return (
    <>
      <div className="sidebar">
        {navLinks?.map((plantMenu, i) => {
          return plantMenu?.is_active ? (
            <>
              <div
              style={{marginBottom:'0px'}}
                className={`menu-item ${hasChildLayers(plantMenu) ? 'daz-sidebar__hoverinfo--item daz-sidebar__dropdown-hov' : ''}`}
                onMouseEnter={() => toggleMenu('menu1')}
                onMouseLeave={() => toggleMenu(null)}
              >
                {/* ========================plant=================================== */}
                
                <CommonNavLink
                  to={plantMenu?.to}
                  activeClassName="active"
                >
                  {plantMenu?.name}
                </CommonNavLink>
                {openMenu === 'menu1' && hasChildLayers(plantMenu) && (
                  <div className="child-menu shadow">
                    {plantMenu?.layers?.map((processMenu, i) => {
                      return (
                        <>
                          <div
                            className={`child-item ${hasChildLayers(processMenu) ? 'daz-sidebar__hoverinfo--item daz-sidebar__dropdown-hov' : ''}`}
                            onMouseEnter={() => toggleNestedMenu('nested1')}
                            onMouseLeave={() => toggleNestedMenu(null)}
                          >
                            {/* ========================process=================================== */}
                            <CommonNavLink
                             to={processMenu?.to}
                              // to={`/layer_process/${processMenu?.name}?process_type=${processMenu.layer_type}&process_id=${processMenu?.layer_id}&plant_code=${plantMenu?.plant_code}&plant_id=${plantMenu?.layer_id}`}
                              activeClassName="active" className="child-link"
                            >
                              {processMenu?.name}
                            </CommonNavLink>
                            {openNestedMenu === 'nested1' && hasChildLayers(processMenu) && (
                              <div
                                className="nested-child-menu shadow"
                                onMouseEnter={() => toggleNestedChildMenu('nestedChild1')}
                              >
                                {processMenu?.layers?.map((equipmentMenu, i) => {
                                  return (
                                    <>
                                      <div
                                        className={`nested-child-item ${hasChildLayers(equipmentMenu) ? 'daz-sidebar__hoverinfo--item daz-sidebar__dropdown-hov' : ''}`}
                                        onMouseLeave={() => toggleNestedChildMenu(null)}
                                      >
                                        {/* ========================equipment=================================== */}
                                        <CommonNavLink
                                         to={equipmentMenu?.to}
                                          // to={`/layer_equipment/${processMenu?.name}?/${encodeURIComponent(equipmentMenu?.name)}?process_type=${equipmentMenu.layer_type}&equipment_id=${equipmentMenu?.layer_id}&plant_code=${plantMenu?.plant_code}&process_id=${processMenu?.layer_id}&plant_id=${plantMenu?.layer_id}`}
                                          activeClassName="active" className="nested-child-link"
                                        >
                                          {equipmentMenu?.name}
                                        </CommonNavLink>
                                        {openNestedChildMenu === 'nestedChild1' && hasChildLayers(equipmentMenu) && (
                                          <div
                                            className="nested-child-child-menu shadow"
                                            onMouseEnter={() => toggleNestedChildMenu1('nestedChild2')}
                                          >
                                            {equipmentMenu?.layers?.map((componentMenu, i) => {
                                              return (
                                                <>
                                                  <div
                                                    className={`nested-child-child-item ${hasChildLayers(componentMenu) ? 'daz-sidebar__hoverinfo--item daz-sidebar__dropdown-hov' : ''}`}
                                                    onMouseLeave={() => toggleNestedChildMenu1(null)}
                                                  >
                                                    {/* ========================component=================================== */}
                                                    <CommonNavLink
                                                    to={componentMenu?.to}
                                                      // to={`/${componentMenu.layer_type}/${encodeURIComponent(componentMenu?.name)}?process_type=${componentMenu.layer_type}&process_id=${processMenu?.layer_id}&plant_code=${plantMenu?.plant_code}&plant_id=${plantMenu?.layer_id}&component_id=${componentMenu?.layer_id}`}
                                                      activeClassName="active" className="nested-child-child-link"
                                                    >
                                                      {componentMenu?.name}
                                                    </CommonNavLink>
                                                    {openNestedChildMenu1 === 'nestedChild2' && hasChildLayers(componentMenu) && (
                                                      <div className="nested-child-child-child-menu shadow">
                                                        {/* Add third-level nested links here */}
                                                        {componentMenu?.layers?.map((componentList, i) => {
                                                          return (
                                                            <CommonNavLink
                                                              to={`/${componentList.layer_type}/${encodeURIComponent(componentList?.name)}?process_type=${componentList.layer_type}&process_id=${componentList?.layer_id}&plant_code=${plantMenu?.plant_code}&plant_id=${plantMenu?.layer_id}&component_id=${componentList?.layer_id}`}
                                                              activeClassName="active" className="nested-child-child-child-link"
                                                            >
                                                              {componentList?.name}
                                                            </CommonNavLink>
                                                          );
                                                        })}
                                                      </div>
                                                    )}
                                                  </div>
                                                </>
                                              );
                                            })}
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          ) : ('');
        })}
      </div>
    </>
  );
}