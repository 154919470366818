/* eslint-disable prettier/prettier */
import React, { useEffect, useRef } from 'react';
import { Alert } from 'react-bootstrap';
import SelectUnselectContainer from '../../../common/equipmentlisting/containers/select.unselect.container';
import useOutsideAlerter from '../../../utilities/close.slider.outside.click';
import scrollToTop from '../../../utilities/scroll.top';
import SelectEquipmentComponent from '../../../common/equipmentlisting/components/select.equipment.component';

// const Checkbox = ({ type = "checkbox", name,disabled, checked = true, onChange }) => {
//     return (
//       <input id={name} type={type} disabled={disabled} className="daz-checkbox right" name={name} checked={checked} onChange={onChange} />
//     );
//   };

export default function MachineStatusFilterEquipmentsComponent({
  apiEquipmentList,
  setCheckedItems,
  checkedItems,
  onCheckEquipment,
  onFilterSubmit,
  setShowToast,
  showToast,
  setOpenEquipmentFilterSlider
}) {
  const sliderRef = useRef(null);
  var isClickedOutside = useOutsideAlerter(sliderRef);
  useEffect(() => {
    if (isClickedOutside) {
      setOpenEquipmentFilterSlider(false);
    }
  }, [isClickedOutside]);

  useEffect(() => {
    if (showToast.show) {
      scrollToTop(sliderRef);
    }
  }, [showToast.show]);

  // const checkLastTrueItem = () => {
  //   var count = 0;
  //   Object.values(checkedItems).map((item, index) => {
  //     if (item) {
  //       count++;
  //     }
  //   });

  //   if (count == 1) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  return (
    <div ref={sliderRef} className="sidebar-slider machine-status-equipment-filter">
      <Alert
        className="sidebar-slider_alert"
        show={showToast.show}
        variant={showToast.variant}
        onClose={() => setShowToast({ ...showToast, show: false })}
      >
        <p className="text-center">
          {showToast.variant == 'success'
            ? 'Filter updated successfully'
            : 'Select atleast one equipment'}
        </p>
      </Alert>
      <i
        className="sidebar-slider_close material-icons notranslate graphoptionicons"
        onClick={() => {
          setOpenEquipmentFilterSlider(false);
        }}
      >
        cancel
      </i>
      <div className="select-source">
        <h3 className="mb-5">Select equipment</h3>
        {/* <div className="flex-btw" >    
                     <input id="unselect_all" type="checkbox" className="daz-checkbox right" name= "unselect_all" checked={unselectAll}  onChange={onUnselectAll} />
                    <label htmlFor="unselect_all">Select/Unselect all</label>
                    </div> */}
        <SelectUnselectContainer checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
        {/* <ul className="daz-listing mt-2">
                        {apiEquipmentList && apiEquipmentList.map((eachEquipment,i) => {
                                var id_name = `equipment_${eachEquipment.id}`
                        return <li key={"equipment_" + i} className="flex-btw">
                            <div>
                                <span className="font-bold">{eachEquipment.equipment_name} </span> <span className="font-light">- {eachEquipment.process_display_name}</span>
                            </div> 
                            <div>
                                <Checkbox    name={id_name} checked = {checkedItems[id_name]}  onChange={onCheckEquipment}  />
                                <label htmlFor={id_name}></label>
                            </div>
                        </li>
                        }) 
                        }
                    </ul>  */}
        <SelectEquipmentComponent
          checkedItems={checkedItems}
          apiEquipmentList={apiEquipmentList}
          onCheckEquipment={onCheckEquipment}
          isSelectUnselect={true}
        />
        <button
          className="btn mt-5 btn-grad pr-5 pl-5 mx-auto"
          type="submit"
          onClick={onFilterSubmit}
        >
          Save
        </button>
      </div>
    </div>
  );
}
