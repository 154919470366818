import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { kpiUnitSlug } from '../containers/machine.kpi.utilities';
import KpiSetCustomComponent from './kpi.set.custom.component';

export default function KpiUnitsComponent({
  setKpiIds,
  heading,
  description,
  currentKpiUnitId,
  currentKpiInfo,
  setCurrentKpiUnitId,
  closeKpiSet,
  currentKpiId,
  handleSetUnit,
  setCustomDetails,
  customKpiInputHandler,
  customApiDetails,
  handleDeleteKpiSet
}) {
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  return (
    <>
      <Modal show={modalShow} onHide={handleClose} centered>
        <div className="modal-wrap">
          <Modal.Header closeButton>
            <h5>Warning!</h5>
          </Modal.Header>
          <Modal.Body>
            Please note that removing a KPI setting also will{' '}
            <strong> remove all previous KPI entries.</strong> <br /> Energy data is still intact
            but you will have to re-enter the KPI values for the previous months.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="btn-grad"
              onClick={() => {
                handleDeleteKpiSet();
                handleClose();
              }}>
              Confirm
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
      <div className="shadow border p-3 kpi-unit-listing">
        <div className="flex-c justify-content-end kpi-unit-listing--header">
          <div className="flex-c">
            {currentKpiId == 'custom' || (currentKpiUnitId && setKpiIds.includes(currentKpiId)) ? (
              <i className="material-icons notranslate medium-grey pointer" onClick={handleShow}>
                delete
              </i>
            ) : (
              ''
            )}
            <i
              className="material-icons notranslate medium-grey close-icon pointer"
              onClick={() => {
                closeKpiSet('close_button');
              }}>
              cancel_rounded
            </i>
          </div>
        </div>
        <div>
          <h6 className="font-bold mb-2 text-center">{heading}</h6>
          <h6 className="text-center">{description}</h6>
        </div>
        {currentKpiId == 'custom' ? (
          <KpiSetCustomComponent
            customApiDetails={customApiDetails}
            setCustomDetails={setCustomDetails}
            customKpiInputHandler={customKpiInputHandler}
          />
        ) : (
          <div className="mt-5 kpi-unit-listing--main">
            {currentKpiInfo.kpiunits.map((eachunit) => {
              return (
                <div
                  key={`kiunits_${eachunit.id}`}
                  className="flex-c-m flex-column pointer"
                  onClick={() => setCurrentKpiUnitId(eachunit.id)}>
                  <h6 className="font-bold mb-1 txt-12">{eachunit.name}</h6>
                  <i
                    className={`notranslate material-symbols-outlined ${
                      currentKpiUnitId == eachunit.id ? 'grad-icon' : 'medium-grey'
                    }`}>
                    {kpiUnitSlug[eachunit.slug]}
                  </i>
                </div>
              );
            })}
          </div>
        )}
        <div className="text-center  mt-5">
          {currentKpiUnitId ||
          (currentKpiId == 'custom' &&
            (customApiDetails.custom_kpi_name.value != '' ||
              customApiDetails.custom_kpi_unit.value != '')) ? (
            <button className="btn btn-lg btn-grad mx-auto mb-2" onClick={handleSetUnit}>
              Confirm
            </button>
          ) : (
            <button className="btn btn-lg btn-darkgrey mb-2">Confirm</button>
          )}
        </div>
      </div>
    </>
  );
}
