const initialState = {
  set_comparison_filter: ''
};

const comparisonFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'COMPARISON_FILTER':
      return {
        ...state,
        set_comparison_filter: action.payload
      };
    default:
      return state;
  }
};

export default comparisonFilterReducer;
