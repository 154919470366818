import { useEffect, useState } from 'react';
import MainLayoutComponent from '../../../common/layout/components/main.layout.component';
import SetKpiMachineContainer from '../containers/set.kpi.machine.container';
export default function ViewKpiMachineComponent({
  isAllKpiSet,
  chartWindows,
  idParams,
  setCurrentKpiId,
  currentKpiId,
  setKpiMachineStatus,
  kpiMachineStatus,
  setKpiIds,
  setCurrentKpiUnitId,
  currentKpiUnitId,
  viewKpiList,
  customApiDetails,
  setCustomApiDetails,
  setUpdateState,
  toggleShowKpiBox,
  showKpiBox,
  is_operator
}) {
  const [kpiList, setKpiList] = useState([]);
  const [isAllChartsRendered, setIsAllChartsRendered] = useState(false);

  useEffect(() => {
    if (kpiMachineStatus && viewKpiList.length > 0) {
      setTimeout(() => {
        setIsAllChartsRendered(true);
      }, 3000);
    }
  }, [kpiMachineStatus, viewKpiList]);

  return (
    <div>
      {/* {openEnterKpiSlider && <MachineKpiEnterContainer idParams={idParams} openEnterKpiSlider={openEnterKpiSlider} setOpenEnterKpiSlider={setOpenEnterKpiSlider} viewKpiList={viewKpiList} setUpdateState={setUpdateState}/>} */}
      {kpiMachineStatus ? (
        <div className={chartWindows.length == 2 ? 'two-graphs' : ''}>
          
          <MainLayoutComponent chartWindows={chartWindows} />
        </div>
      ) : (
        ''
      )}
      {(((!kpiMachineStatus && viewKpiList) || (kpiMachineStatus && isAllChartsRendered))&& !is_operator) && (
        <div>
          <div className="text-center mt-5">
            {kpiMachineStatus && !isAllKpiSet ? (
              showKpiBox ? (
                <i
                  className="material-symbols-outlined notranslate medium-grey pointer"
                  onClick={toggleShowKpiBox}>
                  visibility_off
                </i>
              ) : (
                <i
                  className="material-symbols-outlined notranslate medium-grey pointer"
                  onClick={toggleShowKpiBox}>
                  visibility
                </i>
              )
            ) : (
              ''
            )}
          </div>
{
        !is_operator &&  <SetKpiMachineContainer
        idParams={idParams}
        setCurrentKpiId={setCurrentKpiId}
        currentKpiId={currentKpiId}
        setKpiMachineStatus={setKpiMachineStatus}
        kpiMachineStatus={kpiMachineStatus}
        showKpiBox={showKpiBox}
        setKpiIds={setKpiIds}
        setCurrentKpiUnitId={setCurrentKpiUnitId}
        currentKpiUnitId={currentKpiUnitId}
        viewKpiList={viewKpiList}
        setCustomApiDetails={setCustomApiDetails}
        customApiDetails={customApiDetails}
        kpiList={kpiList}
        setKpiList={setKpiList}
        setUpdateState={setUpdateState}
      />
}

        </div>
      )}
    </div>
  );
}
