import { useRoutes } from 'react-router-dom';
import publicRoutes from './public.routes';
import privateRoutes from './private.routes';
import redirectionLoginRoutes from './redirection.login.routes';

function AppRouter() {
  return useRoutes([publicRoutes, privateRoutes, redirectionLoginRoutes]);
}

export default AppRouter;
