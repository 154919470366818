const initialState = {
  linkClick: false
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SIDEBAR_CLICK':
      return {
        ...state,
        linkClick: action.payload
      };
    default:
      return state;
  }
};

export default sidebarReducer;
