const { useReducer, createContext } = require('react');

function ContextTemplate(reducer, actions, initialState) {
  const Context = createContext();

  function Provider(props) {
    const [state, dispatch] = useReducer(reducer, initialState);

    let boundActions = {};

    for (let key in actions) {
      boundActions[key] = actions[key](dispatch);
    }

    return (
      <Context.Provider
        value={{
          state,
          ...boundActions
        }}>
        {props.children}
      </Context.Provider>
    );
  }

  return [Context, Provider];
}

export default ContextTemplate;
