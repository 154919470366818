import React from 'react';
import DownloadChartComponent from '../components/downloadchart.component';

export default function DownloadChartContainer({ charRef }) {
  const downloadChart = (type) => {
    const chart = charRef.current.chart;
    if (type == 'csv') {
      chart.downloadCSV();
    } else if (type == 'excel') {
      chart.downloadXLS();
    } else {
      chart.exportChartLocal({
        type: type,
        sourceWidth: 1500,
        sourceHeight: 520
      });
    }
  };

  return <DownloadChartComponent downloadChart={downloadChart} />;
}
