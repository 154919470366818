/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import {
  axisLablesFontSizeSet,
  chartChartConfig,
  chartCommonConfig,
  chartTitleConfig,
  chartYaxisConfig
} from '../../../config/chartCommon';
const _ = require('lodash');

function comparisonConfig({ chartApiData, graphFilter, graphName, userAccessibility }) {
  const config = {
    ...chartTitleConfig({
      graphTitle: graphName,
      userAccessibility: userAccessibility
    }),
    ...chartCommonConfig,
    chart: {
      ...chartChartConfig({ userAccessibility: userAccessibility }),
      spacingTop: 10,
      spacingBottom: 15,
      type: 'column'
    },
    colors: [
      '#7cb5ec',
      '#a2c3c5',
      '#f7a35c',
      '#434348',
      '#8085e9',
      '#f15c80',
      '#e4d354',
      '#2b908f',
      '#f45b5b',
      '#91e8e1'
    ],
    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'bottom',
      y: 20,
      itemStyle: {
        fontWeight: 'lighter',
        fontSize: axisLablesFontSizeSet(userAccessibility)
      },
    },
    series: chartApiData && chartApiData.length == 0 ? [] : chartApiData,
    exporting: {
      chartOptions: {
        chart: {
          spacingBottom: 30
        },
        subtitle: {
          text: ''
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.y:,.0f}',
              style: {
                fontSize: graphFilter.filterMain != '1week' ? '9px' : '11px'
              }
            }
          }
        }
      }
    },
    tooltip: {
      style: {
        lineHeight: '17px',
    },
      formatter: function () {
        let monthRange = graphFilter.filterMain == '1year' ? this.point.month_range : '';
        let kWh = this.point.kwh != null ? `<br><div class="font-bold txt-10">${_.round(this.point.kwh,2)} kWh</div>` : ''
        let avgKwh = this.point.avg_kwh != null ? `<br><div class="txt-10"> Avg: ${_.round(this.point.avg_kwh, 2)} kWh` : '';
        return `<div class="txt-10">${this.point.name}</div><br><div class="txt-10"><span style="color:${this.series.color}">\u25CF </span>${this.series.name}: <span class="font-bold">${Math.ceil(this.point.y * 100) / 100} %</span>(${this.point.hours}h)</div> ${kWh} ${avgKwh}<br><div class=" txt-10">${monthRange}</div>`;
      },
      outside: true,
      positioner: function(labelWidth, labelHeight, point) {
        var x = Math.min(Math.max(point.plotX + this.chart.plotLeft - labelWidth / 2, this.chart.plotLeft), this.chart.plotWidth + this.chart.plotLeft - labelWidth);
        var y = point.plotY + this.chart.plotTop - labelHeight - 5;
        return { x: x, y: y };
      }
    },
    xAxis: {
      lineColor: '#cccccc',
      categories: null,
      tickLength: 0,
      tickPixelInterval: 70,
      labels: {
        autoRotation: undefined,
        formatter: function () {
          return (
            chartApiData &&
            chartApiData[0].data[this.value] &&
            chartApiData[0].data[this.value].name
          );
        },
        style: {
          fontSize: axisLablesFontSizeSet(userAccessibility)
        }
      }
    },
    yAxis:{
      max:100,
      min:0,
     ...chartYaxisConfig({ userAccessibility }).yAxis
    }
  };
  return config;
}

export default comparisonConfig;
