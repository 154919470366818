/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { subDays } from 'date-fns';
import { setExcelDownload } from '../../../redux/exceldownload/exceldownload.action';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
export default function SetRecurringDownloadComponent({
  onFormSubmit,
  formData,
  setFormData,
  openDownloadScreen,
  recurringDownloadInfo,
}) {
  //const data_type = formData.data_type.value;
  const INITIAL_DATE = new Date().setHours(0, 0, 0, 0);
  const dispatch = useDispatch();

  const dateFilter = (date) => {
    return moment() > date;
  };

  const startTimeFilter = (time) => {
    return moment() >= time;
  };

  const endTimeFilter = (time) => {
       return moment() >= time && moment(formData.start_date) <= time;
  };

  const startSelectedDate = () => {
    if(isDateCurrent(formData.start_date)){
        return subSingleDayFromToday("start_date");
    }
    return formData.start_date;

  };

  const isDateCurrent = (date) => {
    let data_type_value =  recurringDownloadInfo && getRecurringDownloadInfo('current_data_resolution').slug;
    return data_type_value == "daily" && moment(date).isSame(new Date(), 'day');
  }

  const subSingleDayFromToday = (key) => {
    let subDate = subDays(new Date(), 1).setHours(0, 0, 0, 0);
    setFormData((prev) => ({ ...prev, [key]: subDate }));
    return subDate;
  }

  const endSelectedDate = () => {
    if(isDateCurrent(formData.end_date)){
        return subSingleDayFromToday("end_date");
    }
    if (moment(formData.start_date) > moment(formData.end_date)) {
        setFormData((prev) => ({ ...prev, end_date:  formData.start_date  }));
        return formData.start_date;
    } else{
        return formData.end_date;
    }
  };

  const startChangeDate = (date) => {
    setFormData((prev) => ({ ...prev, start_date:date }));
  };
  const endChangeDate = (date) => {
    setFormData((prev) => ({ ...prev, end_date:date }));
  };

  const onBackToSelectEquipments = () => {
    openDownloadScreen('select_source');
  };

  const handleExcludeDates = () => {
    let data_type_value =  recurringDownloadInfo && getRecurringDownloadInfo('current_data_resolution').slug;
    return  (data_type_value == "daily") ? [new Date()] : [] ;
};
    const handleDateFormat = () => {
        let timeInterval =  recurringDownloadInfo && getRecurringDownloadInfo('current_data_resolution').time_interval;
        return  timeInterval ? 'yyyy-MM-dd, HH:mm' : 'yyyy-MM-dd' ;
    };

  
  const handleMinDate = () => {
      return recurringDownloadInfo && subDays(new Date(), getRecurringDownloadInfo('current_data_resolution').max_selection);
  };
  const handleTimeInterval = () => {
        let timeInterval =  recurringDownloadInfo && getRecurringDownloadInfo('current_data_resolution').time_interval;
        return  timeInterval ? timeInterval : 2 ;
    }
const handleShowTimeSelect = () => {
        let timeInterval =  recurringDownloadInfo && getRecurringDownloadInfo('current_data_resolution').time_interval;
        console.log("time", timeInterval ? true : false )
        return  timeInterval ? true : false ;
};

  const getRecurringDownloadInfo = (param) =>{
    if(recurringDownloadInfo){
        let currentDataTypeValues = recurringDownloadInfo.data_type_values[recurringDownloadInfo.data_type_values.findIndex( k => k.slug === formData.data_type)]
        let currentDataResolution = currentDataTypeValues.data_resolution[currentDataTypeValues.data_resolution.findIndex( k => k.slug === formData.data_resolution)]
        if(param === 'data_resolution_array'){
          console.log('data resolution',currentDataTypeValues.data_resolution )
          return  currentDataTypeValues.data_resolution;
        } else if(param ===  'period_mappings_array'){
           return currentDataResolution.period_mappings
        }
        else if(param === 'current_data_resolution'){
            return currentDataResolution
        }
        else{
            return currentDataTypeValues
        }
    }
    else{
        return null
    }
   
  }

  return (
    <div className="set-download">
      <i
        className=" material-icons notranslate graphoptionicons flip-icon top-left-btn"
        onClick={() => onBackToSelectEquipments()}>
        forward
      </i>
      <h3>API settings</h3>
      <p className="mt-2 mb-4 txt-15">
        Create a recurring data export that allows you to integrate sensor data with your own
        systems. Select a period of time, data type and if the data should be daily, hourly or
        2-minute values. Data comes in CSV format and the download frequency is decided in the
        platform you integrate it with.
      </p>
      <form onSubmit={onFormSubmit}>
        <div className="form-group">
          <label className="mb-2 mt-2">Period</label>
          <div id="periods" className="flex-c flex-wrap ">
              {recurringDownloadInfo && getRecurringDownloadInfo('period_mappings_array').map((eachPeriod) => {
                return (
                  <button
                    key={eachPeriod.period_id}
                    type="button"
                    disabled={formData.span}
                    id={eachPeriod.period_id}
                    onClick={() => setFormData((prev) => ({...prev, period : eachPeriod.period_id}))}
                    className={` ${
                        formData.period == eachPeriod.period_id &&
                      'graph-filter-active'
                    } graph-filter-btn mb-2`}>
                    {eachPeriod.period}
                  </button>
                );
              })}
            </div>
        </div>
        <div className="form-group flex-c">
          <input
            className="daz-checkbox right mr-1"
            type="checkbox"
            checked={formData.span}
            onChange={() => setFormData((prev) => ({...prev, span : !prev.span}))}
            id="span"
          />
          <label htmlFor="span" className="ml-1">
            Span
          </label>
        </div>
        <div className="form-group">
            <label className="mb-2">
                Start date<span className="subheading-grey">
                     &nbsp; (with time)
                </span>
            </label>
          
            <DatePicker
                selected={startSelectedDate()}
                onChange={startChangeDate}
                disabled = {!formData.span}
                showTimeSelect={handleShowTimeSelect()}
                dateFormat={handleDateFormat()}
                timeIntervals={handleTimeInterval()}
                timeFormat="HH:mm"
                className="mb-2 shadow w-100"
                excludeDates={handleExcludeDates()}
                 filterDate={dateFilter}
                 filterTime={startTimeFilter}
                minDate={handleMinDate()}
                calendarStartDay={1}
            />
            <label className="mb-2 mt-4">
                End date<span className="subheading-grey">
                     &nbsp; (with time)
                </span>
            </label>
            <DatePicker
                selected={endSelectedDate()}
                onChange={endChangeDate}
                disabled = {!formData.span}
                showTimeSelect={handleShowTimeSelect()}
                dateFormat={handleDateFormat()}
                timeIntervals={handleTimeInterval()}
                timeFormat="HH:mm"
                minDate={formData.start_date}
                filterDate={dateFilter}
                excludeDates={handleExcludeDates()}
                filterTime={endTimeFilter}
                calendarStartDay={1}
                className="shadow w-100"
            />
            </div>
            <div className="form-group">
          <label className="mb-2 mt-2">Data type</label>
          <div id="dataType" className="flex-c flex-wrap ">
              {recurringDownloadInfo && recurringDownloadInfo.data_type_values.map((eachDataType) => {
                return (
                  <button
                    key={eachDataType.slug}
                    type="button"
                    id={eachDataType.slug}
                    onClick={() => setFormData((prev) => ({...prev, data_type : eachDataType.slug, data_resolution: eachDataType.data_resolution[0].slug, period:eachDataType.data_resolution[0].period_mappings[0].period_id, start_date:INITIAL_DATE, end_date: INITIAL_DATE }))}
                    className={` ${
                        formData.data_type == eachDataType.slug &&
                      'graph-filter-active'
                    } graph-filter-btn mb-2`}>
                    {eachDataType.data_type}
                  </button>
                );
              })}
            </div>
        </div>

        <div className="form-group">
          <label className="mb-2 mt-2">Values</label>
          <div id="dataResolutions" className="flex-c flex-wrap ">
              {recurringDownloadInfo && getRecurringDownloadInfo('data_resolution_array').map((eachDataValue) => {
                return (
                  <button
                    key={eachDataValue.slug}
                    type="button"
                    id={eachDataValue.slug}
                    onClick={() => setFormData((prev) => ({...prev,data_resolution : eachDataValue.slug,period:eachDataValue.period_mappings[0].period_id, start_date:INITIAL_DATE, end_date: INITIAL_DATE}))}
                    className={` ${
                        formData.data_resolution == eachDataValue.slug &&
                      'graph-filter-active'
                    } graph-filter-btn mb-2`}>
                    {eachDataValue.data_type_value}
                  </button>
                );
              })}
            </div>
        </div>

        <div className="form-group">
          <label className="mb-2 mt-2">Format</label>
          <div id="dataResolutions" className="flex-c flex-wrap ">
              {recurringDownloadInfo && recurringDownloadInfo.format.map((eachFormat) => {
                return (
                  <button
                    key={eachFormat.slug}
                    type="button"
                    id={eachFormat.slug}
                    onClick={() => setFormData((prev) => ({...prev, format : eachFormat.slug}))}
                    className={` ${
                        formData.format == eachFormat.slug &&
                      'graph-filter-active'
                    } graph-filter-btn mb-2`}>
                    {eachFormat.name}
                  </button>
                );
              })}
            </div>
        </div>
        <div className='flex-c-m'>
            <Button
            variant="primary"
            className="btn-grad  mt-5 "
            type="submit"
            >
            Generate Link
            </Button>
        </div>

       </form>   
      
    </div>
  );
}
