/* eslint-disable prettier/prettier */
import moment from 'moment';
import { useEffect, useState } from 'react';
import useHttpApiCall from '../../services/useHttpApiCall';

export default function RefreshMultipleTimeseries({
  data,
  payload2,
  charRef,
  chartApiData,
  setChartApiData,
  lastTimeStamp,
  setLastTimeStamp,
  graphApiCall,
  graphAddPointApiCall,
  equipmentEnergyUsage,
  setEquipmentEnergyUsage,
  setProcessedXAxis
}) {
  const [checkOfflineCut, setCheckOfflineCut] = useState(false);
  // const EXTRATIME_TO_TRIGGER = 0 //Trigger the api after 30 seconds

  const EXTRATIME_TO_TRIGGER = chartApiData.point_interval == 300000 ? 30 * 1000 : 5000; //Trigger the api after 30 seconds
  const TIME_TO_TRIGGER = chartApiData.point_interval ? chartApiData.point_interval : 120000;
  let addPointApiTimer, loadingTimer, callEveryHour;

  const [data2, loading2, error2] = useHttpApiCall(payload2);

  useEffect(() => {
    if (!checkOfflineCut) {
      callEveryHour = setTimeout(
        () => {
          console.log('reload after 1hr');
          graphApiCall();
        },
        60 * 60 * 1000
      );
    }

    return () => {
      clearTimeout(callEveryHour);
    };
  }, [chartApiData]);

  const addPointApi = (triggertime, diffrenceTime) => {
    clearTimeout(loadingTimer);
    clearTimeout(addPointApiTimer);
    //console.log('extra time', EXTRATIME_TO_TRIGGER, chartApiData.point_interval, chartApiData);
    let timeOutTime = triggertime - diffrenceTime + EXTRATIME_TO_TRIGGER;
    console.log('Api will be called after minutes', moment.utc(timeOutTime).format('HH:mm:ss'));

    addPointApiTimer = setTimeout(function () {
      console.log('point add called at', moment.utc().local().format('HH:mm:ss'));
      setCheckOfflineCut(true);
      graphAddPointApiCall();
    }, timeOutTime);
  };

  useEffect(() => {
    if (lastTimeStamp) {
      let lastTime = moment(lastTimeStamp);
      let diffrenceTime = moment().diff(lastTime); // Time left from current time to last updated
      console.log('difference time ', diffrenceTime, 'last time stamp', lastTimeStamp);

      if (diffrenceTime > TIME_TO_TRIGGER) {
        //console.log('difference time is more', diffrenceTime);
        diffrenceTime = TIME_TO_TRIGGER;
      }
      addPointApi(TIME_TO_TRIGGER, diffrenceTime);
    }
    return () => {
      clearTimeout(addPointApiTimer);
    };
  }, [lastTimeStamp, data]);

  useEffect(() => {
    if (data2 && data2.data) {
      let apiData = data2.data.data;
      let series = charRef.current.chart.series;
      if (series.length == apiData.data_points.length){
        setChartApiData((prev) => ({ ...prev, last_updated_time: apiData.last_updated_time }));
        setCheckOfflineCut(false);
        console.log("api data", apiData)

        if (Array.isArray(apiData) && apiData.length == 0) {
          // array exists and is empty
          console.log("call again api")
          addPointApi(0, 0);
        } else if (series && apiData.data_points && apiData.data_points.length != 0) {
          series.forEach((eachSeries, i) => {
            apiData.data_points[i].data.forEach((eachSeriesData) => {
              eachSeries.addPoint([eachSeriesData[0], eachSeriesData[1]], false, true);
            });
          });
          setLastTimeStamp(apiData.last_updated_time);
        }
  
        if (apiData.equipment_energy_usage) {
          let updatedArray = equipmentEnergyUsage.map((innerArr, i) => {
            // Create a new array by removing the first element and adding values from another array
            const updatedInnerArr = [
              ...innerArr.slice(apiData.time_intervals.length),
              ...apiData.equipment_energy_usage[i]
            ];
            return updatedInnerArr;
          });
          setEquipmentEnergyUsage(updatedArray);
  
          // let timestampsArray = Object.keys(apiData.kwh_data_points).map(function (timestamp) {
          //   return parseInt(timestamp);
          // });
  
          // // Sort the array of timestamps in ascending order
          // timestampsArray.sort(function (a, b) {
          //   return a - b;
          // });
  
          // setProcessedXAxis((prev) => {
          //   let tempPrev = [...prev];
          //   tempPrev.splice(0, timestampsArray.length);
          //   return [...tempPrev, ...timestampsArray];
          // });
  
          setProcessedXAxis((prev) => {
            let tempPrev = [...prev];
            tempPrev.splice(0, apiData.time_intervals.length);
            return [...tempPrev, ...apiData.time_intervals];
          });
        }
      }
      else{
        graphApiCall();
      }
    }
  }, [data2]);

  useEffect(() => {
    if (error2) {
      console.log('error case');
      addPointApi(0, 0);
    }
  }, [error2]);

  useEffect(() => {
    if (!loading2 && checkOfflineCut) {
      console.log('gone offline, trigger after', TIME_TO_TRIGGER);
      loadingTimer = setTimeout(function () {
        addPointApi(0, 0);
      }, TIME_TO_TRIGGER);
    }
    return () => {
      clearTimeout(loadingTimer);
    };
  }, [loading2]);

  // useEffect(() => {
  //   return () => {
  //     clearTimeout(addPointApiTimer);
  //     if (charRef.current) {
  //       charRef.current.destroy();
  //     }
  //   };
  // }, []);

  return <></>;
}
