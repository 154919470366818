/* eslint-disable no-undef */
/* eslint no-use-before-define: 0 */
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import React from 'react';
const { NODE_ENV } = process.env;

const instance = createInstance({
  urlBase: 'https://mato.dazoq.se/',
  siteId: 4
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  NODE_ENV == 'production' && process.env.REACT_APP_ENV != 'staging' ? (
    <MatomoProvider value={instance}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </MatomoProvider>
  ) : (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
