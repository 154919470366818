/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import MachineStatusCustomizeComponent from '../components/machine.status.customize.component';
import useHttpApiCall from '../../../services/useHttpApiCall';
import machineStatusApiParams from '../../../utilities/machine.status.api.params';
import { machineStatusEndpoints } from '../../../config/widgetEndpoints';
import { doRangesOverlap } from '../../../utilities/form.validations';
import { validateMachineStatusCustomize } from './machine.status.validate';
import { current } from '@reduxjs/toolkit';
import _ from 'lodash';

export default function MachineStatusCustomizeContainer({
  processDetails,
  openMachineStatusSlider,
  setOpenMachineStatusSlider,
  setCustomizeUpdate,
  filterGraphId
}) {
  const [payload, setPayload] = useState(null);
  const [data] = useHttpApiCall(payload);
  const [payload2, setPayload2] = useState(null);
  const [data2] = useHttpApiCall(payload2);
  const [payload3, setPayload3] = useState(null);
  const [data3] = useHttpApiCall(payload3);
  const [machineStatusData, setMachineStatusData] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [formErrors, setFormErrors] = useState(null);
  const [editedEquipmentList, setEditedEquipmentList] = useState([]);
  const [isFormError, setIsFormError] = useState(false);
  
  useEffect(() => {
    if (openMachineStatusSlider) {
      setPayload({
        auth: true,
        url: machineStatusEndpoints.getpost,
        params: machineStatusApiParams({ apiType: 'machineStatusGet', props: processDetails })
      });
    }
  }, [openMachineStatusSlider,data3,data2]);

  useEffect(() => {
    if (data && data.data) {
      let apiData = data.data.data;
      let errorArr = [];
      setMachineStatusData(apiData);
      setFormEdited(false);
      apiData.machine_status.forEach((eachEquipment) => {
        let errorObj = { source_mapping: eachEquipment.source_mapping, errorMsg: {} };
        errorArr.push(errorObj);
      });
      setFormErrors(errorArr);
    }
  }, [data]);

  const inputHandler = (e) => {
    let input_type = e.target.id;
    let input_value = e.target.value;
    let source_mapping = parseInt(e.target.getAttribute('data-source-mapping'));
    let tempApiData = machineStatusData.machine_status;
    let currentApiDataIndex = tempApiData.findIndex((t) => t.source_mapping == source_mapping);
    tempApiData[currentApiDataIndex][input_type] = input_value != '' ? parseFloat(input_value) : null;

    let greastestInputTypeValue = findGreatestInputTypeValue(tempApiData[currentApiDataIndex])
    tempApiData[currentApiDataIndex]["production_above"] = greastestInputTypeValue;
     
    setMachineStatusData((prev) => ({
      ...prev,
      machine_status: tempApiData
    }));
    setFormEdited(true);
    let tempEquipment = [...editedEquipmentList];

    if (!tempEquipment.includes(source_mapping)) {
      tempEquipment.push(source_mapping);
      setEditedEquipmentList(tempEquipment);
    }
  };


  const findGreatestInputTypeValue = (currentInputObj) =>{
    let greatestNumber = _.max([currentInputObj.sleep_at_below, currentInputObj.standby_at_below, currentInputObj.off_at_below]);
    return greatestNumber
  }


  useEffect(() => {
    if (formErrors) {
      if (formErrors.every((v) => Object.keys(v.errorMsg).length == 0)) {
        setIsFormError(false);
      } else {
        setIsFormError(true);
      }
    }
  }, [formErrors]);



  const blurHandler = (e) => {
    let input_type = e.target.id;
    let source_mapping = e.target.getAttribute('data-source-mapping');
    
    setFormErrors(validateMachineStatusCustomize({input_type,source_mapping,formErrors,machineStatusData}))
    
    // if (input_type == 'standby_at_below') {
    //    validateIdle(source_mapping);
    //   setFormErrors( validateMachineStatusCustomize({input_type,source_mapping,formErrors,machineStatusData}))
    // }
    if (input_type == 'sleep_at_below') {
      // validateSleepMode(source_mapping);
      // validateIdle(source_mapping);
      //setFormErrors( validateMachineStatusCustomize({input_type,source_mapping,formErrors,machineStatusData}))
      setFormErrors( validateMachineStatusCustomize({input_type:'standby_at_below' ,source_mapping,formErrors,machineStatusData}))

    }
    if (input_type == 'off_at_below') {
      // validateOff(source_mapping)
      // validateSleepMode(source_mapping);
      // validateIdle(source_mapping);
     // setFormErrors( validateMachineStatusCustomize({input_type,source_mapping,formErrors,machineStatusData}))
      setFormErrors( validateMachineStatusCustomize({input_type:'sleep_at_below' ,source_mapping,formErrors,machineStatusData}))
      setFormErrors( validateMachineStatusCustomize({input_type:'standby_at_below' ,source_mapping,formErrors,machineStatusData}))

    }
  };

  // Enter KPI form submit
  const onFormSubmit = (e) => {
    e.preventDefault();
    let filteredApiSendArray = machineStatusData.machine_status.filter(
      (obj) => editedEquipmentList.includes(obj.source_mapping) || obj.id
    );
    if (machineStatusData.is_machine_status_empty) {
      // Api for creating a creating
      setPayload2({
        auth: true,
        url: machineStatusEndpoints.getpost,
        method: 'POST',
        data: filteredApiSendArray,
        params: machineStatusApiParams({ apiType: 'machineStatusGet', props: processDetails })
      });
    } else {
      // Api for creating a creating
      setPayload3({
        auth: true,
        url: machineStatusEndpoints.multiUpdate,
        method: 'PATCH',
        data: filteredApiSendArray,
        params: machineStatusApiParams({ apiType: 'machineStatusGet', props: processDetails })
      });
    }
  };

  useEffect(() => {
    if ((data2 && data2.data) || (data3 && data3.data)) {
      setShowToast(true);
      setFormEdited(false);
      setEditedEquipmentList([]);
      setCustomizeUpdate((prev) => ({ filterGraphId: filterGraphId, update: !prev.update }));
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }
  }, [data2, data3]);

  return (
    <MachineStatusCustomizeComponent
      onFormSubmit={onFormSubmit}
      formEdited={formEdited}
      inputHandler={inputHandler}
      setShowToast={setShowToast}
      showToast={showToast}
      processDetails={processDetails}
      machineStatusData={machineStatusData}
      setOpenMachineStatusSlider={setOpenMachineStatusSlider}
      formErrors={formErrors}
      blurHandler={blurHandler}
      isFormError={isFormError}
    />
  );
}
