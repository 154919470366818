export const barColorCode = [
    {
      name: "Purchased",
      color: '#3A5984'
    },
    {
      name: 'Measured',
      color: '#61b3ed'
    },
    {
      name: "Produced",
      color: '#FFD763'
    },
    {
        name: "Incomplete",
        color: '#C4C4C4'
      }
  ]
//   export const legendLabel=[
//     {
//         name:"Purchased",
//         label:"Purchased energy"
//     },
//     {
//         name:"Produced",
//         label:"Produced energy"
//     },
//     {
//         name:"Measured",
//         label:"Measured"
//     }

//   ]