const initialState = {
  set_user_accessibility: null
};

const userAccessibilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER_ACCESSIBILITY':
      return {
        ...state,
        set_user_accessibility: action.payload
      };
    default:
      return state;
  }
};

export default userAccessibilityReducer;
