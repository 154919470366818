import React from "react";
import { NavLink } from "react-router-dom";

export default function SidebarList({dataList,toggleActiveClass,kpi_view,energy_view}){
    return(<>
        {dataList.map((unitMenu, i) => {
        return (
          <div key={`${unitMenu.name}_${i}`} className="daz-sidebar__dropdown-wrp">
            <div
              onClick={toggleActiveClass}
              className="daz-sidebar__hoverinfo--item   daz-sidebar__dropdown-hov ">
              {/* <i class="material-icons notranslate graphoptionicons red-color">notifications</i> */}
              <NavLink
                className={({ isActive }) => (isActive ? ' daz-sidebar__hoverinfo--active' : '')}
                to={`/processes/${encodeURIComponent(unitMenu.name)}?process_type=${
                  unitMenu.process_type
                }&process_id=${unitMenu.process_id}&sub_process_id=${
                  unitMenu.sub_process_id
                }&kpi=${kpi_view}&energy=${energy_view}`}>
                {' '}
                {unitMenu.name}
              </NavLink>
            </div>
  
            {unitMenu.equipments ? (
              <div className="daz-sidebar__dropdown shadow">
                {unitMenu.equipments.map((machinesMenu) => {
                  return (
                    <div key={machinesMenu.equipment_id} className="daz-sidebar__dropdown--item">
                      {/* <i class="material-icons notranslate graphoptionicons red-color">notifications</i> */}
                      <NavLink
                        className={({ isActive }) =>
                          'daz-sidebar__hoverinfo--item' +
                          (isActive && ' daz-sidebar__hoverinfo--active')
                        }
                        to={`/processes/${encodeURIComponent(unitMenu.name)}/${encodeURIComponent(
                          machinesMenu.equipment_name
                        )}?process_id=${unitMenu.process_id}&sub_process_id=${
                          unitMenu.sub_process_id
                        }&equipment_id=${
                          machinesMenu.equipment_id
                        }&kpi=${kpi_view}&energy=${energy_view}`}>
                        {' '}
                        {machinesMenu.equipment_name}
                      </NavLink>
                    </div>
                  );
                })}
              </div>
            ) : unitMenu.ugn_zones ? (
              <div className="daz-sidebar__dropdown shadow">
                {Object.keys(unitMenu.ugn_zones).map((eachZone, i) => {
                  return (
                    <div key={i} className="daz-sidebar__dropdown--item p-0">
                      <div
                        onClick={toggleActiveClass}
                        className={'daz-sidebar__dropdown-wrp w-100'}>
                        <div className="daz-sidebar__hoverinfo--item   daz-sidebar__dropdown-hov ">
                          {eachZone}
                        </div>
                        {unitMenu.ugn_zones[eachZone].equipments ? (
                          <div className="daz-sidebar__dropdown shadow">
                            {unitMenu.ugn_zones[eachZone].equipments.map((machinesMenu) => {
                              return (
                                <div
                                  key={machinesMenu.equipment_id}
                                  className="daz-sidebar__dropdown--item">
                                  <NavLink
                                    className={({ isActive }) =>
                                      'daz-sidebar__hoverinfo--item' + isActive &&
                                      'daz-sidebar__hoverinfo--active'
                                    }
                                    to={`/processes/${encodeURIComponent(
                                      unitMenu.name
                                    )}/${encodeURIComponent(
                                      machinesMenu.equipment_name
                                    )}?process_type=${unitMenu.process_type}&process_id=${
                                      unitMenu.process_id
                                    }&sub_process_id=${unitMenu.sub_process_id}&equipment_id=${
                                      machinesMenu.equipment_id
                                    }&kpi=${kpi_view}&energy=${energy_view}`}>
                                    {' '}
                                    {machinesMenu.equipment_name}
                                  </NavLink>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        );
      })}
    </>
  
    )
  }