import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// import { roundToTwoDecimal } from '../../utilities/common.computations';

export default function InstantanousPowerPeakKwhComponent({ peak,time }) {
  return (
    <div className="flex-c-e txt-11">
      <span className='dash-right-border'>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Peak value for the equipment during the selected time frame</Tooltip>}>
          <div className="flex-c">
            {/* <span className="yellow material-icons notranslate-outlined txt-20 ">bolt</span> */}
            Peak:&nbsp;<span className="semi-bold"> {peak?.toFixed(2)} kW </span>
            &nbsp; - &nbsp;<span className=""> {time} </span>

          </div>
        </OverlayTrigger>
      </span>
    </div>
  );
}
