/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
//import InstantanousPowerTotalKwhComponent from '../../../common/commonchartcomponents/instantanous.power.total.kwh.component';
import ChartTopFilterContainer from '../../../common/charttopfilter/containers/charttopfilter.container';
import DownloadChartContainer from '../../../common/downloadchart/containers/downloadchart.container';
import ChartExpandContainer from '../../../common/chartexpand/containers/chartexpand.container';
import ChartSettingsContainer from '../../../common/chartsettings/containers/chartsettings.container';
import { useCallback, useRef } from 'react';
import DateRangePicker from '../../../common/datetimepicker/daterangepicker';

export default function MachineStatusLayoutComponent({
  hasFilter,
  graphFilterObject,
  expandWindow,
  minimizeWindow,
  chartId,
  graphFilter,
  setGraphFilter,
  charRef,
  shouldExpand,
  hasSettings,
  jsChartCallback,
  chartData,
  //chartApiData,
  setOpenMachineStatusSlider,
  isMachineStatusEmpty,
  onSetFilterEquipments,
  selectEquipments,
  filterByDate,
  filterByDateCheck,
  setFilterByDateCheck,
  dateRange,
  setDateRange,
  graphInfo
}) {
  const chartContainerRef = useRef();
  const onWindowChange = useCallback(() => {
    !shouldExpand ? expandWindow(chartId) : minimizeWindow();
  }, [shouldExpand, expandWindow, minimizeWindow, chartId]);
  return (
    <div>
      <div className="home-chart-layout process-level-page four-layout">
        <div className="flex-btw top-functions ">
          <div className="flex-c">
            {hasFilter ? (
              <ChartTopFilterContainer
                graphFilterObject={graphFilterObject}
                graphFilter={graphFilter}
                setGraphFilter={setGraphFilter}
              />
            ) : (
              ''
            )}
              <label className="flex-c">
            {filterByDate ? (
              <i
                onClick={() => {
                  setFilterByDateCheck((prev) => !prev);
                }}
                className={
                  filterByDateCheck
                    ? 'calendar-icon material-icons notranslate graphoptionicons material-icon-grey-active'
                    : 'calendar-icon material-icons notranslate graphoptionicons'
                }>
                calendar_month_mutlined_icon
              </i>
            ) : (
              ''
            )}

            {filterByDateCheck ? (
              <div className="filter_by_date--inputs">
                <DateRangePicker setDateRange={setDateRange} dateRange={dateRange} graphInfo={graphInfo} />
              </div>
            ) : (
              ''
            )}
          </label>
          </div>
          <div className="flex-c top-functions-right">
            {selectEquipments && selectEquipments.equipment_length > 1 ? (
              <>
                <span
                  className={
                    selectEquipments && selectEquipments.is_equipment_filter_enabled
                      ? 'equipment-filter active'
                      : 'equipment-filter'
                  }
                  onClick={onSetFilterEquipments}
                >
                  Filter
                </span>
              </>
            ) : (
              ''
            )}
            <DownloadChartContainer charRef={charRef} />

            <ChartExpandContainer
              shouldExpand={shouldExpand}
              onWindowChange={onWindowChange}
              charRef={charRef}
              chartContainerRef={chartContainerRef}
            />
            {hasSettings ? (
              <ChartSettingsContainer
                machineStatus={true}
                isGreen={isMachineStatusEmpty}
                setOpenMachineStatusSlider={setOpenMachineStatusSlider}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="highcharts-wrp" ref={chartContainerRef}>
          <HighchartsReact
            ref={charRef}
            callback={jsChartCallback}
            highcharts={Highcharts}
            options={chartData}
            containerProps={{ style: { height: '100%' } }}
          />
        </div>
        {/* {chartApiData && chartApiData.equipment_energy_usage && (
          <InstantanousPowerTotalKwhComponent totalKwh={totalKwh} />
        )} */}
      </div>
    </div>
  );
}
