import React from 'react';

export default function KpiSetCustomComponent({ customKpiInputHandler, customApiDetails }) {
  return (
    <div>
      <div className="mt-1">
        <div className="form-group">
          <label className="mb-1">{customApiDetails.custom_kpi_name.label}</label>
          <input
            className="form-control shadow border-0 py-4"
            name={customApiDetails.custom_kpi_name.name}
            type="text"
            value={customApiDetails.custom_kpi_name.value}
            onChange={customKpiInputHandler}
          />
        </div>
        <div className="form-group">
          <label className="mb-1"> {customApiDetails.custom_kpi_unit.label}</label>
          <input
            className="form-control shadow border-0 py-4"
            type="text"
            name={customApiDetails.custom_kpi_unit.name}
            value={customApiDetails.custom_kpi_unit.value}
            onChange={customKpiInputHandler}
          />
        </div>
      </div>
    </div>
  );
}
