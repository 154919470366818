import moment from 'moment';
import { useState, useEffect } from 'react';

const useRealtimeUpdateGraph = (lastTimeStamp, timeToTrigger = 2000*60, extraTime = 1000 * 5) => {
  const [chartUpdateTrigger, setChartUpdateTrigger] = useState(false);
  const [chartUpdateLastTimeStamp, setChartUpdateLastTimeStamp] = useState(lastTimeStamp);
  let timeOut ;


  useEffect(() => {
    if(chartUpdateLastTimeStamp){
      let lastTime = moment(chartUpdateLastTimeStamp);
      let diffrenceTime = moment().diff(lastTime); // Time left from current time to last updated
      //console.log('difference time ', diffrenceTime, 'last time stamp', chartUpdateLastTimeStamp);
      if (diffrenceTime > timeToTrigger) {
        diffrenceTime = timeToTrigger;
      }
      let timeOutTime = timeToTrigger - diffrenceTime + extraTime;
      //console.log("timeOutTime",timeOutTime)
      timeOut = setTimeout(function () {
        //console.log("api called at", moment().format("HH:mm"))
          
          setChartUpdateTrigger(prev => !prev);
      }, timeOutTime);
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [chartUpdateLastTimeStamp]);

  return [
    chartUpdateTrigger,
    setChartUpdateLastTimeStamp
  ];
};

export default useRealtimeUpdateGraph;

