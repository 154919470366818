import SelectSourceComponent from '../components/select.source.component';

export default function SelectSourceContainer({
  onSourceNextClick,
  setCheckedItems,
  checkedItems,
  apiEquipmentList,
  openAlertScreen,
  setSelectSensorClassification,
  setIsSelectAlertType,
  isSelectAlertType
}) {
  // const [payload, setPayload] = useState(undefined);
  // const [data, loading, error] = useHttpApiCall(payload);
  const onCheckEquipment = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
  };

  return (
    <div>
      <SelectSourceComponent
        apiEquipmentList={apiEquipmentList}
        checkedItems={checkedItems}
        onCheckEquipment={onCheckEquipment}
        onSourceNextClick={onSourceNextClick}
        openAlertScreen={openAlertScreen}
        setSelectSensorClassification={setSelectSensorClassification}
        setIsSelectAlertType={setIsSelectAlertType}
        isSelectAlertType={isSelectAlertType}
      />
    </div>
  );
}
