import '../../machinekpi/machinekpi.scss';

export default function KpiListComponent({
  icon,
  heading,
  kpiId,
  setCurrentKpiId,
  setCurrentKpiUnitId,
  setKpiIds,
  viewKpiList,
  setKpiMachineStatus
}) {
  const openKpiUnitBox = () => {
    setCurrentKpiId(kpiId);
    setKpiMachineStatus(false);
    if (setKpiIds.includes(kpiId)) {
      let index = viewKpiList.findIndex((k) => k.kpi.id == kpiId);
      setCurrentKpiUnitId(viewKpiList[index].kpi_unit.id);
    } else {
      setCurrentKpiUnitId(null);
    }
  };
  return (
    <div className="col p-3 shadow border classification-box" onClick={openKpiUnitBox}>
      <h6 className="text-center font-bold mb-2">{heading}</h6>
      <div className="classification-box-icon">
        <i className="material-symbols-outlined notranslate box-icon">{icon}</i>
      </div>
    </div>
  );
}
