import moment from 'moment';
import { useState, useEffect } from 'react';

export default function RefreshOnceTotalEnergyUse({
  graphApiCall,
  data,
  loading,
  error,
  graphFilter,
  comparisonViewCheck,
  comparisonFilter
}) {
  let setRefreshTimer, loadingTimer, lastUpdateWrongTimer;
  const [checkOfflineCut, setCheckOfflineCut] = useState(false);

  const EXTRATIME_TO_TRIGGER = 5 * 60 * 1000; //Refresh the api after 5 min
  const LAST_UPDATE_WRONG = 15 * 60 * 1000;
  // Call API at midnight UTC every day
  const SETTIOMEOUT_MAX_24_DAYS = 1000 * 60 * 60 * 24 * 24;
  const HOURS_12_IN_MILLISEC = 1000 * 60 * 60 * 12;
  const refreshGraphOnce = (timeInMillisec) => {
    console.log('will be called in ', timeInMillisec);

    setRefreshTimer = setTimeout(function () {
      setCheckOfflineCut(true);
      graphApiCall();
    }, timeInMillisec);
  };

  const getTimeInMilliSec = (apiLastUpdated) => {
    let timeInMillisec;
    let lastUpdatedTime = moment(moment.utc(apiLastUpdated).toDate()).format('LLLL');
    let currentTime = moment();
    let timeForRefresh = addDaysForRefresh(lastUpdatedTime);
    let diffrenceTime = moment.duration(timeForRefresh.diff(currentTime));

    if (!apiLastUpdated) {
      return (timeInMillisec = HOURS_12_IN_MILLISEC);
    }

    if (diffrenceTime > 0) {
      if (diffrenceTime.asDays() > 24) {
        return (timeInMillisec = SETTIOMEOUT_MAX_24_DAYS);
      } else {
        return (timeInMillisec = diffrenceTime.asMilliseconds() + EXTRATIME_TO_TRIGGER);
      }
    } else {
      timeInMillisec = LAST_UPDATE_WRONG;
      return timeInMillisec;
    }
  };

  useEffect(() => {
    if (data && data.data) {
      setCheckOfflineCut(false);
      clearTimeout(setRefreshTimer);
      clearTimeout(lastUpdateWrongTimer);

      refreshGraphOnce(getTimeInMilliSec(data.data.last_updated_time));
    }
  }, [data]);

  useEffect(() => {
    if (!loading && checkOfflineCut) {
      loadingTimer = setTimeout(
        function () {
          console.log('loading in total energey');
          graphApiCall();
        },
        15 * 60 * 1000
      );
    }
    return () => {
      clearTimeout(loadingTimer);
    };
  }, [loading]);

  useEffect(() => {
    let errorTimer;
    if (error && !checkOfflineCut) {
      errorTimer = setTimeout(
        function () {
          graphApiCall();
        },
        15 * 60 * 1000
      );
    }
    return () => {
      clearTimeout(errorTimer);
    };
  }, [error]);

  useEffect(() => {
    return () => {
      clearTimeout(setRefreshTimer);
      clearTimeout(lastUpdateWrongTimer);
    };
  }, []);

  const addDaysForRefresh = (lastUpdatedTime) => {
    if (comparisonViewCheck) {
      if (comparisonFilter.filterMain == 'month') {
        return moment(lastUpdatedTime, 'LLLL').add(1, 'months');
      } else {
        return moment(lastUpdatedTime, 'LLLL').add(1, 'days');
      }
    } else {
      if (graphFilter.filterMain == '3months') {
        return moment(lastUpdatedTime, 'LLLL').add(1, 'weeks');
      } else if (graphFilter.filterMain == '1year') {
        return moment(lastUpdatedTime, 'LLLL').add(1, 'months');
      } else {
        return moment(lastUpdatedTime, 'LLLL').add(1, 'days');
      }
    }
  };

  return <></>;
}
