const initialState = {
  set_equipment_filter: ''
};

const filterEquimentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FILTER_EQUIPMENTS':
      return {
        ...state,
        set_equipment_filter: action.payload
      };
    default:
      return state;
  }
};

export default filterEquimentsReducer;
