import moment from 'moment';
import { useEffect, useState } from 'react';
import useHttpApiCall from '../../../services/useHttpApiCall';
import SetDownloadComponent from '../components/set.download.component';
import { useDispatch, useSelector } from 'react-redux';
import { setExcelDownload } from '../../../redux/exceldownload/exceldownload.action';
import { replaceUnderscoreWithHyphen } from '../../../utilities/common.computations';

export default function SetDownloadContainer({
  checkedItems,
  openDownloadScreen,
  setShowToast,
  apiEquipmentList,
  selectSensorClassification,
  sensorUnit
}) {
  const INITIAL_DATE = new Date().setHours(0, 0, 0, 0);
  const dispatch = useDispatch();
  const [payload, setPayload] = useState(null);
  const [data] = useHttpApiCall(payload); //API for download initation
  const set_excel_download = useSelector((state) => state.excelDownload.set_excel_download);
  const [currentSingleDataTypeSensorValueInfo, setCurrentSingleDataTypeSensorValueInfo] =
    useState(null);

  const sensorClassificationSlug = {
    energy: {
      name: 'Energy',
      unit: 'kW',
      values: ['Daily', 'Hourly', '2-min'],
      timeIntervals: 2,
      maxSelectionDays: 30
    },
    temperature: {
      name: 'Temprature',
      unit: '°C',
      values: [
        {
          name: '30-min',
          value: 'temperature-humidity-least-interval',
          timeIntervals: 30,
          maxSelectionDays: 90
        }
      ]
    },
    humidity: {
      name: 'Humidity',
      unit: '%',
      values: [
        {
          name: '30-min',
          value: 'temperature-humidity-least-interval',
          timeIntervals: 30,
          maxSelectionDays: 90
        }
      ]
    },
    total_flow: {
      name: 'Total flow',
      unit: 'm3',
      values: [
        {
          name: 'Daily',
          value: 'daily',
          timeIntervals: 30,
          maxSelectionDays: false,
          hideTime: true
        },
        { name: 'Hourly', value: 'hourly', timeIntervals: 60, maxSelectionDays: false }
      ]
    },
    flow_rate: {
      name: 'Flow rate',
      unit: 'm3/h',
      values: [{ name: '2-min', value: 'least-interval', timeIntervals: 2, maxSelectionDays: 30 }]
    }
  };
  var initialFormObject = {
    data_type: {
      //   label :"Data type",
      value: {
        kW: true,
        kWh: false
      }
      //  name:"data_type"
    },
    values: {
      //  label : "Values",
      value: {
        daily: false,
        hourly: false,
        least_interval: true
      }
      //   name:"values"
    },
    start_date: {
      //  label : "Start date (and time)",
      value: INITIAL_DATE
      //  name:"start_date"
    },
    end_date: {
      //  label : "End date (and time)",
      value: INITIAL_DATE
      //  name:"end_date"
    }
  };
  const [formData, setFormData] = useState(initialFormObject);

  useEffect(() => {
    if (sensorUnit) {
      setCurrentSingleDataTypeSensorValueInfo(sensorClassificationSlug[sensorUnit].values[0]);
    }
  }, [sensorUnit]);

  const getEquipmentList = () => {
    let equipmentArray = [];
    Object.keys(checkedItems).map((item, index) => {
      if (checkedItems[item]) {
        let indexNum = item.split('_')[1];
        console.log('equipmentList', indexNum, index, apiEquipmentList);
        equipmentArray.push({
          source_mapping: apiEquipmentList[index].id
        });
      }
    });
    return equipmentArray;
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    let postEquipmentsSendArray = getEquipmentList();
    let postDataType, postDataValues;
    if (selectSensorClassification && sensorUnit != 'energy') {
      postDataType = sensorUnit;
      postDataValues = currentSingleDataTypeSensorValueInfo.value;
    } else {
      postDataType = Object.keys(formData.data_type.value).find((k) => formData.data_type.value[k]);
      postDataValues = Object.keys(formData.values.value).find((k) => formData.values.value[k]);
    }

    let postStartDate = moment(formData.start_date.value).format('yyyy-MM-DD HH:mm:SS');
    let postEndDate = moment(formData.end_date.value).format('yyyy-MM-DD HH:mm:SS');

    setPayload({
      auth: true,
      url: 'v0/downloads/',
      method: 'POST',
      data: {
        level: 'equipment',
        data: replaceUnderscoreWithHyphen(postDataType),
        data_resolution: replaceUnderscoreWithHyphen(postDataValues),
        start_date_time: postStartDate,
        end_date_time: postEndDate,
        equipments: postEquipmentsSendArray
      }
    });
  };

  const handleSingleDataTypeSensorValue = (e) => {
    let dateInitialObject = {
      end_date: { value: INITIAL_DATE },
      start_date: { value: INITIAL_DATE }
    };

    let currentSingleDataTypeSensorValueObject = sensorClassificationSlug[sensorUnit].values.find(
      (k) => k.value == e.target.id
    );
    setFormData((prev) => ({ ...prev, ...dateInitialObject }));
    setCurrentSingleDataTypeSensorValueInfo(currentSingleDataTypeSensorValueObject);
  };

  const onSelectOption = (e) => {
    let catergory = e.target.parentNode.getAttribute('id');
    let option = e.target.id;
    let selectedValueCopy = formData[catergory].value;
    let dependentValueCopy = formData.values.value;
    let dateInitialObject = {
      end_date: { value: INITIAL_DATE },
      start_date: { value: INITIAL_DATE }
    };

    if (!formData[catergory].value[option]) {
      //Same option is not clicked
      dispatch(
        setExcelDownload({
          ...set_excel_download,
          download_status: false
        })
      );
    }

    changeOneKeyTrue(selectedValueCopy, option);
    if (catergory == 'data_type') {
      if (option == 'kW') {
        changeOneKeyTrue(dependentValueCopy, 'least_interval');
        setFormData((prev) => ({
          ...prev,
          [catergory]: { ...prev[catergory], value: { ...selectedValueCopy } },
          values: { ...prev.values, value: { ...dependentValueCopy } },
          ...dateInitialObject
        }));
      } else if (option == 'kWh') {
        changeOneKeyTrue(dependentValueCopy, 'daily');
        setFormData((prev) => ({
          ...prev,
          [catergory]: { ...prev[catergory], value: { ...selectedValueCopy } },
          values: { ...prev.values, value: { ...dependentValueCopy } }
        }));
      }
    } else if (catergory == 'values') {
      if (option == 'daily') {
        setFormData((prev) => ({
          ...prev,
          [catergory]: {
            ...prev[catergory],
            value: { ...selectedValueCopy }
          }
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [catergory]: { ...prev[catergory], value: { ...selectedValueCopy } },
          ...dateInitialObject
        }));
      }
    }
  };

  const changeOneKeyTrue = (object, conditionValue) => {
    Object.keys(object).forEach((v) => {
      if (v == conditionValue) {
        object[v] = true;
      } else {
        object[v] = false;
      }
    });
  };

  useEffect(() => {
    if (data && data.data) {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 5000);

      let apiData = data.data.data;
      let download_ids;
      if (localStorage.getItem('download_ids') == undefined) {
        download_ids = [];
      } else {
        download_ids = localStorage.getItem('download_ids').split(',');
      }

      download_ids.push(apiData.download_id);

      localStorage.setItem('download_ids', download_ids.join(','));
      if (apiData.download_status == 0) {
        console.log('data download 0 status', download_ids);
        dispatch(
          setExcelDownload({
            ...set_excel_download,
            download_ids: download_ids,
            download_status: true
          })
        );
      } else {
        let cache_download_id = apiData.download_id;
        dispatch(
          setExcelDownload({
            ...set_excel_download,
            cache_download_id: cache_download_id,
            download_status: true
          })
        );
      }
    }
  }, [data]);

  return (
    <>
      <SetDownloadComponent
        onFormSubmit={onFormSubmit}
        formData={formData}
        onSelectOption={onSelectOption}
        setFormData={setFormData}
        openDownloadScreen={openDownloadScreen}
        excelDonwload={set_excel_download}
        selectSensorClassification={selectSensorClassification}
        sensorUnit={sensorUnit}
        sensorClassificationSlug={sensorClassificationSlug}
        currentSingleDataTypeSensorValueInfo={currentSingleDataTypeSensorValueInfo}
        handleSingleDataTypeSensorValue={handleSingleDataTypeSensorValue}
      />
    </>
  );
}
