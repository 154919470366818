import { useEffect, useRef } from 'react';
import { Alert } from 'react-bootstrap';
import SelectEquipmentComponent from '../../../common/equipmentlisting/components/select.equipment.component';
import SelectUnselectContainer from '../../../common/equipmentlisting/containers/select.unselect.container';
import useOutsideAlerter from '../../../utilities/close.slider.outside.click';

function MachineKpiEquipmentFilterComponent({
  apiEquipmentList,
  setCheckedItems,
  checkedItems,
  onCheckEquipment,
  onFilterSubmit,
  setShowToast,
  showToast,
  setOpenEquipmentFilter,
  equipmentsLimit
}) {
  const sliderRef = useRef(null);
  var isClickedOutside = useOutsideAlerter(sliderRef);
  useEffect(() => {
    if (isClickedOutside) {
      setOpenEquipmentFilter(false);
    }
  }, [isClickedOutside]);

  return (
    <div ref={sliderRef} className="sidebar-slider equipmentfilter-slider inside-component">
      {showToast && (
        <Alert
          className="sidebar-slider_alert"
          show={showToast.show}
          variant={showToast.variant}
          onClose={() => setShowToast({ ...showToast, show: false })}>
          <p className="text-center">
            {showToast.variant == 'success'
              ? 'Filter updated successfully'
              : Object.values(checkedItems).filter((item) => item === true).length > equipmentsLimit
              ? 'Select maximum 4 equipments'
              : 'Select atleast one equipment'}
          </p>
        </Alert>
      )}
      <i
        className="sidebar-slider_close material-icons notranslate graphoptionicons"
        onClick={() => setOpenEquipmentFilter(false)}>
        cancel
      </i>
      <div className="select-source">
        <h3 className="mb-5">Select equipment</h3>
        <SelectUnselectContainer checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
        <SelectEquipmentComponent
          checkedItems={checkedItems}
          apiEquipmentList={apiEquipmentList}
          onCheckEquipment={onCheckEquipment}
          isSelectUnselect={true}
          equipmentLimit={4}
        />
        <button
          className="btn mt-5 btn-grad pr-5 pl-5 mx-auto"
          disabled={
            Object.values(checkedItems).filter((item) => item === true).length > equipmentsLimit
          }
          type="submit"
          onClick={onFilterSubmit}>
          Save
        </button>
      </div>
    </div>
  );
}

export default MachineKpiEquipmentFilterComponent;
