import { useEffect, useState } from 'react';
import useHttpApiCall from '../../../services/useHttpApiCall';
import ContactSupportComponent from '../components/contactsupport.component';

export default function ContactSupportContainer({ modalShow, setModalShow, comparisonView }) {
  const [payload, setPayload] = useState(undefined);
  const handleClose = () => setModalShow(false);
  //  const handleShow = () => setModalShow(true);
  const showToastInitialState = { show: false, variant: 'danger' };
  const [showToast, setShowToast] = useState(showToastInitialState);
  const formDataInitialState = {
    support_text: {
      label: 'Support text',
      value: ''
    }
  };
  const [formData, setFormData] = useState(formDataInitialState);
  const [data, , error] = useHttpApiCall(payload);

  const onSupportTextSubmit = () => {
    let txt = formData.support_text.value;
    let txtToSend = '<p>' + txt.replace(/\n/g, '</p>\n<p>') + '</p>';
    setPayload({
      auth: true,
      url: 'v0/contact-us/',
      method: 'POST',
      data: {
        support_text: txtToSend
      }
    });
  };

  useEffect(() => {
    if (data && data.data) {
      setShowToast({ show: true, variant: 'success' });
      setFormData(formDataInitialState);
      setTimeout(() => {
        handleClose();
        setShowToast(showToastInitialState);
      }, 2000);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setShowToast({ show: true, variant: 'danger' });
      setTimeout(() => {
        handleClose();
      }, 2000);
    }
  }, [error]);

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: { ...prev[name], value } }));
  };
  return (
    <ContactSupportComponent
      formData={formData}
      onSupportTextSubmit={onSupportTextSubmit}
      inputHandler={inputHandler}
      modalShow={modalShow}
      handleClose={handleClose}
      showToast={showToast}
      comparisonView={comparisonView}
      setShowToast={setShowToast}
    />
  );
}
