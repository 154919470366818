import moment from 'moment';
import { useEffect, useState } from 'react';
import useHttpApiCall from '../../services/useHttpApiCall';

export default function RefreshSingleTimeseries({
  payload3,
  graphAddPointApiCall,
  graphInstantaneouwApiCall,
  setChartApiData2,
  lastTimeStamp,
  setLastTimeStamp,
  data2,
  chartApiData,
  charRef
}) {
  const EXTRATIME_TO_TRIGGER = 5 * 1000; //Trigger the api after 30 seconds
  const TIME_TO_TRIGGER = 120000;
  let addPointApiTimer, loadingTimer, callEveryHour;
  // const [chartApiData3, setChartApiData3] = useState([]); //New point API
  const [checkOfflineCut, setCheckOfflineCut] = useState(false);
  const addPointApi = (triggertime, diffrenceTime) => {
    clearTimeout(loadingTimer);
    clearTimeout(addPointApiTimer);
    let timeOutTime = triggertime - diffrenceTime + EXTRATIME_TO_TRIGGER;
    addPointApiTimer = setTimeout(function () {
      setCheckOfflineCut(true);
      graphAddPointApiCall();
    }, timeOutTime);
  };

  const [data3, loading3, error3] = useHttpApiCall(payload3);

  useEffect(() => {
    if (lastTimeStamp) {
      let lastTime = moment(lastTimeStamp);
      let diffrenceTime = moment().diff(lastTime);
      if (diffrenceTime > TIME_TO_TRIGGER) {
        diffrenceTime = 0;
      }
      addPointApi(TIME_TO_TRIGGER, diffrenceTime);
    }
    return () => {
      clearTimeout(addPointApiTimer);
    };
  }, [lastTimeStamp, data2]);

  useEffect(() => {
    if (!checkOfflineCut) {
      callEveryHour = setTimeout(
        () => {
          console.log('reload after 1hr');
          graphInstantaneouwApiCall();
        },
        60 * 60 * 1000
      );
    }

    return () => {
      clearTimeout(callEveryHour);
    };
  }, [chartApiData]);

  useEffect(() => {
    // For API errors that dint hit the server
    if (!loading3 && checkOfflineCut) {
      loadingTimer = setTimeout(function () {
        addPointApi(0, 0);
      }, TIME_TO_TRIGGER);
    }
    return () => {
      clearTimeout(loadingTimer);
    };
  }, [loading3]);

  useEffect(() => {
    if (data3 && data3.data) {
      let apiData = data3.data.data;
      //   setChartApiData3(apiData);
      setCheckOfflineCut(false);
      setChartApiData2((prev) => ({ ...prev, last_updated_time: apiData.last_updated_time }));
      let series = charRef.current.chart.series[0];
      if (Array.isArray(apiData) && apiData.length == 0) {
        // array exists and is empty
        addPointApi(0, 0);
      } else {
        apiData.data_points.forEach((data) => {
          series.addPoint([data[0], data[1]], false, true);
        });
        setLastTimeStamp(apiData.last_updated_time);
      }
    }
    return () => {};
  }, [data3]);

  useEffect(() => {
    if (error3) {
      console.log('error case');
      addPointApi(0, 0);
    }
  }, [error3]);
  return <></>;
}
