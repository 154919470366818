import ContextTemplate from './context.template';

let initialState = { sidebarLinks: [] };
const reducer = (prevState = initialState, action) => {
  switch (action.type) {
    case 'SET_SIDEBAR':
      return { ...prevState, sidebarLinks: action.payload };
    default:
      return prevState;
  }
};

const fetchSidebarLinks = (dispatch) => {
  return (payload) => {
    dispatch({ type: 'SET_SIDEBAR', payload: payload });
  };
};

const action = { fetchSidebarLinks };

const [context, provider] = ContextTemplate(reducer, action, initialState);

export { context, provider };
