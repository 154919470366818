import { roundToTwoDecimal } from "../../../utilities/common.computations";

export default function getTotalEnergyUseBarTooltop({
  drillDownLevel,
  graphFilter,
  thisPoint,
  yPoint,
  chartUnit,
  chartSlugInfo
}) {
  let result, equipments, commonTooltip, extraInfo,co2Emmision;
  let incomplete = thisPoint.incomplete_flag
    ? `<span style="color:#C4C4C4;">\u25CF</span><span class="txt-10"> Incomplete period </span>`
    : '';

  extraInfo = chartSlugInfo
    ? `<span class="txt-11">${
        chartSlugInfo == 'total_flow' ? `${roundToTwoDecimal(yPoint * 1000)} L <br>` : ''
      }  </span>`
    : '';

  co2Emmision = thisPoint.co2_emission ? `<div class="flex-c"><span class=" material-symbols-outlined notranslate-outlined tooltip-eco-icon pe-1">eco</span><span class="semi-bold">${roundToTwoDecimal(thisPoint.co2_emission)}&nbsp</span> kg CO₂</div>` : ''

  console.log('extraInfo', thisPoint.co2_emission);
  if (graphFilter.filterMain == '1day' || graphFilter.filterMain == 'today'|| thisPoint?.hour_range) {
    let name = thisPoint.name;
    let remove_after = name.indexOf(',');
    let date_alone = name.substring(0, remove_after);
    commonTooltip = `<div class="txt-10">${date_alone}, ${
      thisPoint.hour_range
    }</div><div class="flex-c"><span class=" material-icons notranslate-outlined tooltip-bolt-icon">bolt</span><span class="semi-bold">${roundToTwoDecimal(yPoint)}&nbsp </span> ${
      chartUnit ? chartUnit : 'kWh'
    }</div>${co2Emmision}${extraInfo}`;
  } else {
    commonTooltip = `<div class="txt-10">${
      thisPoint.name
    }</div><div class="flex-c"><span class=" material-icons notranslate-outlined tooltip-bolt-icon">bolt</span><span class="semi-bold">${roundToTwoDecimal(yPoint)}&nbsp</span> ${
      chartUnit ? chartUnit : 'kWh'
    }</div>${co2Emmision}${extraInfo}`;
  }

  if (drillDownLevel == 'Machine') {
    equipments = ``;
  } else {
    equipments = `<span class="txt-11" >${thisPoint.number_of_equipments} equipment</span><br>`;
  }

  if (graphFilter.filterMain == '3months') {
    result = `${commonTooltip}<span class="txt-11" >${thisPoint.week_range}</span><br>${equipments} ${incomplete}`;
  } else if (graphFilter.filterMain == '1year') {
    result = `${commonTooltip}<span class="txt-11" >${thisPoint.month_range}</span><br>${equipments} ${incomplete}`;
  } else {
    result = `${commonTooltip} ${equipments} `;
  }
  return result;
}
