import { useState, useEffect } from 'react';
import SetAlertsComponent from '../components/set.alerts.component';
import moment from 'moment';
import useHttpApiCall from '../../../services/useHttpApiCall';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import setSeconds from 'date-fns/setSeconds';

var today = new Date();
const getMonthendDate = () => {
  return new Date(today.getFullYear(), today.getMonth() + 1, 0, 0);
};

export default function SetAlertsContainer({
  setShowToast,
  openAlertScreen,
  checkedItems,
  apiEquipmentList,
  editMode,
  apiEquipmentEdit,
  // alertEditApiResponse,
  executeScrollTop,
  directEquipmentEdit,
  directEquipmentCreate,
  directEquipmentCreateList,
  existingAlertMode,
  setExistingAlertMode,
  // setOverlappingAlarmIds,
  sensorUnit,
  isTempHumidityClassificationEnabled,
  selectedAlertType,
  isSelectAlertType
}) {
  const [alertLimit, setAlertLimit] = useState({
    if_above: {
      label: 'If above (kW)',
      value: null
    },
    if_below: {
      label: 'If below (kW)',
      value: null
    },
    for_minutes: {
      label: 'For (minutes)',
      value: null
    },
    // span_from: {
    //   label: 'from',
    //   value: null
    // },
    // span_to: {
    //   label: 'to',
    //   value: null
    // }
  });
  const [occur, setOccur] = useState('One time');
  const [openEnded, setOpenEnded] = useState(false);
  // const [payload, setPayload] = useState(null);
  // const [data] = useHttpApiCall(payload);
  // const [payload2, setPayload2] = useState(null);
  // const [data2] = useHttpApiCall(payload2);
  const [startDate, setStartDate] = useState(getMonthendDate());
  const [expiryDate, setExpiryDate] = useState(getMonthendDate());
  const [singleEquimentIndex, setSingleEquimentIndex] = useState(null);
  const [rangeNotSetError, setRangeNotSetError] = useState(null);
  const [payload3, setPayload3] = useState(null);
  const [data3] = useHttpApiCall(payload3);
  const [payload4, setPayload4] = useState(null);
  const [data4] = useHttpApiCall(payload4);
  const [payload5, setPayload5] = useState(null);
  const [data5] = useHttpApiCall(payload5);
  const [apiUserEmailList, setApiUserEmailList] = useState([]);
  const [apiUserEmailListSet, setApiUserEmailListSet] = useState(false);
  const [overlapResponse, setOverlapResponse] = useState(null);
  // const [overlapEquipements, setOverlapEquipements] = useState(null);
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')=='true'?true:false
  const [weekDaysObject, setWeekDaysObject] = useState([
    { selected: true, name: 'Mo', id: 1 },
    { selected: true, name: 'Tu', id: 2 },
    { selected: true, name: 'We', id: 3 },
    { selected: true, name: 'Th', id: 4 },
    { selected: true, name: 'Fr', id: 5 },
    { selected: false, name: 'Sa', id: 6 },
    { selected: false, name: 'Su', id: 7 }
  ]);
  const [startTime, setStartTime] = useState(setHours(setMinutes(setSeconds(new Date(), 0), 0), 8));
  const [endTime, setEndTime] = useState(setHours(setMinutes(setSeconds(new Date(), 0), 0), 17));
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const inputHandler = (e) => {
    var { name, value } = e.target;
    if (value == '') {
      value = null;
    }
    setAlertLimit((prev) => ({ ...prev, [name]: { ...prev[name], value } }));
  };
  // console.log("checkedItems: ", checkedItems);
  const onDateTimeChange = (date) => {
    setStartDate(date);
    setExpiryDate(moment.utc(date).format());
  };
  useEffect(() => {
    var checkedItemsArray = Object.keys(checkedItems).filter((k) => checkedItems[k] == true);
    if (checkedItemsArray.length == 1) {
      let indexNum = checkedItemsArray[0].split('_')[1];
      setSingleEquimentIndex(indexNum);
    } else {
      setSingleEquimentIndex(null);
    }
  }, [checkedItems]);

  useEffect(() => {
    if (alertLimit.if_above.value != null || alertLimit.if_below.value != null) {
      setRangeNotSetError(null);
    }
    //  else if (
    //   alertLimit.if_above.value > alertLimit.if_below.value &&
    //   alertLimit.if_above.value != null &&
    //   alertLimit.if_below.value != null
    // ) {
    //   setRangeNotSetError(null);
    // }
  }, [alertLimit]);

  const validateForm = () =>{
    let errorMsg = '', error= false;

    if((isSelectAlertType && selectedAlertType?.slug == "kw_span") || (editMode && apiEquipmentEdit.alarm_type_slug == "kw_span" )){
      if(alertLimit.if_above.value == null || alertLimit.if_below.value == null){
        errorMsg = 'Both between inputs should be set';
        error = true;
      }
      else if (alertLimit.for_minutes.value == null){
        errorMsg = 'For minutes should be set';
        error = true;
      }
      else if ( parseFloat(alertLimit.if_above.value) > parseFloat(alertLimit.if_below.value)){
        errorMsg = 'Between start should be lesser than end';
        error = true;
      }
    }
    else{
      if(alertLimit.if_above.value == null && alertLimit.if_below.value == null) {
        errorMsg = 'Enter a value(If below or If above) to set alert';
        executeScrollTop();
      } else if (parseFloat(alertLimit.if_above.value) <= parseFloat(alertLimit.if_below.value)) {
        errorMsg ="'If above' value should be greater than 'If below'";
      }  
    }

    if(error){
      setRangeNotSetError(errorMsg)
      executeScrollTop();
      return false
    }
    else{
      return true
    }
  }



  const onFormSubmit = (e) => {
    e.preventDefault();
    let validation = validateForm()
    if(validation){
      if (editMode) {
        var editSendArray = apiEquipmentEdit;
        let notificationUsers = [];
        let triggerTime = [];
        apiUserEmailList.forEach((eachUser) => {
          if (eachUser.status == true) {
            let userIndex = editSendArray.notification_users.findIndex(
              (item) => item.user == eachUser.id
            );
            if (userIndex >= 0) {
              notificationUsers.push({
                user: eachUser.id,
                is_enabled: eachUser.status,
                id: editSendArray.notification_users[userIndex].id
              });
            } else {
              notificationUsers.push({
                user: eachUser.id,
                is_enabled: eachUser.status
              });
            }
          }
        });

        weekDaysObject.forEach((eachDay) => {
          if (eachDay.selected == true) {
            let dayIndex = editSendArray.trigger_time.findIndex(
              (item) => item.trigger_day == eachDay.id
            );
            if (dayIndex >= 0) {
              triggerTime.push({
                trigger_day: eachDay.id,
                trigger_from_time: moment(startTime).format('HH:mm:ss'),
                trigger_to_time: moment(endTime).format('HH:mm:ss'),
                id: editSendArray.trigger_time[dayIndex].id
              });
            } else {
              triggerTime.push({
                trigger_day: eachDay.id,
                trigger_from_time: moment(startTime).format('HH:mm:ss'),
                trigger_to_time: moment(endTime).format('HH:mm:ss')
              });
            }
          }
        });

        editSendArray = {
          ...editSendArray,
          above_limit: alertLimit.if_above.value,
          below_limit: alertLimit.if_below.value,
          alarm_frequency: occur,
          alarm_level: 'Equipment',
          is_open_ended: openEnded,
          expiry_date: openEnded ? null : expiryDate,
          notification_users: notificationUsers,
          trigger_time: triggerTime,
          span_duration: alertLimit.for_minutes.value

        };
        callCreatAlarmAPI(editSendArray);

        // setPayload2({
        //     auth:true,
        //     url:`v0/alarms/${editSendArray.id}/overlapping/`,
        //     method:'PUT',
        //     data:  editSendArray
        // })
      } else {
        //Create alarm
        var postSendArray = [];
        var alarmIdList = [];
        let notificationUsers = [];
        let triggerTime = [];
        apiUserEmailList.forEach((eachUser) => {
          if (eachUser.status == true) {
            notificationUsers.push({
              user: eachUser.id,
              is_enabled: eachUser.status
            });
          }
        });

        weekDaysObject.forEach((eachDay) => {
          if (eachDay.selected == true) {
            triggerTime.push({
              trigger_day: eachDay.id,
              trigger_from_time: moment(startTime).format('HH:mm:ss'),
              trigger_to_time: moment(endTime).format('HH:mm:ss')
            });
          }
        });

        if (directEquipmentCreate) {
          console.log("direct", isSelectAlertType && selectedAlertType?.slug == "kw_span" ? alertLimit.for_minutes.value : null)
          postSendArray.push({
            process: directEquipmentCreateList.process,
            sub_process_id: directEquipmentCreateList.sub_process_id,
            equipment_id: directEquipmentCreateList.equipment_id,
            above_limit: alertLimit.if_above.value,
            below_limit: alertLimit.if_below.value,
            alarm_frequency: occur,
            alarm_level: 'Equipment',
            is_active: true,
            is_open_ended: openEnded,
            enable_email: true,
            enable_sms: false,
            expiry_date: openEnded ? null : expiryDate,
            notification_users: notificationUsers,
            trigger_time: triggerTime,
            alarm_type: isSelectAlertType ? selectedAlertType.id :1 ,
            span_duration: isSelectAlertType && selectedAlertType?.slug == "kw_span" ? alertLimit.for_minutes.value : null
          });
        } else {
          //if duplicate exist add alarm_id to overwrite in post
          // apiAlarmDuplicateList.map((item, index) => {
          //     let alarm_id = item.id;
          //     let combination_id = `${item.process}${item.sub_process_id}${item.equipment_id}`
          //     alarmIdList = {...alarmIdList,[combination_id]:alarm_id}
          // })
          console.log("multiple", isSelectAlertType,selectedAlertType?.slug, isSelectAlertType && selectedAlertType?.slug == "kw_span" ? alertLimit.for_minutes.value : null)

          Object.keys(checkedItems).map((item) => {
            if (checkedItems[item] && apiEquipmentList) {
              let indexNum = item.split('_')[1];
              let combination_id = `${apiEquipmentList[indexNum].process}${apiEquipmentList[indexNum].sub_process_id}${apiEquipmentList[indexNum].equipment_id}`;
              postSendArray.push({
                process: apiEquipmentList[indexNum].process||apiEquipmentList[indexNum].process_id,
                sub_process_id: apiEquipmentList[indexNum].sub_process_id,
                equipment_id: apiEquipmentList[indexNum].equipment_id,
                above_limit: alertLimit.if_above.value,
                below_limit: alertLimit.if_below.value,
                alarm_frequency: occur,
                alarm_level: 'Equipment',
                is_active: true,
                is_open_ended: openEnded,
                enable_email: true,
                enable_sms: false,
                expiry_date: openEnded ? null : expiryDate,
                notification_users: notificationUsers,
                trigger_time: triggerTime,
                alarm_id: alarmIdList[combination_id],
                alarm_type: isSelectAlertType ? selectedAlertType.id : 1 ,
                span_duration: isSelectAlertType && selectedAlertType?.slug == "kw_span" ? alertLimit.for_minutes.value : null,
                plant_code:apiEquipmentList[indexNum]?.plant_code
              });
            }
          });
        }

        callCreatAlarmAPI(postSendArray);

        // setPayload({
        //     auth:true,
        //     url:'v0/alarms/overlapping/',
        //     method:'POST',
        //     data:  postSendArray
        // })
      }
    }
  };

  const onOverwriteAlarmConfirm = () => {
    if (overlapResponse) {
      setExistingAlertMode(false)
      callCreatAlarmAPI(overlapResponse);
    }
  };

  // const callOverlapAlarmAPI = (apiData) => {
  //   if (editMode) {
  //     setPayload2({
  //       auth: true,
  //       url: `v0/alarms/${apiData.id}/overlapping/`,
  //       method: 'POST',
  //       data: {
  //       	"alarm_parameters": {
  //           ...apiData
  //         },
  //         "alarm_type": apiEquipmentEdit.alarm_type,
  //       }
  //     });
  //   } else {
  //     console.log("alarm type", apiData)
  //     //Create alarm
  //     setPayload({
  //       auth: true,
  //       url: 'v0/alarms/overlapping/',
  //       method: 'POST',
  //       data:{ 
  //         "alarm_parameters": [
  //           ...apiData
  //         ],
  //         "alarm_type": isSelectAlertType ? selectedAlertType.id : 1,
  //       }
  //     });
  //   }
  // };

  const callCreatAlarmAPI = (apiData) => {
    if (editMode) {
      console.log('call edit', apiData);
      flexibleEquipmentEnabled?  setPayload5({
        auth: true,
        url: `v1/alarms/${apiData.id}/`,
        method: 'PUT',
        data: apiData
      }):
      setPayload5({
        auth: true,
        url: `v0/alarms/${apiData.id}/`,
        method: 'PUT',
        data: apiData
      });
    } else {
      //Create alarm
      flexibleEquipmentEnabled?  setPayload4({
        auth: true,
        url: 'v1/alarms/',
        method: 'POST',
        data: apiData
      }):
      setPayload4({
        auth: true,
        url: 'v0/alarms/',
        method: 'POST',
        data: apiData
      });
    }
  };

  //Show alert on create and edit submit button
  // useEffect(() => {
  //   if ((data2 && data2.data) || (data && data.data)) {
  //     let responseData;
  //     let responsePostOrPutData;
  //     if (editMode) {
  //       responseData = data2.data.data;
  //       responsePostOrPutData = responseData.put_data;
  //     } else {
  //       responseData = data.data.data;
  //       responsePostOrPutData = responseData.post_data;
  //       setOverlappingAlarmIds();
  //     }

  //     if (responseData.overlapping_alarms.length == 0) {
  //       callCreatAlarmAPI(responsePostOrPutData);
  //       setExistingAlertMode(false);
  //     } else {
  //       setExistingAlertMode(true);
  //       setOverlappingAlarmIds(responseData.overlapping_alarm_ids);
  //       setOverlapEquipements(responseData.overlapping_alarms.join(','));
  //       setOverlapResponse(responsePostOrPutData);
  //       handleShow();
  //     }
  //   }
  // }, [data2, data]);

  useEffect(() => {

    if ((data4 && data4.data) || (data5 && data5.data)) {
if(data4?.data?.status===3||data5?.data?.status===3){
    // openAlertScreen('set_alerts');
    handleShow();


}
else if(data4?.data?.status===1||data5?.data?.status===1){
  setOverlapResponse(null);
  setShowToast(true);
  executeScrollTop();
  setTimeout(() => {
    setShowToast(false);
  }, 3000);

}
 
      if (!editMode) {
    //     if ((data4 && data4.data) || (data5 && data5.data)) {
    //   if(data4?.data?.status===3){
    //       // openAlertScreen('set_alerts');
    //     handleShow();

      
    //   }
    //   else{
    //     openAlertScreen('select_source');

    //   }
    // }
      }
    }
  }, [data4, data5]);
  // useEffect(() => {
  //     if(data2 && duplicateMode){
  //         duplicateListApiCall()
  //     }
  // }, [data2])

  const showExistingActiveAlertList = () => {
    openAlertScreen('active_alerts');
  };

  //Prepopulating on clicking edit from active alerts
  useEffect(() => {
    if (editMode || (directEquipmentEdit && apiEquipmentEdit)) {
      var localTime;
      setOccur(apiEquipmentEdit.alarm_frequency);
      setAlertLimit((prev) => ({
        if_below: { ...prev.if_below, value: apiEquipmentEdit.below_limit },
        if_above: { ...prev.if_above, value: apiEquipmentEdit.above_limit },
        for_minutes: { ...prev.for_minutes, value: apiEquipmentEdit?.span_duration }

      }));
      if (apiEquipmentEdit.expiry_date == null) {
        localTime = getMonthendDate();
        setExpiryDate(moment.utc(localTime).format());
      } else {
        localTime = moment.utc(apiEquipmentEdit.expiry_date).toDate();
        setExpiryDate(moment.utc(localTime).format());
        localTime = moment(localTime).format('YYYY-MM-DD HH:mm:ss');
      }

      setStartDate(new Date(localTime));

      setOpenEnded(apiEquipmentEdit.is_open_ended);
      console.log('apiequipment', apiEquipmentEdit);
      if (apiEquipmentEdit.trigger_time.length > 0) {
        let fromTimeHours = moment(
          apiEquipmentEdit.trigger_time[0].trigger_from_time,
          'HH:mm:ss'
        ).format('HH');
        let fromTimeMinutes = moment(
          apiEquipmentEdit.trigger_time[0].trigger_from_time,
          'HH:mm:ss'
        ).format('mm');
        let fromTimeSeconds = moment(
          apiEquipmentEdit.trigger_time[0].trigger_from_time,
          'HH:mm:ss'
        ).format('ss');

        let toTimeHours = moment(
          apiEquipmentEdit.trigger_time[0].trigger_to_time,
          'HH:mm:ss'
        ).format('HH');
        let toTimeMinutes = moment(
          apiEquipmentEdit.trigger_time[0].trigger_to_time,
          'HH:mm:ss'
        ).format('mm');
        let toTimeSeconds = moment(
          apiEquipmentEdit.trigger_time[0].trigger_to_time,
          'HH:mm:ss'
        ).format('ss');

        setStartTime(
          setHours(
            setMinutes(setSeconds(new Date(), fromTimeSeconds), fromTimeMinutes),
            fromTimeHours
          )
        );
        setEndTime(
          setHours(setMinutes(setSeconds(new Date(), toTimeSeconds), toTimeMinutes), toTimeHours)
        );
        let tempWeekDaysObject = weekDaysObject.map((eachday) => {
          let dayIndex = apiEquipmentEdit.trigger_time.findIndex(
            (item) => item.trigger_day == eachday.id
          );
          return dayIndex >= 0 ? { ...eachday, selected: true } : { ...eachday, selected: false };
        });
        // apiEquipmentEdit.trigger_time.forEach((eachTriggerTime)=>{
        //     let dayIndex = tempWeekDaysObject.findIndex(item => item.id == eachTriggerTime.trigger_day);
        //     tempWeekDaysObject[dayIndex]={...tempWeekDaysObject[dayIndex],selected:true}
        // })
        setWeekDaysObject(tempWeekDaysObject);
      } else {
        let tempWeekDaysObject = weekDaysObject.map((a) => {
          a.selected = false;
          return a;
        });
        setWeekDaysObject(tempWeekDaysObject);
      }
    }
  }, [editMode, apiEquipmentEdit]);

  //Notification

  useEffect(() => {
    flexibleEquipmentEnabled?   setPayload3({
      auth: true,
      url: 'v2/users?layer_type=company'
    }):
    setPayload3({
      auth: true,
      url: 'v1/users/'
    });
  }, []);

  useEffect(() => {
    if (data3 && data3.data) {
      setApiUserEmailList(data3.data.data);
      setApiUserEmailListSet(true);
    }
  }, [data3]);

  useEffect(() => {
    if (editMode || (directEquipmentEdit && apiUserEmailListSet)) {
      let tempUserEmailList = [...apiUserEmailList];
      apiEquipmentEdit.notification_users.forEach((eachUser) => {
        let userIndex = tempUserEmailList.findIndex((item) => item.id == eachUser.user);
        tempUserEmailList[userIndex] = {
          ...tempUserEmailList[userIndex],
          status: eachUser.is_enabled
        };
      });
      setApiUserEmailList(tempUserEmailList);
    }
  }, [editMode, apiEquipmentEdit, apiUserEmailListSet]);

  return (
    <div>
      <SetAlertsComponent
        inputHandler={inputHandler}
        onFormSubmit={onFormSubmit}
        alertLimit={alertLimit}
        occur={occur}
        setOccur={setOccur}
        setOpenEnded={setOpenEnded}
        openEnded={openEnded}
        openAlertScreen={openAlertScreen}
        onDateTimeChange={onDateTimeChange}
        startDate={startDate}
        apiEquipmentList={apiEquipmentList}
        checkedItems={checkedItems}
        singleEquimentIndex={singleEquimentIndex}
        rangeNotSetError={rangeNotSetError}
        editMode={editMode}
        apiEquipmentEdit={apiEquipmentEdit}
        directEquipmentEdit={directEquipmentEdit}
        directEquipmentCreate={directEquipmentCreate}
        directEquipmentCreateList={directEquipmentCreateList}
        apiUserEmailList={apiUserEmailList}
        setApiUserEmailList={setApiUserEmailList}
        weekDaysObject={weekDaysObject}
        startTime={startTime}
        endTime={endTime}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
        setWeekDaysObject={setWeekDaysObject}
        onOverwriteAlarmConfirm={onOverwriteAlarmConfirm}
        handleClose={handleClose}
        modalShow={modalShow}
        overlapEquipements={null}
        showExistingActiveAlertList={showExistingActiveAlertList}
        existingAlertMode={existingAlertMode}
        sensorUnit={sensorUnit}
        isTempHumidityClassificationEnabled={isTempHumidityClassificationEnabled}
        selectedAlertType={selectedAlertType}
        isSelectAlertType={isSelectAlertType}
      />
    </div>
  );
}
