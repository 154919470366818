export default function AlertsMenuComponent({ onAlertSidebarClick }) {
  return (
    <div>
      <div className="daz-sidebar__hoverinfo shadow">
        <div className="daz-sidebar__dropdown--item">
          <div
            className="daz-sidebar__hoverinfo--click"
            onClick={() => {
              onAlertSidebarClick('select_source');
            }}>
            Set alert
          </div>
        </div>
        <div className="daz-sidebar__dropdown--item">
          <div
            className="daz-sidebar__hoverinfo--click"
            onClick={() => {
              onAlertSidebarClick('active_alerts');
            }}>
            Active alerts
          </div>
        </div>
        {/* <div className="daz-sidebar__dropdown--item daz-sidebar__dropdown--disabled">
                    <div className="daz-sidebar__hoverinfo--click daz-sidebar__dropdown--disabled" >Alert settings</div> 
                </div> */}
      </div>
    </div>
  );
}
