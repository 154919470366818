import { useState, useEffect } from 'react';

const useHideAfterTimeout = (duration = 3000, initialVisibility = false) => {
  const [isVisible, setIsVisible] = useState(initialVisibility);

  useEffect(() => {
    if (isVisible) {
      const timeout = setTimeout(() => {
        setIsVisible(false);
      }, duration);

      return () => clearTimeout(timeout);
    }
  }, [isVisible, duration]);

  const show = () => {
    setIsVisible(true);
  };

  return {
    isVisible,
    show
  };
};

export default useHideAfterTimeout;
