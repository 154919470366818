import { useEffect, useState } from "react";
import useHttpApiCall from "../../../services/useHttpApiCall";
import AlertTypeComponent from "../components/alert.type.component";

export default function AlertTypeContainer({openAlertScreen,setSelectSensorClassification,selectSensorClassification,setIsSelectAlertType,setSelectedAlertType}) {
const [payload, setPayload] = useState(null);
const [data] = useHttpApiCall(payload);
const [alertType, setAlertType] = useState(null);

useEffect(() => {
    setPayload({
        auth: true,
        url: `v0/alarms/types/`,
      });
}, [])


useEffect(() => {
    if (data && data.data) {
        console.log("data",data.data.data)
        setAlertType(data.data.data);
    }
}, [data]);


const onAlertTypeSelect = ({ slug, id}) => {
    console.log("slug", slug, "id",id)
    setIsSelectAlertType(true);
    setSelectedAlertType({slug:slug, id:id})
  };


return (
<div>
    <AlertTypeComponent onAlertTypeSelect={onAlertTypeSelect} openAlertScreen={openAlertScreen} setSelectSensorClassification={setSelectSensorClassification} 
    alertType={alertType} selectSensorClassification={selectSensorClassification}  setIsSelectAlertType={setIsSelectAlertType}
    />
</div>
);
}
