import { useEffect, useState } from 'react';

export function useCopyToClipboard(duration = 3000) {
  const [copiedText, setCopiedText] = useState(null);

  useEffect(() => {
    if (copiedText) {
      const timeout = setTimeout(() => {
        setCopiedText(null);
      }, duration);
      return () => clearTimeout(timeout);
    }
  }, [copiedText]);

  const copyToClipboard = async (text) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      setCopiedText(null);
      return false;
    }
  };

  return [copyToClipboard, copiedText];
}
