import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../utilities/local.storage';
import { useEffect } from 'react';

export const RedirectLogin = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login');
    }
  }, []);
  return children;
};

const redirectionLoginRoutes = {
  path: '*',
  element: <RedirectLogin />
};
export default redirectionLoginRoutes;
