const initialState = {
  is_custom_graph_enabled: false,
  is_zone_enabled: false,
  api_call_once: false,
  sensor_classification: false,
  is_single_equipment_customer: false,
  is_variable_pricing_enabled: false,
  is_machine_status_enabled: false,
  is_generators_classification_enabled:false,
  is_incomers_classification_enabled:false,
  is_flexible_equipment_categorization_enabled:false,
  layer_drilldown_list:{}
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CUSTOM_GRAPH_ENABLED':
      return {
        ...state,
        is_custom_graph_enabled: action.payload
      };
    case 'ZONE_ENABLED':
      return {
        ...state,
        is_zone_enabled: action.payload
      };
    case 'API_CALL_ONCE':
      return {
        ...state,
        api_call_once: action.payload
      };
    case 'SENSOR_CLASSIFICATION':
      return {
        ...state,
        sensor_classification: action.payload
      };
    case 'VARIABLE_PRICING_ENABLED':
      return {
        ...state,
        is_variable_pricing_enabled: action.payload
      };
    case 'SINGLE_EQUIPMENT_CUSTOMER':
      return {
        ...state,
        is_single_equipment_customer: action.payload
      };
    case 'EXTERNAL_ACCESS_ENABLED':
      return {
        ...state,
        external_access_enabled: action.payload
      };
    case 'MACHINE_STATUS_ENABLED':
      return {
        ...state,
        is_machine_status_enabled: action.payload
      };
      case 'INCOMERS_ENABLED':
        return {
          ...state,
          is_incomers_classification_enabled: action.payload
        };
        case 'GENERATORS_ENABLED':
          return {
            ...state,
            is_generators_classification_enabled: action.payload
          };
          case 'FLEXIBLE_EQUIPMENT_CATEGORIZATION_ENABLED':
            return {
              ...state,
              is_flexible_equipment_categorization_enabled: action.payload
            };
            case 'LAYERS_DRILLDOWN_LIST':
              return {
                ...state,
                layer_drilldown_list: action.payload
              };
    default:
      return state;
  }
};

export default companyReducer;
