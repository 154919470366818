import { axisLablesFontSizeSet } from '../../../config/chartCommon';

function comparisonTotalEnergyUseConfig({ chartApiData, graphFilter, userAccessibility }) {
  const config = {
    subtitle: {
      text: undefined
    },
    legend: {
      enabled: true,
      align: 'center',
      verticalAlign: 'bottom',
      // floating: true,
      y: 30,
      itemStyle: {
        fontWeight: 'lighter',
        fontSize: axisLablesFontSizeSet(userAccessibility)
      },
      reversed: true
    },
    chart: {
      type: 'column',
      className: 'totalenergyuse-comparison',
      spacingBottom: 25
    },
    xAxis: {
      reversedStacks: true,
      categories:
        graphFilter.filterMain == 'date'
          ? ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
          : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      labels: {
        autoRotation: undefined,
        formatter: undefined
      },
      tickLength: 0,
      tickPixelInterval: 70
    },

    tooltip: {
      formatter: function () {
        let result;
        let commonTooltip = `<div class="txt-10">${
          this.point.name
        }</div><div class="flex-c"><span class=" material-icons notranslate-outlined tooltip-bolt-icon">bolt</span><div><span class="semi-bold" >${parseInt(this.y)}</span> kWh</div></div>`;
        let equipments = `<span class="txt-11" >${this.point.number_of_equipments} equipment</span>`;
        //   let incomplete = this.point.incomplete_flag ?  `<span style="color:#C4C4C4;">\u25CF</span><span class="txt-10"> Incomplete period </span>` : ''
        if (graphFilter.filterMain == 'month') {
          result = `${commonTooltip}<span class="txt-11" >${this.point.month_range}</span><br>${equipments} <br>`;
        } else {
          result = `${commonTooltip} ${equipments}`;
        }
        return result;
      },
      outside: true
    },
    plotOptions: {
      column: {
        color: undefined,
        shadow: false,
        pointPadding:0.1,
        groupPadding: 0.2,
      }
    },
    series: chartApiData && chartApiData
  };
  return config;
}

export default comparisonTotalEnergyUseConfig;
