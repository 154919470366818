const initialState = {
  set_excel_download: null,
  recurring_download: false
};

const excelDownloadReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_EXCEL_DOWNLOAD':
      return {
        ...state,
        set_excel_download: action.payload
      };
    case 'RECURRING_DOWNLOAD':
      return {
        ...state,
        recurring_download: action.payload
      };
    default:
      return state;
  }
};

export default excelDownloadReducer;
