import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button, Modal } from 'react-bootstrap';
import sensorClassificationUnit from '../containers/sensor.classification.unit';

export default function ActiveAlertsComponent({
  onAlertDelete,
  onAlertEdit,
  apiAlarmList,
  openAlertScreen,
  onAlertDeleteConfirm,
  setShowDeleteToast,
  showDeleteToast,
  modalRef,
  existingAlertMode
}) {
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  // eslint-disable-next-line no-unused-vars
  const [weekDaysShow, setWeekDaysShow] = useState([
    { selected: false, name: 'Mo', id: 1 },
    { selected: false, name: 'Tu', id: 2 },
    { selected: false, name: 'We', id: 3 },
    { selected: false, name: 'Th', id: 4 },
    { selected: false, name: 'Fr', id: 5 },
    { selected: false, name: 'Sa', id: 6 },
    { selected: false, name: 'Su', id: 7 }
  ]);

  // useEffect(() => {
  //     let tempWeekDaysObject = weekDaysShow.map((eachday,index)=>{
  //         let dayIndex = apiEquipmentEdit.trigger_time.findIndex(item => item.trigger_day == eachday.id);
  //         return dayIndex >= 0 ? {...eachday,selected : true} : {...eachday,selected : false}
  //     })
  // }, [])

  useEffect(() => {
    if (!modalShow) {
      modalRef.current = null;
    }
  }, [modalShow]);

  return (
    <div className="active-alert">
      <Alert
        className="sidebar-slider_alert"
        show={showDeleteToast}
        variant="success"
        onClose={() => setShowDeleteToast(false)}>
        <p className="text-center">Alert has been deleted</p>
      </Alert>

      <Modal show={modalShow} onHide={handleClose} centered>
        <div ref={modalRef} className="modal-wrap">
          <Modal.Header closeButton>
            <h5>Delete Alert!</h5>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this alert?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="btn-grad"
              onClick={() => {
                onAlertDeleteConfirm();
                handleClose();
              }}>
              Confirm
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
      {existingAlertMode ? (
        <i
          className=" material-icons notranslate graphoptionicons flip-icon top-left-btn"
          onClick={() => {
            openAlertScreen('set_alerts');
          }}>
          forward
        </i>
      ) : (
        ''
      )}
      <h3 className="mb-5">{existingAlertMode ? 'Existing Alerts' : 'Active Alerts'}</h3>
      {apiAlarmList.length > 0 ? (
        <ul className="daz-listing">
          {console.log('alsm', apiAlarmList)}
          {apiAlarmList &&
            apiAlarmList.map((eachAlarm, i) => {
              return (
                <li key={'alarm_' + i} className="flex-btw">
                  <div className="active-alert_name">
                    <p className="font-bold">{eachAlarm.equipment_name} </p>
                    <p className="font-light">{eachAlarm.process_display_name}</p>
                  </div>
                  <div className="active-alert_limit">
                 { eachAlarm.alarm_type_slug == "kw_span" ?
                  <>
                    <p className="font-light txt-12">
                      between: {eachAlarm.above_limit} kW{' - '} {eachAlarm.below_limit} kW
                    </p>
                    <p className="font-light txt-12">
                      for: {eachAlarm.span_duration}{' '}minutes
                    </p>
                  </>
                 : 
                    <>  
                    <p className="font-light txt-12">
                      If above: {eachAlarm.above_limit}{' '}
                      {sensorClassificationUnit[eachAlarm.alarm_type_slug] || sensorClassificationUnit[eachAlarm.equipment_type_slug] || 'kW'}
                    </p>
                    <p className="font-light txt-12">
                      If below: {eachAlarm.below_limit}{' '}
                      {sensorClassificationUnit[eachAlarm.alarm_type_slug] || sensorClassificationUnit[eachAlarm.equipment_type_slug] || 'kW'}{' '}
                    </p>
                    </>
              }
                    <div className="mt-1 hstack gap-1">
                      {weekDaysShow.map((eachday, i) => {
                        return (
                          <button
                            key={i}
                            type="button"
                            className={
                              eachAlarm.trigger_time.find((v) => {
                                return eachday.id == v.trigger_day;
                              })
                                ? 'weekdays-btn weekdays-btn_active '
                                : 'weekdays-btn'
                            }>
                            {eachday.name}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className="active-alert_change">
                    <i
                      className=" material-icons notranslate graphoptionicons me-1"
                      onClick={() => {
                        onAlertEdit(eachAlarm.id);
                      }}>
                      edit
                    </i>

                    <i
                      className=" material-icons notranslate graphoptionicons"
                      onClick={() => {
                        onAlertDelete(eachAlarm.id);
                        handleShow();
                      }}>
                      delete
                    </i>
                    {eachAlarm.is_expired ? (
                      <Badge className="active-alert_expired" pill>
                        Expired
                      </Badge>
                    ) : (
                      // !eachAlarm.is_active ?
                      // <Badge className="active-alert_expired" pill >
                      //     Inactive
                      // </Badge>
                      // :
                      ''
                    )}
                  </div>
                </li>
              );
            })}
        </ul>
      ) : (
        <div className="active-alert_none">
          <p className="light2-grey txt-14">You have no active alerts.</p>
        </div>
      )}
    </div>
  );
}
