import {
  chartChartConfig,
  chartCommonConfig,
  chartTitleConfig,
  chartLineTooltipConfig,
  chartLineExportingConfig,
  chartLineXaxisConfig,
  chartYaxisConfig
} from '../../../config/chartCommon';

function instantaneousPowerChartConfig(
  chartApiData2,
  graphFilter,
  graphName,
  userAccessibility,
  graphTooltip,
  isPlotedLine,
  setMinMaxValue,
  graphDetails,

  
  
) {
  const config = {
    ...chartTitleConfig({
      graphTitle: graphName,
      userAccessibility: userAccessibility,
      graphTooltip: graphTooltip
    }),
    ...chartCommonConfig,
    ...chartLineExportingConfig,
    chart: {

      ...chartChartConfig({ userAccessibility: userAccessibility })
    },
    exporting: {
      chartOptions: {
        title: {
          text: `${graphName} - ${graphDetails?.process? graphDetails.process: graphDetails?.drilldownlevel == 'Home' && 'Plant'}`
        },
        xAxis:{
          title: {
            text:'',
            align:'high'
          },
        }
      }
    },
    ...chartLineXaxisConfig({ graphFilter: graphFilter, userAccessibility: userAccessibility ,isPlotedLine:isPlotedLine,setMinMaxValue:setMinMaxValue}),
    ...chartYaxisConfig({ userAccessibility }),
    tooltip: {
      valueSuffix: chartApiData2.unit_name ? ` ${chartApiData2.unit_name}` : ' kW',
      ...chartLineTooltipConfig.tooltip
    },
    plotOptions: {
      series: {
        lineWidth: 2,
        type: 'line',
        color: '#71B2FF',
        marker: {
          enabled: false
        },
        dataGrouping: {
          enabled: false
        },
      }
    },

    series: [
      // {
      //   name: "Max peak load",
      //   data: chartApiData.length == 0 ? [] :chartApiData && chartApiData.data_points  ,
      //   dashStyle: 'shortdot'
      // },
      {
        name: 'Instantaneous',
        data: chartApiData2.length == 0 ? [] : chartApiData2 && chartApiData2.data_points,
        dataLabels: {
          enabled: false,
          style: {},

        },
     
      }
    ]
  };
  return config;
}

export default instantaneousPowerChartConfig;
