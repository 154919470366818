import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { roundToTwoDecimal } from '../../utilities/common.computations';

export default function InstantanousPowerTotalKwhComponent({ totalKwh , emissionFactor}) {
  return (
    <div className="flex-c-e txt-11">
      <span>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Total kWh for the equipment during the selected time frame</Tooltip>}>
          <div className="flex-c">
            <span className="yellow material-icons notranslate-outlined txt-20 ">bolt</span>
            Total:&nbsp;<span className="semi-bold"> {totalKwh} kWh</span>
          </div>
        </OverlayTrigger>
      </span>
     { emissionFactor != null? <span className='ms-2'>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Total kg CO₂ for the equipment during the selected time frame</Tooltip>}>
          <div className="flex-c">
            <span className="green material-symbols-outlined notranslate-outlined txt-20 pe-1">eco</span>
            Total:&nbsp;<span className="semi-bold"> {roundToTwoDecimal(totalKwh*emissionFactor)} kg CO₂</span>
          </div>
        </OverlayTrigger>
      </span>:null
      }
    </div>
  );
}
