/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import Highcharts from 'highcharts';
require('highcharts/modules/xrange')(Highcharts);
import {
    axisLablesFontSizeSet,
    chartChartConfig,
    chartCommonConfig,
    chartTitleConfig,
    chartYaxisConfig
  } from '../../../config/chartCommon';
  import getTotalEnergyUseBarTooltop from '../utilities/main.total.energy.use.bar.tooltip';
  import eventFire from '../../../utilities/eventFire-inside-highcharts';
import moment from 'moment';
  function timelineConfig({ chartApiData, graphFilter, graphName, userAccessibility, navigate, props }) {
    const config = {
      ...chartTitleConfig({
        graphTitle: graphName,
        userAccessibility: userAccessibility
      }),
      ...chartCommonConfig,
      chart: {
        ...chartChartConfig({ userAccessibility: userAccessibility }),
        spacingTop: 10,
        spacingBottom: 15,
        type: "xrange",
      },
      legend: {
        enabled: true,
        align: 'center',
        verticalAlign: 'bottom',
        y: 20,
        itemStyle: {
          fontWeight: 'lighter',
          fontSize: axisLablesFontSizeSet(userAccessibility)
        },
        reversed: true
      },
      plotOptions: {
        series:{
          //minPointLength: 0.5,
          pointPadding:0,
          borderRadius: 0,
          borderColor: 'transparent',
          borderWidth: 0,
          states: {
            hover: {
                enabled: false
            }
        }

        },
        xrange: {
          //pointInterval: 2 * 3600 * 1000 ,
          grouping: false,
          dataLabels: {
            align: "center",
            enabled: true,
            format: "{point.name}"
          },
          tooltip:{
            followPointer:true,
            distance:35
          },
          colorByPoint: false
        },
        
      },
      colors: ['#C4C4C4','#d7ddfe', '#feedbd', '#f9d7d7', '#dcf9bd'],
      //colors: [ '#f9d7d7', '#dcf9bd','#C4C4C4','#FF0000','#d7ddfe'],
      series: chartApiData  && Object.keys(chartApiData.series).length > 0 ?  [
        {
          name: chartApiData.series['No Data']
          .name,
          data: chartApiData.series['No Data']
          .data,
          showInLegend:chartApiData.series['No Data'].data.length > 0 ? true : false 
        },
        {
        name: chartApiData.series.Off.name,
        data: chartApiData.series.Off.data
      }, {
        name: chartApiData.series.Sleep.name,
        data: chartApiData.series.Sleep.data
      }, {
        name: chartApiData.series.Standby.name,
        data: chartApiData.series.Standby.data
      }, {
        name: chartApiData.series.Production.name,
        data: chartApiData.series.Production.data
      }
    ] : [],
    
      exporting: {
        chartOptions: {
          chart: {
            spacingBottom: 30
          },
          subtitle: {
            text: ''
          },
          plotOptions: {

            series: {
              dataLabels: {
                format: '{point.y:,.0f}',
                style: {
                  fontSize: graphFilter.filterMain != '1week' ? '9px' : '11px'
                }
              }
            }
          }
        }
      },
  
      xAxis: {
        lineColor: '#cccccc',
        type: "datetime",
        tickLength: 0,
        minRange: 1,
        labels: {
          overflow: false,
          autoRotation: false,
          padding: 20,
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordBreak: 'breakall',
            fontSize: axisLablesFontSizeSet(userAccessibility)
          },
       
        }
      },
      yAxis:{
        style: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordBreak: 'breakall',
          fontSize: axisLablesFontSizeSet(userAccessibility)
        },
          ...chartYaxisConfig({ userAccessibility }).yAxis,
          gridLineWidth:0,
        categories: chartApiData && chartApiData.categories && chartApiData.categories.length > 0 ? chartApiData.categories : [],
      },
      tooltip: {
          style: {
              lineHeight: '17px',
          },
          formatter: function () {
            let start_point = moment(this.point.x);
            let end_point= moment(this.point.x2); 
            let kWh = this.point.kwh ? `<br><div class="font-bold txt-10 pb-1">${this.point.kwh.toFixed(2)} kWh</div>`: '';
            let rangeSet = start_point.isSame(end_point,'day') ? `<br> <span class="txt-10">${start_point.format('dddd, MMM DD')}<br>${start_point.format("HH:mm")} - ${end_point.format("HH:mm")} (${this.point.range})</span> ` : `<br> <span class="txt-10">${start_point.format('ddd, MMM DD, HH:mm')}  &#8212; ${end_point.format('ddd, MMM DD, HH:mm')} (${this.point.range})</span>`
            return `${this.point.yCategory}<br><div class="txt-10 mb-3"><span style="color:${this.series.color}">\u25CF </span>${this.series.name}</div>${rangeSet}${kWh}`;
          },
        outside: false,
      }
    };
    return config;
  }
  
  export default timelineConfig;
  