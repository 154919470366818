import { RECURRING_DOWNLOAD, SET_EXCEL_DOWNLOAD } from './exceldownload.type';

export const setExcelDownload = (set_excel_download) => {
  return {
    type: SET_EXCEL_DOWNLOAD,
    payload: set_excel_download
  };
};
export const recurringDownload = (recurring_download) => {
  return {
    type: RECURRING_DOWNLOAD,
    payload: recurring_download
  };
};
