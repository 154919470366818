import WidgetGraphComponent from '../components/widgetgraph.component';
export default function EnergyUseContainer({ summaryData }) {
  return (
    <WidgetGraphComponent
      widgetURLName="energy_use"
      widgetTitle="Avg energy use (kWh)"
      summaryData={summaryData}
      widgetTooltipSuffix="kWh"
    />
  );
}
