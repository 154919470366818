const CUSTOM_GRAPH_ENABLED = 'CUSTOM_GRAPH_ENABLED';
const ZONE_ENABLED = 'ZONE_ENABLED';
const API_CALL_ONCE = 'API_CALL_ONCE';
const SENSOR_CLASSIFICATION = 'SENSOR_CLASSIFICATION';
const VARIABLE_PRICING_ENABLED = 'VARIABLE_PRICING_ENABLED';
const SINGLE_EQUIPMENT_CUSTOMER = 'SINGLE_EQUIPMENT_CUSTOMER';
const EXTERNAL_ACCESS_ENABLED = 'EXTERNAL_ACCESS_ENABLED';
const MACHINE_STATUS_ENABLED = 'MACHINE_STATUS_ENABLED';
const INCOMERS_ENABLED = 'INCOMERS_ENABLED';
const GENERATORS_ENABLED = 'GENERATORS_ENABLED';
const FLEXIBLE_EQUIPMENT_CATEGORIZATION_ENABLED='FLEXIBLE_EQUIPMENT_CATEGORIZATION_ENABLED'
const LAYERS_DRILLDOWN_LIST = 'LAYERS_DRILLDOWN_LIST';

export {
  CUSTOM_GRAPH_ENABLED,
  ZONE_ENABLED,
  API_CALL_ONCE,
  SENSOR_CLASSIFICATION,
  VARIABLE_PRICING_ENABLED,
  SINGLE_EQUIPMENT_CUSTOMER,
  EXTERNAL_ACCESS_ENABLED,
  MACHINE_STATUS_ENABLED,
  INCOMERS_ENABLED,
  GENERATORS_ENABLED,
  FLEXIBLE_EQUIPMENT_CATEGORIZATION_ENABLED,
  LAYERS_DRILLDOWN_LIST
};
