import {
  CUSTOM_GRAPH_ENABLED,
  ZONE_ENABLED,
  API_CALL_ONCE,
  SENSOR_CLASSIFICATION,
  VARIABLE_PRICING_ENABLED,
  SINGLE_EQUIPMENT_CUSTOMER,
  EXTERNAL_ACCESS_ENABLED,
  MACHINE_STATUS_ENABLED,
  INCOMERS_ENABLED,
  GENERATORS_ENABLED,
  FLEXIBLE_EQUIPMENT_CATEGORIZATION_ENABLED,
  LAYERS_DRILLDOWN_LIST
} from './company.type';

export const customGraphEnabled = (is_custom_graph_enabled) => {
  return {
    type: CUSTOM_GRAPH_ENABLED,
    payload: is_custom_graph_enabled
  };
};
export const zoneEnabled = (is_zone_enabled) => {
  return {
    type: ZONE_ENABLED,
    payload: is_zone_enabled
  };
};
export const apiCallOnce = (api_call_once) => {
  return {
    type: API_CALL_ONCE,
    payload: api_call_once
  };
};
export const sensorClassification = (sensor_classification) => {
  return {
    type: SENSOR_CLASSIFICATION,
    payload: sensor_classification
  };
};
export const variablePricingEnabled = (is_variable_pricing_enabled) => {
  return {
    type: VARIABLE_PRICING_ENABLED,
    payload: is_variable_pricing_enabled
  };
};
export const singleEquipmentCustomer = (is_single_equipment_customer) => {
  return {
    type: SINGLE_EQUIPMENT_CUSTOMER,
    payload: is_single_equipment_customer
  };
};
export const externalAccessEnabled = (external_access_enabled) => {
  return {
    type: EXTERNAL_ACCESS_ENABLED,
    payload: external_access_enabled
  };
};
export const machineStatusEnabled = (is_machine_status_enabled) => {
  return {
    type: MACHINE_STATUS_ENABLED,
    payload: is_machine_status_enabled
  };
};
export const incomersEnabled = (is_incomers_classification_enabled) => {
  return {
    type: INCOMERS_ENABLED,
    payload: is_incomers_classification_enabled
  };
};
export const generatorsEnabled = (is_generators_classification_enabled) => {
  return {
    type:GENERATORS_ENABLED,
    payload: is_generators_classification_enabled
  };
};
export const flexibleEquipmentEnabled = (is_flexible_equipment_categorization_enabled) => {
  return {
    type:FLEXIBLE_EQUIPMENT_CATEGORIZATION_ENABLED,
    payload: is_flexible_equipment_categorization_enabled
  };
};

export const drilldownLayerList = (layer_drilldown_list) => {
  return {
    type:LAYERS_DRILLDOWN_LIST,
    payload: layer_drilldown_list
  };
};
