import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEquipmentsFilter } from '../../../redux/filterequipments/filterequipments.action';
import useHttpApiCall from '../../../services/useHttpApiCall';
import FilterEquipmentsComponent from '../components/filter.equipments.component';

export default function FilterEquipmentsContainer() {
  const [apiEquipmentList, setApiEquipmentList] = useState([]);
  const [payload, setPayload] = useState(undefined);
  const [data] = useHttpApiCall(payload);
  const [payload1, setPayload1] = useState(undefined);
  const [data1] = useHttpApiCall(payload1);
  const [checkedItems, setCheckedItems] = useState({});
  const set_equipment_filter = useSelector((state) => state.equipmentFilter.set_equipment_filter);
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState({ show: false, variant: 'success' });
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')=='true'?true:false
  useEffect(() => {
    console.log('call api', set_equipment_filter);
    setPayload({
      auth: true,
      url: set_equipment_filter.equipmet_list_api_url,
      params: set_equipment_filter.params
    });
  }, [data1]);

  useEffect(() => {
    if (data && data.data) {
      let equipment_list = data.data.data;
      let checked_equipment = {};
      setApiEquipmentList(equipment_list);
      equipment_list.forEach((equipment) => {
        checked_equipment = {
          ...checked_equipment,
          [`equipment_${equipment.id}`]: equipment.is_user_preferred
        };
      });
      setCheckedItems(checked_equipment);
    }
  }, [data]);

  const onCheckEquipment = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
  };

  const onFilterSubmit = () => {
    var patchSendArray = [];
    var drilldown_level = set_equipment_filter.drilldown_level;

    if (Object.values(checkedItems).some((val) => val === true)) {
      Object.keys(checkedItems).map((item, index) => {
        if (checkedItems[item]) {
          //let equipmentId = item.split('_')[1];
          if (drilldown_level == 0) {
            patchSendArray.push({
              source_mapping: apiEquipmentList[index].id,
              id: apiEquipmentList[index].preference_id,
              layer_type:'company'
            });
          } else if (drilldown_level == 'custom') {
              if(set_equipment_filter.equipment_update_params.is_humidity_overlay===1){
                patchSendArray.push({
                  source_mapping: apiEquipmentList[index].id,
                  id: apiEquipmentList[index].preference_id,
                  process_id: apiEquipmentList[index].process,
                  is_humidity_overlay:true
                });
            }
            else{
              patchSendArray.push({
                source_mapping: apiEquipmentList[index].id,
                id: apiEquipmentList[index].preference_id,
                process_id: apiEquipmentList[index].process,
                sub_division: set_equipment_filter.equipment_update_params.sub_division,
              });
            }
          } else {
            patchSendArray.push({
              source_mapping: apiEquipmentList[index].id,
              process_id: apiEquipmentList[index].process,
              sub_process_id: apiEquipmentList[index].sub_process_id,
              id: apiEquipmentList[index].preference_id
            });
          }
        }
      });

      flexibleEquipmentEnabled?  setPayload1({
        auth: true,
        url: 'v1/layers/equipments/preferences/multi-update/',
        method: 'PATCH',
        params: set_equipment_filter.equipment_update_params,
        data: patchSendArray
      }):
      setPayload1({
        auth: true,
        url: 'v0/instantaneous-power-equipment-preferences/multi-update/',
        method: 'PATCH',
        params: set_equipment_filter.equipment_update_params,
        data: patchSendArray
      });
    } else {
      setShowToast({ show: true, variant: 'danger' });
      hideToast();
    }
  };

  const hideToast = () => {
    setTimeout(() => {
      setShowToast({ ...showToast, show: false });
    }, 3000);
  };

  useEffect(() => {
    if (data1 && data1.data) {
      setShowToast({ show: true, variant: 'success' });
      hideToast();
      dispatch(
        setEquipmentsFilter({
          ...set_equipment_filter,
          filter_update: true
        })
      );
    }
  }, [data1]);

  // const onUnselectAll = () =>{
  //     var tempCheckedItems = checkedItems;
  //     if(!unselectAll){
  //         Object.keys(tempCheckedItems).forEach((key)=>{
  //             console.log(key)
  //              tempCheckedItems[key] = true
  //         });
  //         setCheckedItems(tempCheckedItems)
  //     }
  //     else{
  //         Object.keys(tempCheckedItems).forEach((key)=>{
  //             tempCheckedItems[key] = false
  //        });
  //        setCheckedItems(tempCheckedItems)
  //     }
  //     setUnselectAll(!unselectAll)
  // }

  // useEffect(() => {
  //     if(Object.values(checkedItems).every(val => val === true) && Object.keys(checkedItems).length != 0){
  //         setUnselectAll(true)
  //     }
  //     else if(Object.values(checkedItems).every(val => val === false) && Object.keys(checkedItems).length != 0){
  //         setUnselectAll(false)
  //     }

  // }, [checkedItems])

  useEffect(() => {
    // On submit filter_update is changed to true to refresh graph,To inidiate there is a change in the checked item now its set to false
    dispatch(
      setEquipmentsFilter({
        ...set_equipment_filter,
        filter_update: false
      })
    );
  }, [checkedItems]);

  return (
    <div>
      <FilterEquipmentsComponent
        apiEquipmentList={apiEquipmentList}
        setCheckedItems={setCheckedItems}
        checkedItems={checkedItems}
        onCheckEquipment={onCheckEquipment}
        onFilterSubmit={onFilterSubmit}
        setShowToast={setShowToast}
        showToast={showToast}
      />
    </div>
  );
}
