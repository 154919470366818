export default function SelectUnselectComponent({ unselectAll, onUnselectAll }) {
  return (
    <div className="flex-btw mb-2">
      <input
        id="unselect_all"
        type="checkbox"
        className="daz-checkbox right"
        name="unselect_all"
        checked={unselectAll}
        onChange={onUnselectAll}
      />
      <label htmlFor="unselect_all">Select/Unselect all</label>
    </div>
  );
}
